<template lang="pug">
.advanced-filters-modal.fpui-modal
  fpui-modal-header(
    :title="$t('dashbaord.advanced_filters_modal.title', [attributeCapitalize])"
    @close="close"
    closeTopButton
  )
  fpui-modal-body
    .advanced-filters-modal-body
      .fp-box.preferences
        .box-title {{ $t('dashbaord.advanced_filters_modal.preferences') }}
        .box-line.select
          fpui-input-label {{ $t('dashbaord.advanced_filters_modal.preferences.type') }}
          fpui-input-select(
            :options="typeOptions"
            v-model="filterType"
            :clearable="false"
          )
        .box-line.flex(
          @click="openAttribute"
        )
          span {{ $t('dashbaord.advanced_filters_modal.preferences.open_attribute') }}
          i.fp4.fp4-top-right-in-square

        .box-line.flex(
          v-if='this.filterType === "categorical"'
          @click="openQuery"
        )
          span {{ $t('dashbaord.advanced_filters_modal.preferences.open_query') }}
          i.fp4.fp4-top-right-in-square


      .fp-box.tiles-list
        .box-title {{ $t('dashbaord.advanced_filters_modal.tiles_which_ignore_this_filter') }}
        fpui-table.fp-no-margin(
          :columns='columns'
          :data='tilesWithCompatibility'
          row-key='i'
          :height="tiles.length >= 7 ? '348px' : 'auto'"
          :pagination="{ disabled: true }"
          :onClick="updateIgnoredTiles"
        )

  fpui-modal-footer(:buttons="buttons")
    span.number-tiles-ignored(
      v-html="$t('dashbaord.advanced_filters_modal.number_tiles_ignored', [ignoredTiles.length], ignoredTiles.length)"
    )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _intersection from 'lodash/intersection'

import Config from '@/shared/Config'
import CellCheckbox from '@/qb/components/CellCheckbox'

export default {
  props: {
    item: { type: Object, default: () => ({}) },
    filter: { type: Object, default: () => ({}) },
    layout: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      ignoredTiles: [],
      originalIgnoredTiles: [],
      filterType: 'categorical'
    }
  },
  computed: {
    attributeCapitalize () {
      return this.filter.label[0].toUpperCase() + this.filter.label.substr(1)
    },
    disabled () {
      return false // Test if changes ?
    },
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        autoWidth: true,
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('confirm'),
        color: 'blue-flash',
        disabled: this.disabled,
        autoWidth: true,
        handler: async () => {
          this.updateTiles()
        }
      }]
    },
    tiles () {
      return this.layout.cleanLayout
    },
    tilesWithCompatibility () {
      return this.tiles.map(t => {
        return {
          ...t,
          compatibility: this.isTileCompatible(t)
        }
      })
    },
    columns () {
      const hasIncompatibleTiles = this.tilesWithCompatibility.filter(t => !t.compatibility).length
      const columns = [
        {
          name: 'checkbox',
          label: this.$t('dashbaord.advanced_filters_modal.table.ignored'),
          target: (row) => ({ item: row, value: this.ignoredTiles.includes(row.i), itemUpdate: row => this.updateIgnoredTiles(row) }),
          component: CellCheckbox,
          width: 87
        },
        {
          label: this.$t('dashbaord.advanced_filters_modal.table.tile_name'),
          target: 'title',
          paddingLeft: hasIncompatibleTiles ? 0 : 37,
          style: {
            'padding-left': 0
          }
        }
      ]

      if (hasIncompatibleTiles) {
        columns.splice(1, 0, {
          label: '',
          target: row => {
            return `
              <span class='tile-name'>
                ${!row.compatibility ? '<i class="fp4 fp4-triangle-exclamation"></i>' : ''}
              </span>
            `
          },
          tooltip: row => {
            if (!row.compatibility) return this.$t('dashboard.filter_irrelevant_tooltip')
            return ''
          },
          tooltipDelay: 0,
          width: 35,
          paddingLeft: 0,
          style: {
            'padding-left': '10px'
          }
        })
      }

      return columns
    },
    metasTables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    diamondsWithVirtualAttributes () {
      return this.$store.getters.DWH_DIAMONDS_WITH_VA
    },
    allAttributes () {
      return [...this.$store.getters.DWH_ALL_ATTRIBUTES].sort()
    },
    typeOptions () {
      return [
        {
          label: this.$t('dashboard.filter.type.categorical'),
          value: 'categorical'
        },
        {
          label: this.$t('dashboard.filter.type.date'),
          value: 'date'
        },
        {
          label: this.$t('dashboard.filter.type.datetime'),
          value: 'datetime'
        },
        {
          label: this.$t('dashboard.filter.type.boolean'),
          value: 'boolean'
        }
      ]
    }
  },
  async mounted () {
    this.config = await Config()

    this.tiles.forEach(t => {
      if (t.ignored_filters && t.ignored_filters.includes(this.filter.label)) this.ignoredTiles.push(t.i)
    })
    this.originalIgnoredTiles = [...this.ignoredTiles]

    if (this.filter.type) this.filterType = this.filter.type
  },
  methods: {
    close () {
      this.$emit('close')
    },
    openAttribute () {
      const url = `${this.config.FPUI}/dm/#/${this.config.DATAPLANT_ID}/attribute/${this.filter.label}`
      window.open(url, '_blank')
    },
    openQuery () {
      const activeQuery = this.$store.getters.QB_QUERY_BY_SHARED_ID(this.item.repository, this.filter.query, this.item.repository_version) || {}
      if (activeQuery) {
        const url = `${this.config.FPUI}/am/#/${this.config.DATAPLANT_ID}/query/${activeQuery._id}`
        window.open(url, '_blank')
      }
    },
    updateIgnoredTiles (value) {
      const newValue = value.i

      if (this.ignoredTiles.includes(newValue)) this.ignoredTiles = this.ignoredTiles.filter(t => t !== newValue)
      else this.ignoredTiles.push(newValue)
    },
    async updateTiles () {
      this.ignoredTiles.forEach(tIdx => {
        const tile = this.tiles.find(t => t.i === tIdx)
        const ignoredFilters = tile?.ignored_filters || []

        if (!ignoredFilters.includes(this.filter.label)) {
          ignoredFilters.push(this.filter.label)
          this.layout.updateTile(tIdx, 'ignored_filters', ignoredFilters)
        }
      })

      this.originalIgnoredTiles.forEach(tIdx => {
        if (!this.ignoredTiles.includes(tIdx)) {
          const tile = this.tiles.find(t => t.i === tIdx)
          let ignoredFilters = tile?.ignored_filters || []

          if (ignoredFilters.includes(this.filter.label)) {
            ignoredFilters = ignoredFilters.filter(f => f !== this.filter.label)
            this.layout.updateTile(tIdx, 'ignored_filters', ignoredFilters)
          }
        }
      })

      this.$emit('close')
      if (this.filterType !== this.filter.type) {
        await this.item.update('filter', {
          ...this.item.filter,
          [this.filter.label]: {
            ...this.item.filter[this.filter.label],
            type: this.filterType
          }
        })
      }
      await this.item.update('tiles', _cloneDeep(this.tiles))
    },
    isTileCompatible (tile) {
      let query = {}
      if (tile.query) {
        if (typeof tile.query === 'string') query = this.$store.getters.QB_QUERY_BY_SHARED_ID(this.item.repository, tile.query, this.item.repository_version) || {}
        else query = tile.query
      }
      const availableAttributes = this.getCompatiblesAttributes(query?.configuration)

      return availableAttributes.includes(this.filter?.label)
    },
    getCompatiblesAttributes (queryConfiguration) { // Also used in AddFilter.vue, Tile.vue, QueryFields.vue
      if (!queryConfiguration) return []
      /* Hide incompatible fields vs selected fields */

      // If table is forces, get only attributes from this table
      if (queryConfiguration.table_name) {
        const tableForced = this.metasTables.find(table => table.name === queryConfiguration.table_name)
        return this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(tableForced?._id).map(att => att.name)
      } else {
        // Get Data, Scales and Filters fields selected
        let dataScaleFilterOrder = []
        Object.keys(queryConfiguration).forEach(val => {
          if (val === 'filter' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
          if (val === 'scale' && queryConfiguration[val]?.fields && queryConfiguration[val]?.fields.length > 0) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...queryConfiguration[val].fields]
          }
          if (val === 'data' && queryConfiguration[val]?.fields) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val].fields)]
          }
          if (val === 'order' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
        })

        if (dataScaleFilterOrder.length > 0 && this.diamondsWithVirtualAttributes.length) {
          let diamonds = []

          // If field(s) selected ...
          if (dataScaleFilterOrder.length === 1) {
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dataScaleFilterOrder[0]))
          } else {
            const diamondsNameList = []
            dataScaleFilterOrder.forEach(dsf => {
              // For each fields selected, get a list of diamond's name they are into
              const diamond = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dsf))
              const diamondsNames = []
              diamond.forEach(d => {
                diamondsNames.push(d.name)
              })
              diamondsNameList.push(diamondsNames)
            })
            let diamondNameCommon = []
            diamondsNameList.forEach((t, idx) => {
              // Get the diamond's name in common between the list of names we get previously
              if (idx === 0) {
                diamondNameCommon = t
              } else {
                diamondNameCommon = _intersection(diamondNameCommon, t)
              }
            })
            // Get the diamonds with compatible fields from from the names
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamondNameCommon.includes(diamond.name))
          }

          if (diamonds.length > 0) {
            let allFields = []
            diamonds.forEach(diamond => {
              allFields = [...allFields, ...diamond.attributes]
            })

            return this.allAttributes.filter(field => allFields.includes(field))
          } else {
            return Object.keys(queryConfiguration.data.fields) || []
          }
        } else {
          return this.allAttributes
        }
      }
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.advanced-filters-modal {
  .modal-body {
    height: auto;
    background: #F6F9FC;
    padding: 20px 40px;

    .preferences {
      height: auto;
      margin-bottom: 10px;
      .box-line.flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        cursor: pointer;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #3E4550;
        }
        i {
          color: #CBD3DB;
          font-size: 20px;
          cursor: pointer;
        }

        &:hover {
          i {
            color: #0089c0;
          }
        }
      }
      .box-line.select {
        margin-bottom: 10px;
      }
    }
    .tiles-list {
      .tile-name {
        display: flex;
        align-items: center;

        .fp4-triangle-exclamation {
          color: #FFA312;
        }
      }
    }
  }

  .modal-footer {
    padding-left: 40px;
    .number-tiles-ignored {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #97A7B7;
    }

    .footer-actions {
      flex: auto;
    }
  }
}
</style>
