import _omit from 'lodash/omit'
import _set from 'lodash/set'
import { API, APIObject } from '@/shared/plugins/Api/API'

class Mail extends APIObject {
  constructor (options) {
    super('IAM', options)

    this._id = this._id || ''
    this.bindings = this.bindings || []
    this.content = this.content || ''
    this.global = this.global || true
    this.sender = this.sender || ''
    this.subject = this.subject || ''
    this.type = this.type || ''
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy'
    ])
    return obj
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/mails/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/mails/${this._id}`
    })
  }
}

class Mails extends API {
  list () {
    return this.paginateListing({
      method: 'get',
      url: 'v4/mails'
    })
      .then(data => {
        return data.map(mail => new Mail(mail))
      })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Mail(item)
  }
}

export default Mails
export {
  Mail,
  Mails
}
