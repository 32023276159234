import Vue from 'vue'
import stream from 'stream'

class LogStream extends stream.Readable {
  constructor (jobId, execId, type) {
    super()
    this.jobId = jobId
    this.job_type = type
    this.execId = execId
    this.ended = false
    const request = {
      method: 'logs_subscribe',
      data: {
        job_id: jobId,
        execution_id: execId,
        job_type: type
      }
    }
    this.onMessage = this.onMessage.bind(this)
    Vue.WebSockets.subscribe(`ml.logs.${this.job_type}.${this.jobId}${this.execId ? `.${this.execId}` : ''}`, this.onMessage)
    Vue.WebSockets.send('ML', request)
  }

  onMessage ({ data }) {
    if (!this.ended) {
      data.log.split('\n').forEach(line => {
        this.push(Buffer.from(line, 'utf-8'))
      })
    }
  }

  _read () {
  }

  close () {
    const request = {
      method: 'logs_unsubscribe',
      data: {
        job_id: this.jobId,
        execution_id: this.execId,
        job_type: this.job_type
      }
    }
    Vue.WebSockets.send('ML', request)
    Vue.WebSockets.unsubscribe(`ML_LOGS_${this.jobId}${this.execId ? `_${this.execId}` : ''}`, this.onMessage)
    this.ended = true
  }
}

export default LogStream
