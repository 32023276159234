import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _omit from 'lodash/omit'

let zIndexCount = 10000

class LogicalObject extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    // Default variables
    this.display_name = this.display_name || this.name || ''
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!this.tags.zIndex || isNaN(this.tags.zIndex)) {
      this.tags.zIndex = zIndexCount
      zIndexCount++
    }
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display = this.display || {}
    this.display.x = this.display.x || 0
    this.display.y = this.display.y || 0
    this.attributes = this.attributes || []
    this.type = this.type || 'prim'
    this.name = this.name || ''

    // Status of physical build
    // PROCESSING = "PROCESSING"
    // SUCCESS = "SUCCESS"
    // FAILED = "FAILED"
    // STOPPED = "STOPPED"
    // TIMEOUT = "TIMEOUT"
    // From frontend we add also "NOT_BUILD" and "STARTED"
    this.build_status = this.build_status || 'NOT_BUILD'
  }

  getX (viewId = null) {
    return _get(this, `display.views.${viewId}.x`, this.display.x)
  }

  getY (viewId = null) {
    return _get(this, `display.views.${viewId}.y`, this.display.y)
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }


  async physicalBuild () {
    await this.request({
      method: 'put',
      socketId: false,
      url: 'v4/build/start',
      data: {
        objects: [this._id]
      }
    })
  }


  clone () {
    return new LogicalObject(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'build_status',
      'updated_by',
      'children'
    ])
    return obj
  }

  async create () {
    this.display_name = this.display_name || this.name
    return super.create({
      method: 'POST',
      url: 'v4/logical/objects'
    })
  }

  async save () {
    this.display_name = this.display_name || this.name
    return super.save({
      method: 'PUT',
      url: `v4/logical/objects/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/logical/objects/${this._id}`
    })
  }

  async move (x, y, viewId = null) {
    const tableViews = _cloneDeep(this.display)
    const dwhViews = (Store.getters.DWH_VIEWS || []).map(view => view._id)
    tableViews.views = tableViews.views || {}

    // If view was deleted, we removed it from display of table
    Object.keys(tableViews.views).forEach(view => {
      if (!dwhViews.includes(view)) delete tableViews.views[view]
    })

    // Set position of table in each views
    dwhViews.forEach(id => {
      if (!tableViews.views[id]) {
        tableViews.views[id] = {
          x: this.display.x,
          y: this.display.y
        }
      }
    })

    // If no viewID => update position in all tables else update position in views
    if (!viewId) {
      tableViews.x = x
      tableViews.y = y
    } else {
      tableViews.views[viewId] = { x, y }
    }

    this.update('display', tableViews, false)
  }
}
class LogicalObjects extends API {
  new (opts) {
    return new LogicalObject(opts)
  }

  list () {
    return this.request({
      method: 'get',
      url: 'v4/logical/objects'
    })
      .then(objects => {
        // objects = require('./mock_tables.json')

        return objects.map(object => {
          try {
            return new LogicalObject(object)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i)
      })
  }
}

export default LogicalObjects
export {
  LogicalObject,
  LogicalObjects
}
