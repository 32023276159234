import _maxBy from 'lodash/maxBy'
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'
import _orderBy from 'lodash/orderBy'
import _sumBy from 'lodash/sumBy'
import _keyBy from 'lodash/keyBy'
import _isEqual from 'lodash/isEqual'
import { uid } from 'uid'
import Vue from 'vue'

// class Tile {
//   constructor (options) {
//     this._assign(options)
//   }

//   _assign (object) {
//     Object.assign(this, object)
//   }
// }

class Layout {
  constructor (options) {
    this.horizontalCompact = options.horizontalCompact || true
    this.colNum = options.colNum || 12
    this.minWidth = options.minWidth || 3
    this.margin = options.margin || [10, 10]
    this.preventCollision = options.preventCollision || false

    this.updateTiles(options.tiles || [])
  }

  /**
   * Update originalTiles from tiles
   */
  updateTiles (newTiles) {
    this.tiles = newTiles

    this.validateTiles()
    this.updateOriginalTiles()

    this.addAddTiles()
    this.addVerticalResizeTiles()

    this.compact()
  }

  /**
   * Update originalTiles from tiles
   */
  updateOriginalTiles () {
    this.originalTiles = _cloneDeep(this.tiles)
    this.originalTilesObject = _keyBy(this.originalTiles, 'i')
  }

  /**
   * Compare tiles with originalTiles
   */
  get hasChanges () {
    return !_isEqual(this.originalTiles, this.tiles)
  }

  /**
   * Return the bottom coordinate of the tiles.
   */
  get bottom () {
    return this.tiles.length ? _maxBy(this.tiles, 'y')?.y : 0
  }

  /**
   * Return the bottom coordinate of the tiles without edit ones.
   */
  get bottomClean () {
    return this.cleanLayout.length ? _maxBy(this.cleanLayout, 'y')?.y : 0
  }

  /**
   * Return the height of layout.
   */
  get layoutHeight () {
    let sumHeight = 0

    for (let i = 0; i <= this.bottom; i += 1) {
      const heightOfRow = this.tiles.find(t => t.y === i)?.height
      sumHeight += heightOfRow
    }

    return sumHeight + 2 * this.margin[1]
  }

  /**
   * Return max tiles in row
   */
  get maxTilesInRow () {
    return this.colNum / (this.minWidth || 1)
  }

  /**
   * Get all static tiles.
   */
  get _statics () {
    return this.tiles.filter(t => t.static)
  }

  /**
   * Get tiles without edit tiles
   */
  get cleanLayout () {
    return this.tiles.filter(t => !t.edit)
  }

  /**
   * Get all y use in the tiles
   */
  get getYUsedByTiles () {
    return _uniq(this.tiles.map(t => t.y))
  }

  /**
   * Get item top from parent
   */
  getItemTop (y) {
    let sumHeight = 0

    for (let i = 0; i < y; i += 1) {
      const heightOfRow = this.tiles.find(t => t.y === i)?.height
      sumHeight += heightOfRow + this.margin[1]
    }

    return sumHeight
  }

  /**
   * Get item y from top
   */
  getItemY (top) {
    let sumHeight = 0
    let y = null

    for (let i = 0; i <= this.bottomClean; i += 1) {
      const heightOfRow = this.tiles.find(t => t.y === i)?.height
      sumHeight += heightOfRow + this.margin[1]

      if (sumHeight > top) {
        y = i
        break
      }
    }

    if (y === null) y = this.bottomClean

    return y
  }

  /**
   * Update tile
   *
   * @param {String} tileId tile.i
   * @param {String} key key to update
   * @param {Any} value new value
   */
  updateTile (tileId, key, value) {
    const tile = this.tiles.find(it => it.i === tileId)
    if (tile) {
      Vue.set(tile, key, value)
    }
  }

  /**
   * Return a copy of the tiles.
   */
  cloneLayout () {
    return _cloneDeep(this.tiles)
  }

  /**
   * Return array of int number from min to max.
   *
   * @param {Number} min Min.
   * @param {Number} max Max.
   */
  _generateNumbers (min, max) {
    const list = []
    for (let i = min; i < max; i++) {
      list.push(i)
    }

    return list
  }

  /**
   * Return list of all rows without any tiles.
   */
  _getEmptyRows () {
    let rowsUsed = []

    if (!this.tiles.length) return []

    this.cleanLayout.forEach(t => {
      rowsUsed = rowsUsed.concat(this._generateNumbers(t.y, t.y + 1))
    })
    const allNumber = this._generateNumbers(0, _maxBy(this.tiles, 'y')?.y + 1)

    return allNumber.filter(x => !_uniq(rowsUsed).includes(x))
  }

  /**
   * Remove all addTile in empty rows
   */
  _removeEditFromEmptyRows () {
    if (!this.tiles.length) return
    const emptyRows = this._getEmptyRows()
    emptyRows.forEach(r => {
      this.tiles = this.tiles.filter(t => t.y !== r || t.type === 'add-row')
    })

    // If no tiles left, remove addTiles row
    // if (!this.cleanLayout.length) this.tiles = this.tiles.splice(0, this.tiles.length)
    if (!this.cleanLayout.length) this.tiles = []
  }

  /**
   * Given two layout tiles, check if they collide.
   *
   * @return {Boolean} True if colliding.
   */
  _collides (t1, t2) {
    if (t1 === t2) return false // same element
    if (t1.x + t1.width <= t2.x) return false // t1 is left of t2
    if (t1.x >= t2.x + t2.width) return false // t1 is right of t2

    if (this.getItemTop(t1.y) + t1.height <= this.getItemTop(t2.y)) return false
    if (this.getItemTop(t1.y) >= this.getItemTop(t2.y) + t2.height) return false

    return true // boxes overlap
  }

  /**
   * Get layout tiles sorted from top left to right and down.
   *
   * @param {Boolean} all If true, return all tiles included edit tiles
   */
  _sortLayoutTilesByRowCol (all = false, tiles = this.tiles) {
    const tilesToUse = all ? tiles : tiles.filter(t => !t.edit)
    return tilesToUse.sort((a, b) => {
      if (a.y === b.y && a.x === b.x) return 0
      if (a.y > b.y || (a.y === b.y && a.x > b.x)) return 1
      return -1
    })
  }

  /**
   * Compact the tiles. This involves going down each y coordinate and removing gaps
   * between items.
   *
   * @param {Object} minPositions
   */
  compact (minPositions) {
    // Statics go in the compareWith array right away so items flow around them.
    const compareWith = this._statics
    // We go through the items by row and column.
    const sorted = this._sortLayoutTilesByRowCol(true)

    // Remove edit tile in emptyRows
    this._removeEditFromEmptyRows()
    const emptyRows = this._getEmptyRows()

    for (let i = 0, len = sorted.length; i < len; i++) {
      const t = sorted[i]

      // Don't move static elements
      if ((!t.static && !t.edit) || t.edit) {
        this._compactTile(compareWith, t, minPositions, emptyRows)

        // Add to comparison array. We only collide with items before this one.
        // Statics are already in this array.
        if (!t.edit) compareWith.push(t)
      }

      // Clear moved flag, if it exists.
      t.moved = false
    }
  }

  /**
   * Compact a tile in the layout tiles.
   *
   * @param {Array} compareWith
   * @param {layoutTile} t
   * @param {Object} minPositins
   * @param {Array} emptyRows we pass it through params because this array is updated while compact
   */
  _compactTile (compareWith, t, minPositions, emptyRows) {
    // Go up if there is empty rows
    // We handle it differently because of addTiles
    let i = 0
    emptyRows.forEach(r => {
      if (t.y > r) i += 1
    })
    t.y -= i

    if (!t.edit) {
      if (this.horizontalCompact) {
        // Move the element left as far as it can go without colliding.
        while (t.x > 0 && !this._getFirstCollision(compareWith, t)) {
          t.x--
        }
      } else if (minPositions) {
        const minX = minPositions[t.i].x
        while (t.x > minX && !this._getFirstCollision(compareWith, t)) {
          t.x--
        }
      }

      // Move it right, and keep moving it down if it's colliding.
      let collides
      while ((collides = this._getFirstCollision(compareWith, t))) {
        t.x = collides.x + collides.width
      }
    }
  }

  /**
   * Returns the first tile this tile collides with.
   * It doesn't appear to matter which order we approach this from, although
   * perhaps that is the wrong thing to do.
   *
   * @param {Array} tiles Tiles.
   * @param {Object} tile Layout tile.
   * @param {Boolean} all If true, return all tiles included edeit tiles
   * @return {Object|undefined} A colliding layout tile, or undefined.
   */
  _getFirstCollision (tiles, tile, all = false) {
    const cleanTiles = all ? tiles : tiles.filter(t => !t.edit)
    for (let i = 0, len = cleanTiles.length; i < len; i++) {
      if (this._collides(cleanTiles[i], tile)) return cleanTiles[i]
    }
  }

  /**
   * Returns all tiles this calliding with tile.
   *
   * @param {Array} tiles Tiles.
   * @param {Object} tile Layout tile.
   * @param {Boolean} all If true, return all tiles included edeit tiles
   * @return {Object|undefined} A colliding layout tile, or undefined.
   */
  getAllCollisions (tiles, tile, all = false) {
    if (all) return tiles.filter(t => this._collides(t, tile))
    return tiles.filter(t => !['add-row', 'add-column'].includes(t.type) && this._collides(t, tile))
  }

  /**
   * Given tiles, make sure all elements fit within its bounds.
   *
   * @param {Object} bounds Number of columns.
   */
  correctBounds (bounds) {
    const collidesWith = this._statics
    for (let i = 0, len = this.tiles.length; i < len; i++) {
      const t = this.tiles[i]
      // Overflows right
      if (t.x + t.width > bounds.cols) t.x = bounds.cols - t.width
      // Overflows left
      if (t.x < 0) {
        t.x = 0
        t.width = bounds.cols
      }
      if (!t.static) collidesWith.push(t)
      else {
        // If this is static and collides with other statics, we must move it down.
        // We have to do something nicer than just letting them overlap.
        while (this._getFirstCollision(this.tiles, collidesWith, t)) {
          t.y++
        }
      }
    }
  }

  /**
   * Get a layout tile by ID. Used so we can override later on if necessary.
   *
   * @param {String} id ID
   * @return {LayoutTile} Item at ID.
   */
  getLayoutTile (id) {
    return this.tiles.find(t => t.i === id) || {}
  }

  /**
   * Get layout tiles by row.
   *
   * @param  {String} row row number
   * @return {LayoutItems} Items in row.
   */
  getRowTiles (row, all = false) {
    if (all) return this.tiles.filter(t => t.y === row)
    return this.tiles.filter(t => t.y === row && !t.edit)
  }

  /**
   * Add addTile end of row
   *
   * @param {Number} y y of row
   * @param {Number} height height f row
   */
  _addTileEndOfRow (y, height) {
    this.tiles.push({
      x: this.colNum,
      y,
      width: 0,
      height,
      i: `add-tiles-column-end-${uid()}`,
      edit: true,
      type: 'add-column',
      static: true,
      styleOverride: { width: '36px' }
    })
  }

  /**
   * Add addTile at the bottom of layout
   */
  _addTileBottom () {
    const maxY = this.bottom
    const yBottom = maxY + 1

    this.tiles.push({
      x: 0,
      y: yBottom,
      width: this.colNum,
      height: 0,
      i: `add-tiles-row-${uid()}`,
      edit: true,
      type: 'add-row',
      static: true,
      styleOverride: { height: '40px' }
    })
  }

  /**
   * Add 'add' tiles at the end of rows and at the bottom of layout to add tiles
   */
  addAddTiles () {
    this.tiles = this.tiles.filter(t => !['add-row', 'add-column'].includes(t.type))

    // Add addTiles columns
    const yUseByTiles = this.getYUsedByTiles
    const maxY = this.bottom
    for (let i = 0; i <= maxY; i += 1) {
      if (yUseByTiles.includes(i)) {
        const heightOfRow = this.tiles.find(t => t.y === i)?.height
        this._addTileEndOfRow(i, heightOfRow)
      }
    }

    // Add addTile rows
    if (this.tiles.length) {
      this._addTileBottom()
    }
  }

  /**
   * Get all Y use by tiles
   */
  get yUseByTiles () {
    return _uniq(this.tiles.map(t => t.y))
  }

  /**
   * Add addTile end of row
   *
   * @param {Number} y y of row
   * @param {Number} height height f row
   */
  _addVerticalResizeTile (y, height = 0) {
    this.tiles.unshift({
      x: 0,
      y,
      width: this.colNum,
      height: height + 20,
      i: `vertical-resize-bar-${uid()}`,
      edit: true,
      type: 'vertical-resize'
    })
  }

  /**
   * Add 'vertical-resize-bar' to resize height or row
   */
  addVerticalResizeTiles () {
    this.tiles = this.tiles.filter(t => !['vertical-resize'].includes(t.type))

    const yUseByTiles = this.getYUsedByTiles
    const maxY = this.bottom
    for (let i = 0; i < maxY; i += 1) {
      if (yUseByTiles.includes(i)) {
        const heightOfRow = this.tiles.find(t => t.y === i)?.height
        this._addVerticalResizeTile(i, heightOfRow)
      }
    }
  }

  /**
   * Add tile in layout
   *
   * @param {String} type To know where to add the tile
   */
  addTile (tile, type = null) {
    // Create new tile
    const newTile = {
      query: tile.query,
      title: tile.title
    }

    if (['add-row', 'add-column'].includes(type)) {
      newTile.x = this.colNum - this.minWidth
      newTile.y = tile.y
      newTile.width = type === 'add-column' ? this.minWidth : this.colNum
      newTile.height = type === 'add-column' ? tile.height : 230
      newTile.i = uid()

      if (type === 'add-row') {
        const addTileNewLine = this.tiles.find(t => t.type === 'add-row')
        addTileNewLine.y = addTileNewLine.y + 1

        this._addTileEndOfRow(newTile.y, newTile.height)
        this._addVerticalResizeTile(newTile.y, newTile.height)
      }
    }
    if (['vertical-resize'].includes(type) || !type) {
      newTile.x = 0
      newTile.y = type ? tile.y + 1 : this.bottom
      newTile.width = this.colNum
      newTile.height = 230
      newTile.i = uid()

      this.tiles.forEach(t => {
        if (t.y >= newTile.y) t.y += 1
      })

      this._addTileEndOfRow(newTile.y, newTile.height)
      this._addVerticalResizeTile(newTile.y, newTile.height)
      // If not add-row add button
      if (!this.tiles.find(t => t.type === 'add-row')) this._addTileBottom()
    }

    this.tiles.push(newTile)
    if (tile.type === 'add-column') {
      // this.moveElement(newTile, newTile.x, newTile.y, true, this.preventCollision)
      this.addTileInRow(newTile)
    }

    this.compact()
    this.updateOriginalTiles()

    return newTile
  }

  /**
   * Delete tile in layout
   *
   * @param {LayoutTIle} tile tile to delete
   */
  deleteTile (tile) {
    this.tiles = this.tiles.filter(t => t.i !== tile.i)
    this.compact()
    this.updateOriginalTiles()
  }

  /**
   * Duplicate tile in layout
   *
   * @param {LayoutTIle} tile tile to delete
   */
  duplicateTile (tile) {
    const newTile = _cloneDeep(tile)
    newTile.i = uid()
    const tilesInRow = _orderBy(this.getRowTiles(tile.y), 'x')
    const totalWidthInRow = _sumBy(tilesInRow, 'width')
    const rowHasSpace = this.colNum - totalWidthInRow >= this.minWidth

    if (rowHasSpace) { // If has space, add new tile on the right of duplicated tile
      // Move tiles on the right
      tilesInRow.forEach(t => {
        if (t.x > tile.x) t.x = t.x + this.minWidth
      })
      newTile.width = this.minWidth
      newTile.x = tile.x + tile.width

      this.tiles.push(newTile)
      this.compact()
      this.updateOriginalTiles()
    } else {
      // Add tile on a new row just below
      this.addTile(newTile, 'vertical-resize')
    }
  }

  /**
   * Reset tiles from origincalTiles
   *
   * @param {Number} y if set, update only tiles in this row
   */
  resetTilesFromOriginalTiles (y = null) {
    this.tiles.forEach(t => {
      if ((y === t.y || y === null) && !t.edit) {
        t.width = this.originalTilesObject[t.i].width
        t.height = this.originalTilesObject[t.i].height
      }
    })
  }

  /**
   * Resize height of all items in a row
   *
   * @param  {LayoutTile} tile element to move.
   * @param  {NUmber} y Y of row to update
   * @param  {NUmber} h new height to put in all items in row
   */
  resizeHeightTilesInRow (tile, y, height) {
    const tilesInRow = this.getRowTiles(y, true)

    tilesInRow.forEach(t => {
      t.height = t.type === 'vertical-resize' ? height : height - 20
    })
  }

  /**
   * Move an element. Responsible for doing cascading movements of other elements.
   *
   * @param  {LayoutTile} tile      element to move.
   * @param  {Number} x    X position in grid units.
   * @param  {Number} y    Y position in grid units.
   * @param  {Boolean} isUserAction If true, designates that the item we're moving is
   * being dragged/resized by th euser.
   * @param  {Boolean} preventCollision
   */
  moveElement (tile, x, y, isUserAction, preventCollision = false) {
    if (tile.static) return

    const oldX = tile.x
    const oldY = tile.y

    const movingLeft = x && tile.x > x
    // This is quite a bit faster than extending the object
    if (typeof x === 'number') tile.x = x
    if (typeof y === 'number') tile.y = y
    tile.moved = true

    // If this collides with anything, move it.
    // When doing this comparison, we have to sort the items we compare with
    // to ensure, in the case of multiple collisions, that we're getting the
    // nearest collision.
    let sorted = this._sortLayoutTilesByRowCol()
    if (movingLeft) sorted = sorted.reverse()
    const collisions = this.getAllCollisions(sorted, tile)

    if (preventCollision && collisions.length) {
      tile.x = oldX
      tile.y = oldY
      tile.moved = false
      return
    }

    // Move each item that collides away from this element.
    for (let i = 0, len = collisions.length; i < len; i++) {
      const collision = collisions[i]

      // Short circuit so we can't infinite loop
      if (collision.moved) continue

      // This makes it feel a bit more precise by waiting to swap for just a bit when moving up.
      // if (tile.x > collision.x && tile.x - collision.x > collision.w / 3) continue
      // if (tile.x < collision.x && tile.x - collision.x < collision.w / 4) continue

      if (collision.type === 'add-row') {
        console.log('do nothing ?')
      } else if (collision.static) { // Don't move static items - we have to move *this* element away
        this._moveElementAwayFromCollision(collision, tile, isUserAction)
      } else {
        this._moveElementAwayFromCollision(tile, collision, isUserAction)
      }
    }
  }

  /**
   * This is where the magic needs to happen - given a collision, move an element away from the collision.
   * We attempt to move it up if there's room, otherwise it goes below.
   *
   * @param  {LayoutTile} collidesWith Layout tile we're colliding with.
   * @param  {LayoutTile} tileToMove Layout tile we're moving.
   * @param  {Boolean} [isUserAction] If true, designates that the tile we're moving is being dragged/resized
   * by the user.
   */
  _moveElementAwayFromCollision (collidesWith, tileToMove, isUserAction) {
    const preventCollision = false // we're already colliding
    // If there is enough space above the collision to put this element, move it there.
    // We only do this on the main collision as this can get funky in cascades and cause
    // unwanted swapping behavior.
    if (isUserAction) {
      // Make a mock item so we don't modify the item here, only modify in moveElement.
      const fakeItem = {
        x: tileToMove.x,
        y: tileToMove.y,
        width: tileToMove.width,
        height: tileToMove.height,
        i: '-1'
      }
      fakeItem.x = Math.max(collidesWith.x - tileToMove.width, 0)
      if (!this._getFirstCollision(this.tiles, fakeItem)) {
        return this.moveElement(tileToMove, fakeItem.x, undefined, isUserAction, preventCollision)
      }
    }

    // Previously this was optimized to move below the collision directly, but this can cause problems
    // with cascading moves, as an item may actually leapflog a collision and cause a reversal in order.
    return this.moveElement(tileToMove, tileToMove.x + 1, undefined, isUserAction, preventCollision)
  }

  /**
   * Update width of colliding items when resize on the same row
   */
  addTileInRow (tile, destinationH = null) {
    const tilesInRow = _orderBy(this.getRowTiles(tile.y), 'x')

    // l.w = minWidth
    if (destinationH) tile.height = destinationH

    const totalWidthInRow = _sumBy(tilesInRow, 'width')
    const rowHasSpace = totalWidthInRow <= this.colNum

    if (!rowHasSpace) {
      // Resize item in row to fit
      tilesInRow.forEach(i => {
        i.width = Math.floor(this.colNum / tilesInRow.length)
      })
    }
  }

  /**
   * Resize an element. Responsible for doing cascading movements of other elements.
   *
   * @param  {LayoutTile} tile      element to move.
   * @param  {Number}     [x]    X position in grid units.
   * @param  {Number}     [w]    W width of element being resizing.
   * @param  {Number}     [h]    H height of element being resizing.
   * @param  {Boolean}    [isUserAction] If true, designates that the item we're moving is
   * being dragged/resized by th euser.
   */
  resizeElement (tile, x, y, w, h, isUserAction) {
    if (tile.static) return

    const oldX = tile.x
    const oldW = tile.width
    const oldH = tile.height

    const resizingLeft = x && tile.x < x

    if (typeof w === 'number') tile.width = w
    tile.resized = true

    // If this collides with anything, move it.
    // When doing this comparison, we have to sort the items we compare with
    // to ensure, in the case of multiple collisions, that we're getting the
    // nearest collision.
    let sorted = this._sortLayoutTilesByRowCol()
    if (resizingLeft) sorted = sorted.reverse()
    const collisions = this.getAllCollisions(sorted, tile)

    if (this.preventCollision && collisions.length) {
      tile.x = oldX
      tile.width = oldW
      tile.height = oldH
      tile.resized = false
      return
    }

    // 1 - Get all items in row
    const itemsInRow = this.getRowTiles(tile.y)
    // If 4 items in row (max) -> do nothing
    if (tile.i && itemsInRow.length < this.maxTilesInRow) {
      // 3 - Get total of widths from items
      const totalWidthInRow = _sumBy(itemsInRow, 'width')
      const rowHasSpace = this.colNum - totalWidthInRow >= 0

      // Move each item that collides away from this element.
      for (let i = 0, len = collisions.length; i < len; i++) {
        const collision = collisions[i]

        if (rowHasSpace && !resizingLeft) { // 4 - If space -> decale vers la droite
          collision.x = x + w
        } else if (collision.width > (this.colNum / (this.maxTilesInRow || 1))) { // 5 - If not, reduce size of next item first and if min size, reduce next item width
          collision.width -= 1
        } else {
          tile.x = oldX
          tile.width = oldW
          tile.height = oldH
        }
      }
    }

    tile.resized = false
  }

  /**
   * Validate tiles format. Throws errors.
   *
   * @throw {Error} Validation error.
   */
  validateTiles () {
    const contextName = 'Layout'
    const subProps = ['x', 'y', 'width', 'height']
    const keyArr = []
    if (!Array.isArray(this.tiles)) throw new Error(contextName + ' must be an array!')
    for (let i = 0, len = this.tiles.length; i < len; i++) {
      const item = this.tiles[i]
      for (let j = 0; j < subProps.length; j++) {
        if (typeof item[subProps[j]] !== 'number') {
          throw new Error('VueGridLayout: ' + contextName + '[' + i + '].' + subProps[j] + ' must be a number!')
        }
      }

      if (item.i === undefined || item.i === null) {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i cannot be null!')
      }

      if (typeof item.i !== 'number' && typeof item.i !== 'string') {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i must be a string or number!')
      }

      if (keyArr.indexOf(item.i) >= 0) {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i must be unique!')
      }
      keyArr.push(item.i)

      if (item.static !== undefined && typeof item.static !== 'boolean') {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].static must be a boolean!')
      }
    }
  }
}

export default Layout
