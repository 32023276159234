import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import Vue from 'vue'

// Exporting this model so that we won't need to duplicate the code everywhere
// for actions and workflows
const environmentModel = (obj = {}, type = null) => {
  obj.fpu = obj.fpu || {}
  obj.fpu.instances = obj.fpu.instances || 1
  obj.fpu.size = obj.fpu.size || (type?.includes('pyspark') ? 4 : 2)
  obj.fpu.type = obj.fpu.type || 'fpu-s'
  obj.log_level = obj.log_level || 'info'
  obj.service_account = obj.service_account || 'default'
  obj.variables = obj.variables || {}
  obj.type = obj.type || 'serverless'
  obj.repository_version_override = obj.repository_version_override || 'disabled'
  obj.params = typeof (obj.params) === 'object' ? obj.params : {}
  obj.params.auto_flushall = typeof (obj.params.auto_flushall) !== 'undefined' ? obj.params.auto_flushall : true
  obj.params.segmentation = obj.params.segmentation || {}
  obj.params.perimeter = obj.params.perimeter || {}
  return obj
}

class Environment extends APIObject {
  constructor (options) {
    // Init
    super('DPE', options)

    this.tags = this.tags || {}
    this.tags.tags = this.tags.tags || []
    this.description = this.description || ''
    this._type = 'environment'
    this.type = options.type || 'serverless'
    this.fpu = this.fpu || {}
    this.fpu.instances = this.fpu.instances || 1
    this.fpu.size = this.fpu.size || 2
    this.fpu.type = this.fpu.type || 'fpu-s'
    this.repository_version_override = this.repository_version_override || 'disabled'
    this.service_account = this.service_account || 'default'
    this.log_level = this.log_level || 'info'
    this.variables = this.variables || {}
    this.params = typeof (this.params) === 'object' ? this.params : {}
    this.params.segmentation = this.params.segmentation || {}
    this.params.perimeter = this.params.perimeter || {}
    this.params.auto_flushall = typeof (this.params.auto_flushall) !== 'undefined' ? this.params.auto_flushall : true
    this.display_name = this.display_name || ''
  }

  clone () {
    const environment = new Environment(_omit(_cloneDeep(this), ['_id']))
    return environment
  }

  _filter (object) {
    return _omit(super._filter(object), [
      '_id',
      '__socketId',
      '_type',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v3/environments'
    })
  }

  async save () {
    Vue.$analytics.track('Edit environment', {
      environment_id: this._id,
      auto_flushall: this.params?.auto_flushall,
      execution_mode: this.type === 'deployment' ? 'always-ready' : 'serverless',
      resources_type: this.fpu?.type,
      resources_allocated: this.fpu?.size * this.fpu?.instances,
      nb_of_instances: this.fpu?.instances,
      perimeter_option: this.params?.perimeter?.type ? Vue.i18n.translate(`preferences.editor.type.${this.params?.perimeter?.type}`) : 'None',
      segmentation_option: this.params?.segmentation?.type ? Vue.i18n.translate(`preferences.editor.type.${this.params?.segmentation?.type}`) : 'None',
      log_level: this.log_level,
      nb_of_environment_variables: Object.keys(this.variables).length
    })

    return super.save({
      method: 'PUT',
      url: `v3/environments/${this._id}`
    })
  }

  async remove () {
    Vue.$analytics.track('Delete environment', {
      environment_id: this._id
    })

    return this.request({
      method: 'DELETE',
      url: `v3/environments/${this._id}`
    })
  }
}

class Environments extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v3/environments'
    }).then(environments => {
      return environments.map(environment => {
        try {
          return new Environment(environment)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Environment(item)
  }

  createFromObject (object) {
    return new Environment(object)
  }
}

export default Environments

export {
  Environment,
  Environments,
  environmentModel
}
