import Vue from 'vue'

import FpApp from './FpApp'
import FpAside from './FpAside'
import FpHeader from './FpHeader'
import FpSplashError from './FpSplashError'
import FpTitle from './FpTitle'
Vue.component('FpApp', FpApp)
Vue.component('FpAside', FpAside)
Vue.component('FpHeader', FpHeader)
Vue.component('FpSplashError', FpSplashError)
Vue.component('FpTitle', FpTitle)
