/* eslint-disable camelcase */
import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class ServiceAccount extends APIObject {
  constructor (options) {
    super('IAM', options)

    this._id = this._id || ''
    this.display_name = this.display_name || ''
    this.email = this.email || ''
    this.tags = this.tags || []
    this.active = this.active || true
    this.configuration = this.configuration || {}
    this.configuration.logo = this.configuration.logo || ''
    this.login = this.login || ''
    this.roles = this.roles || []
    this.groups = this.groups || []
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'apikeys',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/service_accounts'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/service_accounts/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/service_accounts/${this._id}`
    })
  }

  generatePassword (id, send) {
    return this.request({
      method: 'get',
      url: `v4/service_accounts/${id}/generatePassword?send=${send}`
    })
  }

  generateApiKey ({ id, apikey_lifetime, send, comment }) {
    return this.request({
      method: 'post',
      url: `v4/service_accounts/${id}/apikey`,
      params: {
        send,
        apikey_lifetime,
        comment
      }
    })
  }

  removeApiKey ({ id, apikey }) {
    return this.request({
      method: 'delete',
      url: `v4/service_accounts/${id}/apikey/${apikey}`
    })
  }
}

class ServiceAccounts extends API {
  list () {
    return this.paginateListing({
      method: 'get',
      url: 'v4/service_accounts'
    })
      .then(data => {
        return data.map(service_account => new ServiceAccount(service_account))
      })
  }

  impersonate () {
    return this.request({
      method: 'get',
      url: 'v4/service_accounts/impersonate'
    })
  }

  names () {
    return this.request({
      method: 'get',
      url: 'v4/service_accounts/names'
    }).then(res => res.service_accounts)
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new ServiceAccount(item)
  }
}

export default ServiceAccounts
export {
  ServiceAccount,
  ServiceAccounts
}
