<template lang="pug">
  .fpui-tabs(:class='{"alternative": alternative}')
    ul.fpui-tabs-heads
      li.fpui-tabs-head(
        v-for="(tab, index) in tabs"
        :title="tab.header"
        v-if='!tab.hide'
        :class="{ active: index === active, disabled: tab.disabled }"
        @click="setActive(index)"
      )
        span(v-html='tab.header')
      li.fpui-tabs-head(
        v-if='actions'
        v-for='action, idx in actions'
      )
        span(v-html='action.label', @click='action.click')
    .fpui-tabs-contents
      slot
</template>

<script>
export default {
  props: {
    defaultActive: { type: Number, default: 0 },
    actions: { type: Array, default: () => [] },
    alternative: { type: Boolean, required: false }
  },
  data () {
    const active = 0
    return {
      active,
      tabs: []
    }
  },
  computed: {
    activeTab () {
      return this.tabs[this.active]
    }
  },
  created () { this.tabs = this.$children },
  mounted () {
    this.active = this.defaultActive || this.active
    if (this.tabs && this.tabs[this.active]) {
      this.tabs[this.active].active = true
    }
  },
  methods: {
    setActive (index) {
      const tab = this.tabs[index]
      if (tab.disabled) return
      this.active = index
      this.tabs.forEach((tab, idx) => {
        tab.active = index === idx
      })
      this.$emit('change', { tab: this.activeTab, active: this.active })
      // Hack - To be sure that every subcomponent get the real size we force a resize event
      process.nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fpui-tabs {
  &:not(.alternative) {
    .fpui-tabs-title{
      text-transform: uppercase;
      position: absolute;
      padding: 0;
      line-height: 40px;
      overflow: hidden;
    }
    .fpui-tabs-contents {
      .fpui-tab-content {
        padding: 0 10px 10px 10px;
      }
    }
    .fpui-tabs-heads {
      border-bottom: 1px solid #D6DEE5;
      padding: 0;
      margin: 0;
      width: 100%;
      overflow-y: hidden;
      height: 28px;
      overflow-x: auto;
      white-space: nowrap;
      padding-left:60px;
      &.right{
        text-align: right;
      }
      &.left{
        text-align: left;
      }
      .fpui-tabs-head {
        overflow: hidden;
        vertical-align: top;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 300;
        position: relative;
        color:#97A7B7;
        line-height: 28px;
        display: inline-block;
        font-family: "Source Sans Pro";
        font-size: 18px;
        margin-right: 60px;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          border-bottom: 3px solid #10b6e9;
          transition: 0.5s;
          color: #0089C0;
        }
        &:before {
          display: block;
          content: attr(title);
          font-weight: normal;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }

        &:hover,&.active{
          color: #0089C0;
          font-weight: normal;
          &:after {
            border-bottom: 3px solid @blue;
            width: 100%;
          }
        }
        &.disabled {
          color: @grey;
          cursor: not-allowed;
          &:after {
            border-bottom: none;
            width: 100%;
          }
        }
      }
      .fpui-tabs-head:first-of-type {
        margin-left: 0 !important
      }
    }
  }
}

</style>
