<template lang="pug">
  .queries-history-content
    fpui-table(
      v-if='queriesHistory.length',
      :columns='columns'
      :actions='table.actions'
      :data='queriesHistory'
      :pagination='{ perPage: queryId ? 8 : 15 }'
      row-key='_id'
      :onClick='setActiveHistory'
      :defaultActiveRow="activeHistory"
    )
    .no-execution(v-if='!queriesHistory.length')
      img(src='@/qb/assets/img/placeholder.png')
      div(v-if='type === "running"') {{ $t('history_execution.table.nodata.running') }}
      div(v-if='type === "queued"') {{ $t('history_execution.table.nodata.queued') }}
      div(v-if='type === "past"') {{ $t('history_execution.table.nodata.past') }}
      div(v-if='type === "latest"') {{ $t('history_execution.table.nodata.latest') }}
</template>

<script>
import moment from 'moment'
import FpuiSqlEditor from '@/shared/components/editors/FpuiSqlEditor'

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ['running', 'queued', 'past', 'latest'].includes(value)
      }
    },
    filters: { type: Object, default: () => ({}) },
    queryId: { type: String, default: '' },
    // activeHistory is used in query metrics page
    activeHistory: { type: Object, default: () => {} },
    fromHistoryPage: { type: Boolean, default: false },
    fromExplorer: { type: Boolean, default: false }
  },
  data () {
    return {
      sortBy: '',
      table: {
        actions: []
      }
    }
  },
  computed: {
    trinoActive () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE
    },
    queriesHistory () {
      if (!this.trinoActive) return []
      const historyToUse = this.queryId ? this.$store.getters.QB_QUERY_HISTORY_BY_QUERY_ID(this.queryId) : this.$store.getters.QB_QUERIES_HISTORY
      const history = [...historyToUse].reverse()
      if (this.fromExplorer) return history.filter(h => h.origin === 'Explorer')
      if (this.type === 'latest') return history.filter(h => h.origin !== 'Explorer').slice(0, 5)
      if (this.filters.origin === 'all' &&
        this.filters.status === 'all' &&
        !this.filters.search) {
        return history
      }
      return history.filter(h => {
        const authorDisplayName = this.$store.getters.IAM_USER_BY_UID(h.created_by, 'displayname', true)
        if (
          (this.filters?.origin === h?.origin || this.filters?.origin === 'all') &&
          (this.filters?.status === h?.status || this.filters?.status === 'all') &&
          (authorDisplayName?.toLowerCase()?.includes((this.filters?.search)?.toLowerCase()) || !this.filters?.search)
        ) {
          return true
        }
        return false
      })
    },
    columns () {
      return [
        {
          name: 'status',
          label: this.$t('history_execution.table.column.status'),
          align: 'left',
          paddingLeft: 20,
          target: row => {
            let icon = ''
            let content = []
            let className = row.status.toLowerCase()
            let text = row.status.toLowerCase()
            if (row.isActive) {
              icon = require('@/qb/assets/img/ic_spinner.png')
              content.push(`<img class='running rotating' src="${icon}"></img>`)
            }

            switch (row.status) {
              case 'SUCCESS': // Query has finished executing and all output has been consumed.
                icon = 'fp4 fp4-check-bold'
                content.push(`<i class='${icon}'></i>`)
                break
              case 'FAILED': // Query execution failed.
                icon = 'fp4 fp4-xmark-bold'
                content.push(`<i class='${icon}'></i>`)
                break
              case 'FINISHING': // Query is finishing (e.g. commit for autocommit queries)
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                break
              case 'RUNNING': // Query has at least one running task.
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                break
              case 'STARTING': // Query execution is being started.
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                break
              case 'PLANNING': // Query is being planned.
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                break
              case 'DISPATCHING': // Query is being dispatched to a coordinator.
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                text = 'loading'
                break
              case 'WAITING_FOR_RESOURCES': // Query is waiting for the required resources (beta).
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                text = 'loading'
                break
              case 'QUEUED': // Query has been accepted and is awaiting execution.
                content.push(`<img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />`)
                className = 'container-loading'
                break
              default:
                return ''
            }
            content.push(`<span class='text'>${text}</span>`)

            content = [
              `<div class="history-status ${className}">`,
              ...content,
              '</div>']
            content = content.join('')

            return content
          },
          grow: 2
        },
        {
          name: 'started on',
          label: this.$t('history_execution.table.column.started_on'),
          target: row => {
            return row.created_at ? moment(row.created_at).format(this.$t('history_execution.table.column.started_on.format')) : '-'
          },
          grow: 2.5,
          sortable: (a, b) => {
            const dateA = moment(a.created_at)
            const dateB = moment(b.created_at)
            return dateA.isBefore(dateB)
          }
        },
        {
          name: 'runtime',
          label: this.$t('history_execution.table.column.runtime'),
          target: row => row.runtime_text || 0,
          sortable: true,
          grow: 1.5
        },
        {
          name: 'progress',
          label: this.$t('history_execution.table.column.progress'),
          target: row => {
            let className = row.status.toLowerCase()
            if (!['SUCCESS', 'FAILED', 'UNKNOWN'].includes(row.status)) className = 'running'
            const content = `
            <div class="progress-bar">
              <div class="progress ${className}" style="width: ${row.progress}%"></div>
            </div>
            `
            return content
          },
          grow: 2,
          if: () => {
            return window.innerWidth > 1600 && this.fromHistoryPage
          }
        },
        {
          name: 'rows_returned',
          label: this.$t('history_execution.table.column.row_returned'),
          target: row => row.rows_returned.toLocaleString(),
          sortable: (a, b) => {
            return parseInt(a.rows_returned) > parseInt(b.rows_returned)
          },
          width: 100
        },
        {
          name: 'requester',
          label: this.$t('history_execution.table.column.requester'),
          target: row => {
            return this.$store.getters.IAM_USER_BY_UID(row.created_by, 'displayname', true)
          },
          sortable: true,
          grow: 2
        },
        {
          name: 'origin',
          label: this.$t('history_execution.table.column.origin'),
          target: row => {
            return row.origin ? this.$t(`history_execution.table.column.origin.${row.origin.toLowerCase()}`, row.origin) : '-'
          },
          sortable: true,
          grow: 2
        },
        {
          name: 'sql',
          label: this.$t('history_execution.table.column.sql'),
          target: (row) => {
            return {
              value: row.sql || '',
              expand: true,
              readOnly: true,
              inlineOnly: true
            }
          },
          component: FpuiSqlEditor,
          grow: 5,
          paddingRight: 50
        }
      ]
    }
  },
  methods: {
    setActiveHistory (row) {
      if (this.activeHistory) this.$emit('active-history', row)
    }
  }
}
</script>

<style lang="less">
.queries-history-content {
  height: 100% !important;
  > .no-execution {
    text-align: center;
    color: #B2BECA;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 18px;
    user-select: none;
    > img {
      width: 99px;
    }
  }
  .fpui-table {
    width: 100%;
    height: calc(~"100% + 10px") !important;
    overflow-y: hidden;
    overflow-x: hidden;
    .history-status {
      display: flex;
      align-items: center;
      height: 18px;
      width: fit-content;
      padding-right: 8px;
      border-radius: 9px;
      font-size: 12px;
      color: @white;
      .text {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 12px;
        margin-left: 5px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
      &.success {
        background-color: @green;
        i {
          font-size: 12px;
          margin-left: 4px !important;
          margin-right: 1px;
          margin-left: 1px;
        }
      }
      &.container-loading {
        background-color: @blue_flash;
        img {
          animation: rotating 1s infinite;
          -webkit-animation: rotating 1s infinite;
          background-color: @white;
          width: 14px;
          border-radius: 8px;
          margin-left: 2px;
        }
      }
      &.failed {
        background-color: @red;
        .text {
          margin-left: 3px;
        }
        i {
          font-size: 16px;
          margin-left: 1px;
          margin-right: 1px;
          margin-left: 1px;
        }
      }
    }
    .progress-bar {
      width:100%;
      height: 5px;
      background-color: #E9ECF0;
      border-radius: 2.5px;
      .progress{
        height: 100%;
        border-radius: 2.5px;
        &.success {
          background-color: @green;
        }
        &.running {
          background-color: @blue_flash;
        }
        &.failed {
          background-color: @grey;
        }
      }
    }
    .fpui-sql-editor {
      height: 78px;
      border-radius: 5px;
      background-color: #3E4550;
      iframe {
        height: 100%;
      }
    }
    .fpui-table_row {
      height: 90px!important;
      .fpui-table_row-actions {
        padding-right: 35px;
        background: transparent;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
  .no-execution {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .message {
      margin-top: 50px;
      font-size: 14px;
      line-height: 18px;
      color: @grey-chart;
    }
  }
}
</style>
