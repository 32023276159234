import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import moment from 'moment'
import Vue from 'vue'
import { environmentModel } from './Environments'

class Pipeline extends APIObject {
  constructor (options) {
    // Init
    super('ML', options)

    // Default variables
    this.__lastStart = null
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
    this.modules = this.modules || []
    this.starting = this.starting || false
    this.consumers = this.consumers || []

    // Envs
    this.environment = this.environment || {}
    switch (typeof (this.environment)) {
      case 'string':
        break
      case 'object':
        break
      default:
        this.environment = {}
    }

    if (typeof (this.environment) === 'object') {
      environmentModel(this.environment)
    }
  }

  init () {
    super.init()
    this.__saveQueueDataset = {}
    this.__saveQueueTrain = {}
    this.__saveQueueScore = {}
    this.__saveDebouncedDataset = _debounce(this.saveDataset, 1000)
    this.__saveDebouncedTrain = _debounce(this.saveTrain, 1000)
    this.__saveDebouncedScore = _debounce(this.saveScore, 1000)
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Pipeline(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__lastStart',
      '__socketId',
      'tags.table',
      'pipeline_id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name',
      'fppm',
      'starting'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v2/pipelines'
    })
  }

  refreshSaveQueue (key) {
    const saveKey = key.split(/[.\][]/).filter(v => v)[0]
    const saveValue = _get(this, saveKey)
    if (!_isEqual(this.__backup[saveKey], saveValue)) {
      switch (saveKey) {
        case 'dataset':
          Vue.set(this.__saveQueueDataset, saveKey, saveValue)
          break
        case 'train':
          Vue.set(this.__saveQueueTrain, saveKey, saveValue)
          break
        case 'score':
          Vue.set(this.__saveQueueScore, saveKey, saveValue)
          break
        default:
          Vue.set(this.__saveQueue, saveKey, saveValue)
          break
      }
    } else {
      switch (saveKey) {
        case 'dataset':
          Vue.delete(this.__saveQueueDataset, saveKey)
          break
        case 'train':
          Vue.delete(this.__saveQueueTrain, saveKey)
          break
        case 'score':
          Vue.delete(this.__saveQueueScore, saveKey)
          break
        default:
          Vue.delete(this.__saveQueue, saveKey)
          break
      }
    }
  }

  update (key, value, autoSave = true, socketId = true) {
    if (_isEqual(_get(this, key), value)) return
    this.recursiveSet(this, key, value)
    this.refreshSaveQueue(key)
    if ((this.id || this._id) && autoSave) {
      this.__saving = true
      const param = key.split('.')[0]
      switch (param) {
        case 'dataset':
          this.__saveDebouncedDataset()
          break
        case 'train':
          this.__saveDebouncedTrain()
          break
        case 'score':
          this.__saveDebouncedScore()
          break
        default:
          if (!socketId) this.save(socketId)
          else this.__saveDebounced()
          break
      }
    }
    this.__v++
  }

  async putToDataStore (fileData, filename) {
    const formData = new FormData()
    formData.append('file', fileData)
    return this.request({
      method: 'PUT',
      url: `v2/repositories/${this.repository}/versions/${this.repository_version}/files/${filename}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async getDataStoreFile (filename) {
    const fileData = await this.request({
      method: 'get',
      url: `v2/repositories/${this.repository}/versions/${this.repository_version}/files/${filename}`
    })
    return fileData
  }

  async getFileList () {
    const fileList = await this.request({
      method: 'get',
      url: `v2/repositories/${this.repository}/versions/${this.repository_version}/files`
    })
    return fileList
  }

  async removeDataStoreFile (filename) {
    await this.request({
      method: 'delete',
      url: `v2/repositories/${this.repository}/versions/${this.repository_version}/files/${filename}`
    })
  }

  async resetDataset () {
    await this.request({
      method: 'post',
      url: `v2/pipelines/${this.pipeline_id}/dataset/reset`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async save (socketId = true) {
    return super.save({
      method: 'PUT',
      url: `v2/pipelines/${this.pipeline_id}`,
      params: {
        repository_version: this.repository_version
      },
      socketId
    })
  }

  async saveDataset () {
    this.saveParam('dataset', '__saveQueueDataset')
  }

  async saveTrain () {
    this.saveParam('train', '__saveQueueTrain')
  }

  async saveScore () {
    this.saveParam('score', '__saveQueueScore')
  }

  async saveParam (param, paramQueue) {
    const options = {
      method: 'PUT',
      url: `v2/pipelines/${this.pipeline_id}/${param}`,
      params: {
        repository_version: this.repository_version
      }
    }

    const saveQueue = this[paramQueue][param]
    this[paramQueue] = {}
    this.__saving = true
    options.data = this._filter(saveQueue)
    try {
      await this.request(options)
      this.__error = false
      return this
    } catch (err) {
      this[paramQueue][param] = saveQueue
      this.__error = err
      throw err
    } finally {
      this.__saving = false
    }
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v2/pipelines/${this.pipeline_id}`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async install () {
    return this.request({
      method: 'PUT',
      url: `v2/pipelines/${this.pipeline_id}/install`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async start (step = '', data = {}) {
    try {
      this.starting = true
      Store.commit('SET_OPEN_CONSOLE', true)
      const stepUrl = step ? `/${step}` : ''

      await this.request({
        method: 'POST',
        url: `v2/pipelines/${this.pipeline_id}${stepUrl}/start`,
        params: {
          repository_version: this.repository_version
        },
        socketId: false,
        data
      })

      // analytics tracking
      Vue.$analytics.track('Play pipeline', {
        pipeline_id: this._id,
        launch_method: 'Manual',
        dataset: data.pipeline.dataset,
        training: data.pipeline.train,
        scoring: data.pipeline.score,
        deployment: data.pipeline.deployment
      })
    } catch (err) {
      // We handle 409, cause it should push the request into "started state"
      if (err.status !== 409) {
        throw err
      }
    } finally {
      this.starting = false
    }
  }

  async stop (step = '') {
    this.__lastStart = null
    const stepUrl = step ? `/${step}` : ''
    return this.request({
      method: 'DELETE',
      url: `v2/pipelines/${this.pipeline_id}${stepUrl}/stop`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async stopBuild () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v2/pipelines/${this.pipeline_id}/build/stop`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async stopInstall () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v2/pipelines/${this.pipeline_id}/install`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  get buildTime () {
    if (!this.buildStartTime) return null
    if (this.buildEndTime) {
      return this.buildEndTime.unix() - this.buildStartTime.unix()
    }
    return moment().unix() - this.buildStartTime.unix()
  }

  get buildStartTime () {
    if (!_get(this, 'build.start_time')) return null
    return Date(this.build.start_time)
  }

  get buildEndTime () {
    if (!_get(this, 'build.end_time')) return null
    return Date(this.build.end_time)
  }

  get datasetAvailable () {
    if (
      this.dataset?.input?.features.length &&
      this.dataset?.output?.features.length &&
      this.dataset?.nature &&
      this.dataset?.ratio &&
      this.dataset?.split_params?.type &&
      this.dataset?.train_source &&
      this.dataset?.testing_source
    ) return true
    return false
  }

  get trainAvailable () {
    let check = true
    if (this.dataset?.testing_source === this.dataset?.train_source && !this.train?.ratio) check = false
    if (this.train?.algorithm && check) return true
    return false
  }

  get scoreAvailable () {
    if (this.score?.functions.length) return true
    return false
  }
}

class Pipelines extends API {
  async get (id, repository, repositoryVersion) {
    const pipeline = await this.request({
      method: 'get',
      url: `v2/pipelines/${id}`,
      params: {
        full: true,
        repository,
        repository_version: repositoryVersion
      }
    })
    return new Pipeline(pipeline)
  }

  async list () {
    const pipelines = await this.request({
      method: 'get',
      url: 'v2/pipelines',
      params: {
        algorithm: true
      }
    })

    return pipelines.map(pipeline => {
      try {
        return new Pipeline(pipeline)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Pipeline(item)
  }
}

export default Pipelines
export {
  Pipeline,
  Pipelines
}

