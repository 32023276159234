<template lang="pug">
.mfa-authenticator
  //- .text-center(v-if="alreadySent")
  //-   p {{ $t('authentication.mfa.send.authenticator') }}
  .text-center(v-if="noMfaSecret&&!alreadySent")
    button.mfa-button(
      :style="buttonStyle"
      @click="send"
      type="button"
    ) {{ $t('authentication.mfa.authenticator.send') }}
  .text-center(v-if="!noMfaSecret||alreadySent")
    label {{$t('authentication.mfa.authenticator.code_label')}}
    input(
      type="text"
      :value="value"
      :placeholder="$t('authentication.mfa.code')"
      @input="(event)=>$emit('input',event.target.value)"
    )
</template>

<script>
export default {
  icon: 'fp4 fp4-authenticator',
  props: {
    noMfaSecret: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    login: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      alreadySent: false
    }
  },
  computed: {
    buttonStyle () {
      return {
        color: 'white',
        backgroundColor: this.color,
        cursor: 'pointer',
        opacity: 1
      }
    }
  },
  methods: {
    async send () {
      if (this.noMfaSecret) {
        try {
          await this.$store.dispatch('SEND_MFA_CODE', {
            login: this.login,
            password: this.password,
            type: 'authenticator'
          })
          this.$fpuiMessageBlock.pop('success', this.$t('authentication.mfa.send.authenticator'))
          this.$emit('mfa-sent')
          this.alreadySent = true
        } catch (err) {
          console.error(err)
          this.$fpuiMessageBlock.error(err)
        }
      }
    }
  }
}
</script>

<style lang="less">
.mfa-authenticator {
  .text-center {
    text-align: center;
    .button-container {
      display: inline-block;
    }
    label {
      text-transform: none;
      max-width: 565px;
      color: #3e4550;
      font-size: 14px;
      font-weight: normal;
      margin: 0 auto;
    }
    input {
      width: 200px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>
