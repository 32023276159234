import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'

class User extends APIObject {
  constructor (options) {
    super('KING', options, true)
    this.defaultOptions()
  }

  defaultOptions () {
    this._id = this._id || ''
    this.id = this.id || ''
    this.email = this.email || ''
    this.profile = this.profile || []
    this.profile = this.profile.map(p => {
      p.company_role = p.company_role || ''
      return p
    })
    this.consent_details = this.consent_details || {}
    this.onboarding = this.onboarding || {}
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'onboarding.trials_left'
    ])
    return obj
  }

  getMainProfile () {
    return this.profile.find(p => p.main === true)
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v1/users/${this.id}`
    })
  }

  async resetPassword (password) {
    return await this.request({
      method: 'PUT',
      url: `v1/users/${this.id}/password`,
      data: password,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  async addMfaConfiguration (type) {
    // Get Mfa configuration by type: authenticator/sms
    return await this.request({
      method: 'POST',
      url: 'v1/users/mfa/configuration/' + type,
      withCredentials: true
    })
  }

  async activateMfaConfiguration (type, data) {
    return await this.request({
      method: 'PUT',
      url: 'v1/users/mfa/configuration/' + type + '/activate',
      withCredentials: true,
      data
    })
  }

  async removeMfa (type) {
    return await this.request({
      method: 'DELETE',
      url: 'v1/users/mfa/' + type
    })
  }

  async sendSMS (type) {
    return await this.request({
      method: 'DELETE',
      url: 'v1/users/mfa/' + type
    })
  }

  async listSession () {
    return await this.request({
      method: 'GET',
      url: 'v1/oauth2/list'
    })
  }

  async revokeSession (id) {
    return await this.request({
      method: 'POST',
      url: 'v1/oauth2/revoke',
      data: {
        id: id
      }
    })
  }
}
class Users extends API {
  constructor (ID) {
    super(ID, true)
  }

  async me (options = {}) {
    options.noReconnect = options.noReconnect || false
    return this.request({
      method: 'GET',
      url: 'v1/users/me',
      retries: 0,
      ...options
    })
  }

  async subscriptionsFromAllOrganizations () {
    const subscriptions = await this.request({
      method: 'GET',
      url: '/v1/users/subscriptions_from_all_organizations'
    })
    return subscriptions
  }

  async getKingPictureOfUser (email) {
    return this.request({
      method: 'GET',
      url: `/v1/files/users/${email}/picture.jpeg`
    })
  }
}

export default Users
export {
  User,
  Users
}
