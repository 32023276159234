/*
Exemple format : {
  {
    sites: [1,2,3,4]
  }
}

Exemple format : {
  {
    sites: 1
  }
}

Exemple format : {
  {
    sites: {
      equal: 1
    }
  }
}

Exemple format : {
  {
    sites: {
      equal: [1]
    }
  }
}

*/
const moment = require('moment')

class Filter {
  constructor (filters = {}) {
    this._ = {}
    this.attributes = []

    for (const key in filters) {
      this._[key] = this.parse(key, filters[key])
    }
  }

  parse (key, value) {
    if (Array.isArray(value)) {
      const v = this.parseValues('in', key, value)
      if (typeof (v) === 'undefined') return undefined
      return {
        in: v
      }
    }
    if (!Array.isArray(value) && typeof (value) !== 'object') {
      const v = this.parseValues('equal', key, [value])
      if (typeof (v) === 'undefined') return undefined
      return {
        equal: v
      }
    }
    const res = {}
    for (const operator in value) {
      const v = this.parseValues(operator, key, value[operator])
      if (typeof (v) !== 'undefined') res[operator] = v
    }
    if (!Object.keys(res).length) return undefined
    return res
  }

  parseValues (operator, key, value) {
    if (operator === 'equal' || operator === 'not_equal') {
      return Array.isArray(value) ? [this.parseValue(key, value[0])] : [this.parseValue(key, value)]
    }
    if (operator === 'in' || operator === 'not_in') {
      return Array.isArray(value) ? value.map(v => this.parseValue(key, v)) : [this.parseValue(key, value)]
    }
    if (operator === 'like' || operator === 'not_like') {
      return Array.isArray(value) ? value.map(v => this.parseValue(key, v)) : [this.parseValue(key, value)]
    }
    if (operator === 'gte' || operator === 'gt' || operator === 'lte' || operator === 'lt') {
      return Array.isArray(value) ? value.map(v => this.parseValue(key, v)) : [this.parseValue(key, value)]
    }
    if (operator === 'between' || operator === 'not_between') {
      return Array.isArray(value) && value.length === 2 ? value.map(v => this.parseValue(key, v)) : undefined
    }
    if (operator === 'is_not_null' || operator === 'is_null') {
      return []
    }
  }

  parseValue (key, value) {
    return value
  }

  setAttributesMetas (attrs) {
    this.attributes = attrs
  }

  get fields () {
    return Object.keys(this._)
  }

  toJSON () {
    return this._
  }

  toSqlDate (date = moment()) {
    return `date '${date.format('YYYY-MM-DD')}'`
  }

  toSqlTime (time = moment()) {
    return `time '${time.format('HH:mm:ss')}'`
  }

  toSqlDateTime (date = moment()) {
    return `timestamp '${date.format('YYYY-MM-DD HH:mm:ss')}'`
  }

  toSQLValue (attr, value, position = 'start') {
    if (attr && attr.type.main === 'date') {
      const [, month, day] = ('' + value).split('-')
      if (position === 'end') {
        if (!month) return this.toSqlDate(moment(value, 'YYYY').endOf('year'))
        if (!day) return this.toSqlDate(moment(value, 'YYYY-MM').endOf('month'))
      }
      if (!month) return this.toSqlDate(moment(value, 'YYYY').startOf('year'))
      if (!day) return this.toSqlDate(moment(value, 'YYYY-MM').startOf('month'))
      return this.toSqlDate(moment(value, 'YYYY-MM-DD'))
    }
    if (attr && attr.type.main === 'time') {
      const [, minute, second] = ('' + value).split(':')
      if (position === 'end') {
        if (!minute) return this.toSqlTime(moment(value, 'HH').endOf('hour'))
        if (!second) return this.toSqlTime(moment(value, 'HH:mm').endOf('minute'))
      }
      if (!minute) return this.toSqlTime(moment(value, 'HH').startOf('hour'))
      if (!second) return this.toSqlTime(moment(value, 'HH:mm').startOf('minute'))
      return this.toSqlTime(moment(value, 'HH:mm:ss'))
    }
    if (attr && attr.type.main === 'datetime') {
      const [date, time] = ('' + value).split(' ')
      const [, month, day] = date.split('-')
      const [hour, minute, second] = (time || '').split(':')
      if (position === 'end') {
        if (!month) return this.toSqlDateTime(moment(value, 'YYYY').endOf('year'))
        if (!day) return this.toSqlDateTime(moment(value, 'YYYY-MM').endOf('month'))
        if (!hour) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD').endOf('day'))
        if (!minute) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD HH').endOf('hour'))
        if (!second) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD HH:mm').endOf('minute'))
      }
      if (!month) return this.toSqlDateTime(moment(value, 'YYYY').startOf('year'))
      if (!day) return this.toSqlDateTime(moment(value, 'YYYY-MM').startOf('month'))
      if (!hour) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD').startOf('day'))
      if (!minute) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD HH').startOf('hour'))
      if (!second) return this.toSqlDateTime(moment(value, 'YYYY-MM-DD HH:mm').startOf('minute'))
      return this.toSqlDateTime(moment(value, 'YYYY-MM-DD HH:mm:ss'))
    }

    if (attr && ['int', 'dec', 'num'].includes(attr.type.main) && !isNaN(parseFloat(value))) return parseFloat(value)
    if (typeof (value) === 'number') return value
    if (typeof (value) === 'boolean') return value
    return `'${value}'`
  }

  parseDate (value) {
    return `DATE('${moment(value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')})'`
  }

  toSQLOperator (key, operator, value) {
    const attr = this.attributes.find(a => a.name === key)

    if (operator === 'in') {
      return `${key} IN (${value.map(v => this.toSQLValue(attr, v)).join(', ')})`
    }
    if (operator === 'not_in') {
      return `${key} NOT IN (${value.map(v => this.toSQLValue(attr, v)).join(', ')})`
    }
    if (operator === 'equal') {
      return value.map(v => `${key} = ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'not_equal') {
      return value.map(v => `${key} != ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'like') {
      return value.map(v => `${key} LIKE ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'not_like') {
      return value.map(v => `${key} NOT LIKE ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'gt') {
      return value.map(v => `${key} > ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'gte') {
      return value.map(v => `${key} >= ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'lt') {
      return `${key} < ${this.toSQLValue(attr, value[0])}`
    }
    if (operator === 'lte') {
      return value.map(v => `${key} <= ${this.toSQLValue(attr, v)}`).join('\n  AND ')
    }
    if (operator === 'is_not_null') {
      return `${key} IS NOT NULL`
    }
    if (operator === 'is_null') {
      return `${key} IS NULL`
    }
    if (operator === 'between') {
      return `${key} BETWEEN ${this.toSQLValue(attr, value[0], 'start')} AND ${this.toSQLValue(attr, value[1], 'end')}`
    }
    if (operator === 'not_between') {
      return `${key} NOT BETWEEN ${this.toSQLValue(attr, value[0], 'start')} AND ${this.toSQLValue(attr, value[1], 'end')}`
    }
  }

  toSQL () {
    if (!Object.keys(this._).length) return null
    return `WHERE\n  ${Object.keys(this._).map(key => {
      return Object.keys(this._[key]).map(operator => {
        return this.toSQLOperator(key, operator, this._[key][operator])
      }).join('\n  AND ')
    }).join('\n  AND ')}`
  }
}

module.exports = Filter
