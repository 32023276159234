import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

const scatterSetsDefaults = {
  color: ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5'],
  series: {
    label: {
      show: false
    },
    type: 'scatter',
    symbolSize: 10,
    itemStyle: {
      opacity: 0.7
    }
  },
  dataset: null,
  legend: {
    show: false,
    left: 'center',
    top: 'bottom'
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      show: true
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true
    }
  },
  xAxisDetails: null,
  yAxisDetails: null,
  trendline: null
}

class Query extends APIObject {
  constructor (options) {
    // Init
    super('QUERY_ADMIN', options)

    // Default variables
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.configuration = this.configuration || { data: { fields: {} } }
    this.type = this.type || 'forepaas'
    this.chart_settings = this.chart_settings || {
      table: {
        pagination: false,
        paginationNumber: 25,
        json: false
      },
      'big-number': {
        compareToPrevious: false,
        percent: false
      },
      bar: {
        color: ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5'],
        series: {
          stack: false,
          label: {
            show_values: true,
            show_percent: false,
            position: 'top'
          },
          type: 'bar'
        },
        orientation: 'vertical',
        legend: {
          show: false,
          left: 'center',
          top: 'bottom'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true
          }
        }
      },
      line: {
        color: ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5'],
        series: {
          label: {
            show_values: true,
            show_percent: false,
            position: 'top'
          },
          type: 'line'
        },
        legend: {
          show: false,
          left: 'center',
          top: 'bottom'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true
          }
        }
      },
      area: {
        color: ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5'],
        series: {
          stack: true,
          label: {
            show_values: true,
            show_percent: false,
            position: 'top'
          },
          type: 'line',
          areaStyle: {}
        },
        legend: {
          show: false,
          left: 'center',
          top: 'bottom'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true
          }
        }
      },
      pie: {
        color: ['#39d2f5', '#0fb2f2', '#0f7ad2', '#ef5791', '#f7d460', '#b2e73f', '#f6b54d', '#5f6879', '#c3ccd5'],
        series: {
          label: {
            show: true,
            position: 'outside',
            formatter: '{b}\n{c}',
            lineHeight: 15
          },
          type: 'pie'
        },
        donut: false,
        legend: {
          show: false,
          left: 'center',
          top: 'bottom'
        }
      },
      scatter: scatterSetsDefaults
    }
    this.chart_active = this.chart_active || 'table'
    // Handle new scatter settings
    if (!this.chart_settings.scatter) this.chart_settings.scatter = scatterSetsDefaults
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Query(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'shared_id'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v3/queries'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v3/queries/${this.shared_id}`,
      params: {
        repository: this.repository,
        repository_version: this.repository_version
      }
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v3/queries/${this.shared_id}`,
      params: {
        repository: this.repository,
        repository_version: this.repository_version
      }
    })
  }
}

class Queries extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v3/queries'
    })
      .then(queries => {
        return queries.map(query => {
          try {
            return new Query(query)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i)
      })
  }

  new (queryString) {
    const item = {}
    const parameters = Object.assign({}, queryString)
    const queryKeys = Object.keys(parameters)
    if (!queryKeys.includes('repository') && !queryKeys.includes('repository_version')) {
      parameters.repository = Store.getters.QB_REPOSITORY_ACTIVE?._id
      parameters.repository_version = Store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id
    }
    if (queryKeys.includes('fromTable')) delete parameters.fromTable
    for (const key in parameters) {
      _set(item, key, parameters[key])
    }
    return new Query(item)
  }
}

export default Queries
export {
  Query,
  Queries
}
