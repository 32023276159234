<template lang="pug">
.title-bordered(:class="{'with-border': border}")
  .title-container(:class="{'no-hover':disableEdit }")
    span(
      @click="$emit('parent')"
      v-if="parent"
      :class="{ pointer: isPointerParent()}"
      v-tooltip="tooltipRender"
    ) {{ parent }}
    slot(name="inner")
    i.fp4.fp4-chevron-right-light(v-if="parent && typeof(value)==='string'")
    i.icon(:class="icon" v-if="icon")
    .details(v-if="typeof(value)==='string'")
      .input
        span.input-mirror {{ value || titlePlaceholder }}
        input(
          ref="input"
          type="text",
          :class="{'error-name':!!error, 'blur-error': !!blurError, 'is-placeholder': isPlaceholder}"
          :disabled="disableEdit"
          :value="value"
          :placeholder="titlePlaceholder"
          @input="updateValue"
          @blur="setBlur()"
          @focus="setFocus()"
        )
      .subtitle-container(v-if="!error && !blurError && subtitleDisplay")
        .subtitle(v-html="subtitleDisplay")
        i.fp4.fp4-copy-right-reverse(
          v-if="isCopySubtitle"
          v-clipboard:copy="subtitleDisplay"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
        )
      .error-text(v-if="error") {{ error }}
      .blur-error-text(v-if="blurError && !isFocus") {{ blurError }}
    .title-append(
      v-if="titleAppend"
      v-tooltip="titleAppendHelper"
      v-html="titleAppend"
    )
  slot
</template>

<script>
export default {
  props: {
    border: { type: Boolean, default: false },
    icon: { type: String, default: null },
    tooltip: { type: String, default: null },
    tooltipTitle: { type: String, default: null },
    loading: { type: Boolean, default: false },
    parent: { type: String, default: '' },
    subtitle: { type: String, default: null },
    isCopySubtitle: { type: Boolean, default: false },
    error: { type: String, default: null },
    blurError: { type: String, default: null },
    value: { type: String, default: null },
    type: { type: String, default: null },
    autoFocus: { type: Boolean, default: false },
    disableEdit: { type: Boolean, default: false },
    titlePlaceholder: { type: String, default: null },
    titleAppend: { type: String, default: null },
    titleAppendHelper: { type: [String, Object], default: null }
  },
  data () {
    return {
      editing: false,
      isFocus: false
    }
  },
  computed: {
    subtitleDisplay () {
      if (this.subtitle) return this.subtitle
      if (this.type) {
        return `
        <span class="label">${this.$t('fp-title.type')}:</span>
        <span class="type-name">${this.type}</span>
      `
      }
      return null
    },
    tooltipRender () {
      if (this.value) return null
      if (!this.tooltipTitle) return this.tooltip
      return `
        <div class="helper-title">${this.tooltipTitle}</div>
        <div class="separator"></div>
        <span>${this.tooltip}</span>
      `
    },
    isPlaceholder () {
      return this.value === ''
    }
  },
  mounted () {
    if (this.autoFocus && !this.value) this.focus()
  },
  methods: {
    focus () {
      this.$refs?.input?.focus()
    },
    setFocus () {
      this.isFocus = true
    },
    setBlur () {
      this.isFocus = false
    },
    updateValue (evt) {
      this.$emit('input', evt.target.value)
    },
    isPointerParent () {
      return !!this.value
    },
    copySuccess () {
      this.$fpuiMessageBlock.pop('success', this.$t('fp-title.copy_success'))
    },
    copyError () {
      this.$fpuiMessageBlock.pop('error', this.$t('fp-title.copy_error'))
    }
  }
}
</script>

<style lang="less">
  .title-bordered {
    background: white;
    position: relative;
    z-index:2;
    height: 87px;
    .fp-loading {
      margin-top:3px;
    }
    &.with-border {
      border-bottom: 1px solid #D6DEE5;
    }
    .fpui-input-search {
      margin-right: 5px;
    }

    > .title-container {
      width: 57%;
      align-items: flex-start;
      flex-wrap: no-wrap;
      flex-basis: 100%;
      display:flex;
      color: #3E4550;
      font-size:22px;
      padding: 0 37px;
      line-height: 87px;
      flex-shrink: 1;
      span {
        white-space: nowrap;
        &.pointer {
          cursor: pointer;
        }
      }
      .icon {
        width: 25px;
        margin-right: 10px;
        margin-left: 10px;
        color: @blue;
      }
      .details {
        position: relative;
        overflow: hidden;
        > .input {
          position: relative;
          min-width: 5em;
          > .input-mirror {
            visibility: hidden;
            white-space: pre;
            margin-right: 2px;
          }
          > input {
            position: absolute;
            top: 28.5px;
            left: 0;
            width: 100%;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: white;
            color: #3E4550;
            transition: border-color 0.3s;
            &.is-placeholder {
              font-style: italic;
            }
            &:hover {
              border-color: @grey;
            }
            &:focus {
              border-color: @blue_flash;
            }
            &:disabled {
              background: none;
            }
            &.error-name {
              color: @red;
              border-color: @red;
            }
            &.blur-error {
              &:not(:focus) {
                color: @red;
                border-color: @red;
              }
            }
          }
          > input, > .input-mirror {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 22px;
            padding: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .subtitle-container {
          display: flex;
          .subtitle {
            font-size: 11px;
            color: #97A7B7;
            line-height: 15px;
            margin-top: -27px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            .type-name {
              margin-left: 4px;
              font-weight: 600;
            }
          }
          i {
            margin-left: 2px;
            margin-top: -27px;
            font-size: 13px;
            cursor: pointer;

            &.fp4-copy-right-reverse {
              color: #d8d8d8;
            }

            &:hover {
              color: @blue;
            }
          }
        }
        .error-text {
          font-size: 11px;
          color: #FF6F90;
          line-height: 15px;
          margin-top: -27px;
          display: block;
        }
        .blur-error-text {
          font-size: 11px;
          color: #FF6F90;
          line-height: 15px;
          margin-top: -27px;
          display: block;
        }
      }
      i {
        margin-top: 33px;
      }
      &.no-hover .input > input {
        border-color: white!important;
      }
    }

    >.actions-buttons {
      position: absolute;
      right: 10px;
      white-space: nowrap;
      top: 26px;
      display: flex;
      .fpui-button {
        margin: 0 5px;
      }
    }
    .fp4-pencil {
      margin-left: 16px;
      color: #CBD3DB;
      font-size: 20px;
    }
  }
</style>
