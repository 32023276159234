<template lang='pug'>
  .fpui-input-time-period
    fpui-input-text(
      :disabled="disabled"
      type="number"
      :min="onlyPositive ? 0 : null"
      :value="model.number"
      @input="(value) => model = `${value}${model.type}`"
    )
    fpui-input-select(
      :disabled="disabled"
      :options="options"
      :clearable="false"
      placeholder="fpui_input_time_period.timeout.placeholder"
      :value="model.type"
      @input="(value) => model = `${model.number}${value}`"
    )
</template>

<script>

export default {
  props: {
    value: { type: String, default: '' },
    min: { type: Object, default: () => ({ m: 1 }) },
    max: { type: Object, default: () => ({ m: 1440 }) }, // 24 hours
    disabled: { type: Boolean, default: false },
    onlyPositive: { type: Boolean, default: false }
  },
  data () {
    return {
      options: [
        {
          value: 's',
          if: this.compareOrder('s'),
          label: this.$t('fpui_input_time_period.timeout.seconds')
        }, {
          value: 'm',
          if: this.compareOrder('m'),
          label: this.$t('fpui_input_time_period.timeout.minutes')
        }, {
          value: 'h',
          if: this.compareOrder('h'),
          label: this.$t('fpui_input_time_period.timeout.hours')
        }, {
          value: 'd',
          if: this.compareOrder('d'),
          label: this.$t('fpui_input_time_period.timeout.days')
        }, {
          value: 'w',
          if: this.compareOrder('w'),
          label: this.$t('fpui_input_time_period.timeout.weeks')
        }, {
          // Must to be transfered. Ex: 1M=4w, 1M=720h
          value: 'M',
          if: this.compareOrder('M'),
          label: this.$t('fpui_input_time_period.timeout.months')
        }, {
          value: 'y',
          if: this.compareOrder('y'),
          label: this.$t('fpui_input_time_period.timeout.years')
        }
      ]
    }
  },
  computed: {
    model: {
      get () {
        let value = this.value
        if (!this.compareOrder(this.separateValues(this.value)[2])) value = this.getFirstMaxValue()

        return {
          number: value.slice(0, -1),
          type: value.slice(-1)
        }
      },
      set (val) {
        let newValue = val.trim()

        const value = this.separateValues(newValue)
        const minFormatted = `${this.min[value?.[2]]}${value?.[2]}`
        const maxFormatted = `${this.max[value?.[2]]}${value?.[2]}`
        const min = this.separateValues(minFormatted)
        const max = this.separateValues(maxFormatted)

        if (this.compareOrder(value?.[2], min[2], min[2])) {
          if (parseInt(value[1]) < parseInt(min[1])) newValue = minFormatted
        }
        if (this.compareOrder(value?.[2], max[2], max[2])) {
          if (parseInt(value[1]) > parseInt(max[1])) newValue = maxFormatted
        }

        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    separateValues (input, release = null) {
      const regex = /^(\d+)?(([s?m?h?d?w?M?y]))$/gm
      const matches = regex.exec(input)
      if (release) return matches[release]
      return matches || []
    },
    compareOrder (input, compareMin, compareMax) {
      const types = ['s', 'm', 'h', 'd', 'w', 'M', 'y']
      const typeIdx = types.indexOf(input)
      if (!this.min[input] || !this.max[input]) return false

      const min = compareMin || `${this.min[input]}${input}`
      const max = compareMax || `${this.max[input]}${input}`
      const compareMinIdx = types.indexOf(this.separateValues(min)[2])
      const compareMaxIdx = types.indexOf(this.separateValues(max)[2])

      return compareMinIdx <= typeIdx && typeIdx <= compareMaxIdx
    },
    getFirstMaxValue () {
      const firstKey = Object.keys(this.max)[0]
      return `${this.max[firstKey]}${firstKey}`
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-time-period {
  display: flex;
  .fpui-input-text {
    width: 65px;
    margin-right: 10px;
  }
  .fpui-input-select {
    margin-left: 6px;
    width: 150px
  }
}
</style>
