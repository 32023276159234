import { APIObject } from '@/shared/plugins/Api/API'

class Certificate extends APIObject {
  constructor (options) {
    super('FPAPI', options, true, true)
  }

  async create (organizationId) {
    return super.create({
      method: 'POST',
      url: `certificates/${organizationId}`
    })
  }

  async remove (organizationId) {
    return this.request({
      method: 'DELETE',
      url: `certificates/${organizationId}/${this._id}`
    })
  }
}

export default Certificate
