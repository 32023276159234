import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'

class Application extends APIObject {
  constructor (options) {
    // Init
    super('APPSERVICE', options, false, 'Cmp-Authorization')
    options = options || {}

    this.type = this.type || 'app'
    this.options = this.options || {}
    this.options.deploy = this.options.deploy || false
    this.options.build = this.options.build || false
    this.options.upload = this.options.upload || true
    this.deploy_options = this.deploy_options || {}
    this.deploy_options.api = this.deploy_options.api || ''
    this.deploy_options.baseUrl = this.deploy_options.baseUrl || ''
    this.icon = this.icon || ''
    this.url = this.url || null
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.app_name = this.app_name || ''
    this.version = this.version || 'v1'
    this.active = this.active || false
    this.description = this.description || ''
    this.repository = this.repository || {}
    this.repository.type = this.repository.type || ''
    this.repository.authentication = this.repository.authentication || false
    this.repository.auth = this.repository.auth || ''
    this.params = this.params || {}
    this.tags = this.tags || []
  }

  get isDeployed () {
    return !!this.tags.find(tag => {
      return tag.state === 'STARTED'
    })
  }

  async create () {
    return super.create({
      method: 'POST',
      timeout: 120000,
      url: 'applications',
      data: this.file ? this.toFormData() : this,
      headers: this.file
        ? {
            'Content-Type': 'multipart/form-data'
          }
        : {}
    })
  }
}

class Applications extends API {
  async list () {
    const { data } = await this.request({
      method: 'get',
      url: '/application',
      params: {
        tags: true
      }
    })
    return data.map(app => new Application(app))
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Application(item)
  }
}

export default Applications
export {
  Application,
  Applications
}
