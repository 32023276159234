import Vue from 'vue'
import FpuiCanvasIcon from './canvas'
import FpuiButton from './FpuiButton'
import FpuiHelper from './FpuiHelper'
import FpuiInputCheckbox from './FpuiInputCheckbox'
import FpuiInputCountry from './FpuiInputCountry'
import FpuiInputDropzone from './FpuiInputDropzone'
import FpuiInputEmail from './FpuiInputEmail'
import FpuiInputEnvironmentVariables from './FpuiInputEnvironmentVariables'
import FpuiInputFpu from './FpuiInputFpu'
import FpuiInputImage from './FpuiInputImage'
import FpuiInputLabel from './FpuiInputLabel'
import FpuiInputNumber from './FpuiInputNumber'
import FpuiInputPassword from './FpuiInputPassword'
import FpuiInputPasswordStrength from './FpuiInputPasswordStrength'
import FpuiInputPhone from './FpuiInputPhone/FpuiInputPhone'
import FpuiInputQty from './FpuiInputQty'
import FpuiInputRadio from './FpuiInputRadio'
import FpuiInputRequirements from './FpuiInputRequirements'
import FpuiInputSearch from './FpuiInputSearch'
import FpuiInputSelect from './FpuiInputSelect'
import FpuiInputSelectCategories from './FpuiInputSelectCategories'
import FpuiInputState from './FpuiInputState'
import FpuiInputTags from './FpuiInputTags'
import FpuiInputText from './FpuiInputText'
import FpuiInputTextarea from './FpuiInputTextarea'
import FpuiInputTimePeriod from './FpuiInputTimePeriod'
import FpuiInputToggle from './FpuiInputToggle'
import FpuiProgressBar from './FpuiProgressBar'
import FpuiTitleEditor from './FpuiTitleEditor'
import FpuiInputJson from './FpuiInputJson'

Vue.component('FpuiCanvasIcon', FpuiCanvasIcon)
Vue.component('FpuiButton', FpuiButton)
Vue.component('FpuiInputRequirements', FpuiInputRequirements)
Vue.component('FpuiInputText', FpuiInputText)
Vue.component('FpuiInputPassword', FpuiInputPassword)
Vue.component('FpuiInputPasswordStrength', FpuiInputPasswordStrength)
Vue.component('FpuiInputEmail', FpuiInputEmail)
Vue.component('FpuiInputToggle', FpuiInputToggle)
Vue.component('FpuiInputSearch', FpuiInputSearch)
Vue.component('FpuiInputCountry', FpuiInputCountry)
Vue.component('FpuiInputState', FpuiInputState)
Vue.component('FpuiInputRadio', FpuiInputRadio)
Vue.component('FpuiInputSelect', FpuiInputSelect)
Vue.component('FpuiInputSelectCategories', FpuiInputSelectCategories)
Vue.component('FpuiInputLabel', FpuiInputLabel)
Vue.component('FpuiInputEnvironmentVariables', FpuiInputEnvironmentVariables)
Vue.component('FpuiHelper', FpuiHelper)
Vue.component('FpuiInputCheckbox', FpuiInputCheckbox)
Vue.component('FpuiInputFpu', FpuiInputFpu)
Vue.component('FpuiInputImage', FpuiInputImage)
Vue.component('FpuiInputNumber', FpuiInputNumber)
Vue.component('FpuiInputQty', FpuiInputQty)
Vue.component('FpuiInputTags', FpuiInputTags)
Vue.component('FpuiProgressBar', FpuiProgressBar)
Vue.component('FpuiInputTextarea', FpuiInputTextarea)
Vue.component('FpuiInputTimePeriod', FpuiInputTimePeriod)
Vue.component('FpuiTitleEditor', FpuiTitleEditor)
Vue.component('FpuiInputDropzone', FpuiInputDropzone)
Vue.component('FpuiInputPhone', FpuiInputPhone)
Vue.component('FpuiInputJson', FpuiInputJson)
