<template lang="pug">
  form(@submit="onSubmit").login
    .forgot-send(v-if="forgotSend") {{ 'authentication.password_reset_sent' | translate }}
    div(v-if="!forgotSend && !mfa")
      label(:style="labelStyle") {{ 'authentication.login' | translate }}
      input(
        autocomplete=""
        :placeholder='"authentication.login.placeholder" | translate'
        type='text'
        name='login'
        v-model="login"
      )
    div(v-if="!passwordReset && !forgotSend && !mfa")
      label(:style="labelStyle") {{ 'authentication.password' | translate }}
      input(
        autocomplete="current-password"
        :placeholder='"authentication.password.placeholder" | translate'
        type='password'
        name='password'
        v-model="password"
      )
    div(v-if="passwordReset && !forgotSend && !mfa")
      label(:style="labelStyle") {{ 'authentication.email' | translate }}
      input(
        :placeholder='"authentication.email.placeholder" | translate'
        type='email'
        name='email'
        v-model="email"
      )
    div(v-if="mfa")
      mfa(
        @mfa-sent="noMfaSecret=false"
        v-model="mfaData"
        :configuration="mode.mfa"
        :phone="phone"
        :login="login"
        :password="password"
        :color="color"
        :no-mfa-secret="noMfaSecret"
      )
    .actions
      button.login-button.btn-login.btn.btn-primary(
        type='submit'
        :style="buttonStyle"
        :disabled="disabledLogin"
        v-if="!forgotSend"
      )
        span(v-if="!passwordReset") {{'authentication.signin'|translate}}
        span(v-if="passwordReset") {{'authentication.send'|translate}}
      .links
        a.forgot-password-link(
          @click="passwordReset=true"
          v-if="!passwordReset && !forgotSend && !mfa"
        ) {{'authentication.forgot_password'|translate}}
        a.return-link(
          @click="$emit('close')"
          v-if="!passwordReset && !forgotSend && auth_modes.length > 1 && !mfa"
        ) {{'authentication.return'|translate}}
        a.cancel-link(
          @click="passwordReset = false; forgotSend = false"
          v-if="passwordReset && !mfa"
        ) {{'authentication.cancel'|translate}}
        a.cancel-link(
          @click="mfa = false; mfaData = {}; noMfaSecret=false"
          v-if="mfa"
        ) {{'authentication.cancel'|translate}}

</template>

<script>
import Mfa from './MFA'
import _get from 'lodash/get'

export default {
  components: {
    Mfa
  },
  props: {
    mode: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mfa: false,
      noMfaSecret: false,
      phone: false,
      passwordReset: false,
      forgotSend: false,
      mfaData: {},
      login: '',
      password: '',
      email: ''
    }
  },
  computed: {
    disabledLogin () {
      return this.mfa && !_get(this, 'mfaData.otp')
    },
    color () { return this.$store.getters.PREFERENCES.color || '#00ccf9' },
    buttonStyle () {
      return {
        color: 'white', // TODO - Add a detect for light or dark background
        backgroundColor: this.color,
        opacity: this.disabledLogin ? 0.5 : 1,
        cursor: this.disabledLogin ? 'not-allowed' : 'pointer'
      }
    },
    labelStyle () {
      return {
        color: this.color
      }
    },
    auth_modes () {
      return this.$store.getters.PREFERENCES.auth_mode.filter(authMode => {
        return authMode.visible && !authMode.hidden
      })
    }
  },
  methods: {
    onSubmit ($event) {
      $event.preventDefault()
      if (this.passwordReset) {
        return this.onPasswordReset()
      }
      this.onLogin()
    },
    async onLogin () {
      const { login, password, mfaData } = this
      try {
        await this.$store.dispatch('LOGIN', { login, password, otp: mfaData.otp, mfa: mfaData.type })
      } catch (err) {
        switch (err.code) {
          case 'MFA::Mandatory':
            if (this.otp) this.$fpuiMessageBlock.error(err)
            this.mfa = true
            this.noMfaSecret = !err.description?.secret
            this.phone = err.description.phone
            break
          default:
            this.$fpuiMessageBlock.error(err)
            break
        }
      }
    },
    async onPasswordReset () {
      const { login, email } = this
      try {
        await this.$store.dispatch('PASSWORD_RESET', { login, email })
        this.forgotSend = true
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    }
  }
}
</script>

<style lang="less">
  .login {
    label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      color: #617de9;
      display: block;
    }
    input {
      max-width: 393px;
      width:100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(151,167,183,0.3);
      background:white;
      font-size: 16px;
      color: #444;
      padding-left: 0;
      margin-top: 10px;
      margin-bottom: 30px;
      &::placeholder {
        color: rgba(151,167,183,0.5);
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        background-color: transparent;
      }
    }
    .forgot-send {
      min-height:100px;
    }
    .actions {
      position:relative;
      .login-button {
        height: 46px;
        width: 205px;
        line-height: 42px;
        border-radius: 5px;
        text-align: center;
        font-size: 13px;
        text-transform: uppercase;
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.07), 0 5px 8px 0 rgba(0,0,0,.14);
        border: none;
        cursor: pointer;
      }
      .links {
        display:inline-block;
        text-align: right;
        position: absolute;
        right:0;
        bottom:0;
        a {
          cursor:pointer;
          display:block;
          color:#97a7b7;
          display:block;
        }
      }
      @media (max-width: 800px) {
        .links {
          position:static;
          display:block;
          text-align: left;
          margin-top:10px;
        }
      }
    }
  }
</style>
