<template lang='pug'>
  .fp-aside(ref="aside")
    .sidebar-content.aside-full
      .aside-full-content
        ul.nav.nav-sidebar(:class='color', ref='itemList')
          fp-aside-item(
            v-if="show(val)",
            v-for='val, key in aside'
            :key='key',
            :id='key',
            :dataplant-id="dataplantId"
            :menutype="menutype"
            :val="val"
          )
          span.aside-animated-border(v-show='showAnimatedBorder', ref='animatedBorder', :class='color')
</template>

<script>
import FpAsideItem from './FpAsideItem'
export default {
  components: {
    FpAsideItem
  },
  props: {
    dataplantId: { type: String, default: '' },
    color: { type: String, default: 'blue' },
    menutype: { type: String, default: '' },
    aside: { type: Object, required: true }
  },
  data () {
    return {
      mouseOver: false
    }
  },
  computed: {
    showAnimatedBorder () {
      const countActive = Object.keys(this.aside).filter(key => {
        return this.isActive(this.aside[key])
      }).length
      return countActive > 0
    },
    comingSoonImage () {
      return require('./aside-coming-soon.png')
    }
  },
  watch: {
    $route () { process.nextTick(() => this.setBorder()) }
  },
  mounted () {
    // With condition in showing and enable it takes
    // more time to process, so keep the setTimeout
    const interval = setInterval(this.setBorder, 100)
    setTimeout(() => clearInterval(interval), 2000)
  },
  methods: {
    show (val) {
      if (typeof val.showing === 'function') return val.showing.call(this)
      if (typeof val.showing !== 'undefined') {
        return val.showing
      }
      return true
    },
    isLoading (val) {
      if (typeof val.loading === 'function') return val.loading.call(this)
      if (val.loading) { return true }
      return false
    },
    addTooltip (val) {
      if (typeof val.addTooltip === 'function') return this.$t(val.addTooltip.call(this))
      return this.$t(val.addTooltip)
    },
    addAside (val) {
      return typeof val.add === 'function' && val.add.call(this)
    },
    getDisableClass (val) {
      if (val.disable || val.soon) return 'disable events-pointer'
      return ''
    },
    getItemClass (val) {
      let ret = ''
      if (this.isActive(val)) { ret += ' active' }
      if (val.hidden) { ret += ' hidden' }
      return ret
    },
    getMenuName (key) {
      const lckey = key.toLowerCase()
      if (this.menutype) {
        return this.$t(`aside.${this.menutype}.${lckey}`, { default: key })
      } else {
        return this.$t(`aside.${lckey}`, { default: key })
      }
    },
    isActive (val) {
      if (!val.url) { return false }
      const path = this.$route.fullPath.split('?')[0]
      if (val.url && (`/${this.dataplantId}${val.url}` === path || `/${this.dataplantId}${val.url}` === path + '/')) return true
      if (val.regexp && path.match(val.regexp)) return true
      return false
    },
    setBorder () {
      if (this.$refs.itemList && this.$refs.animatedBorder) {
        const activeItem = this.$refs.itemList.querySelector('.aside-item.active')
        if (activeItem) {
          const activeItemInfo = activeItem.getBoundingClientRect()
          const asideInfo = this.$refs.aside.getBoundingClientRect()
          const top = activeItemInfo.top - asideInfo.top
          const height = activeItemInfo.height
          this.$refs.animatedBorder.style.top = `${top}px`
          this.$refs.animatedBorder.style.height = `${height}px`
        }
      }
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.sidebar-content {
  width: 124px;
  transition:0.3s all;
  background-color: @background-color_aside;
  position: absolute;
  bottom: 0;
  top:0;
  padding: 0 0 2px 0;
  left: 0;
  right: 0;
  overflow: auto;
  overflow: overlay;
  ul {
    margin: 0;
    box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
    .aside-item {
      width: 100%;
      height: 86px;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      position: relative;
      display: block;
      + .aside-item {
        border-top: 1px solid #E9EDF2;
      }
      &.active {
        background: @white;
        border-top: 0px;
        + .aside-item {
          border-top: 0px
        }
        box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
        a {
          i,span {
            color: @blue;
          }
        }
      }
      .coming-soon-overlay {
        position: absolute;
        span {
          position: absolute;
          left: 0;
          bottom: 12px;
          color: white;
          font-size: 10px;
          font-weight: bold;
          transform-origin: 0 0;
          transform: rotate(-45deg);
          letter-spacing: 1;
          &.fr {
            left: -2px;
            bottom: 10px;
          }
        }
        > img {
          height: 45px;
        }
      }
      a {
        font-size: 12px;
        line-height: 17px;
        padding-bottom: 12px;
        padding-top: 8px;
        display: block;
        height: 86px;
        &:hover {
          i, span {
            color: @blue;
          }
        }
        position: relative;
        i {
          display: block;
          font-size: 190%;
          color: @grey;
          cursor: pointer;
          transition: 0.3 all;
          margin-top: 0;
          margin-bottom: 0;
          &.fp4 {
            font-size: 50px;
          }
        }
        span {
          opacity: 1;
          text-align: center;
          font-size: 11px;
          color: @grey;
          font-weight: 600;
          cursor: pointer;
          height: auto;
          max-height: 200px;
          transition: 0.3 all;
          display: block;
          hyphens: auto;
        }
        img {
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
    }
  }
  .aside-animated-border {
    position: absolute;
    width: 4px;
    min-height: 45px;
    height: 86px;
    background-color: @blue;
    right: 0;
    transition: bottom 300ms ease-out, top 250ms ease-in 50ms;
  }
}

@media @mobile {
  .sidebar {
    display: none;
  }
}

.nav-sidebar {
  padding: 0;
  a.disable {
    color:#bbb!important;
    cursor: not-allowed!important;
    opacity: 0.5;
    i,span {
      cursor: not-allowed!important;
      color:#bbb!important
    }
  }
}

main {
  &.asided {
    left: 124px;
  }
}
.fp-aside {
  .aside-full {
    .events-pointer {
      pointer-events: none;
    }
    a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
  }
  .hidden {
    display: none;
  }
}
</style>
