<template lang="pug">
.store-container
  store-main(
    v-if="!details"
    :multiple="multiple"
    :items="items"
    :type="type"
    :custom-icon="customIcon"
    :name="name"
    :create-button-label="createButtonLabel"
    :custom-button-label="customButtonLabel"
    :on-select="onSelect"
    :on-custom-select="onCustomSelect"
    @close="$emit('close')"
    @details="(value)=>details=value"
    :filterPackages="filterPackages"
  )
  store-details(
    :value="details",
    :on-select="onSelect"
    @back="details=null"
    @close="$emit('close')"
    v-if="details"
  )
</template>

<script>
import './Store.less'
import StoreMain from './StoreMain'
import StoreDetails from './StoreDetails'
export default {
  components: { StoreMain, StoreDetails },
  props: {
    items: { type: Object, default: null },
    onSelect: { type: Function, default: null },
    name: { type: String, default: '' },
    type: { type: String, default: '' },
    customIcon: { type: String, default: null },
    createButtonLabel: { type: String, default: 'store.select' },
    onCustomSelect: { type: Function, default: null },
    customButtonLabel: { type: String, default: 'store.custom' },
    multiple: {
      type: Boolean,
      default: false
    },
    filterPackages: { type: Function, default: (pkg) => { return true } }
  },
  data () {
    return {
      details: null
    }
  }
}
</script>
