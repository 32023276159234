

<template lang='pug'>
  .fpui-input-password-strength
    fpui-input-password(
      :name='name',
      :placeholder="placeholder",
      type="password",
      :color='color',
      v-model='model',
      :error='error',
      :disabled="disabled",
      :error-message='errorMessage'
      :autofocus='autofocus',
      :with-padding='withPadding',
      :label-size='labelSize',
      :label='label',
      :required='required',
      :helper='helper',
      :helper-title='helperTitle',
      @click="$emit('click', $event)"
    )
    .score
      .score-internal(:class="scoreClass")
      .text {{'authentication.password.score.'+score|translate}}
</template>

<script>
import zxcvbn from 'zxcvbn'
export default {
  props: {
    name: { type: String, default: '' },
    value: { type: String, required: true, default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    errorMessage: { type: String, default: null },
    autofocus: { type: Boolean, default: false },
    label: { type: String, default: '' },
    labelSize: { type: Number, default: 3 },
    withPadding: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' }
  },
  data () {
    return {
      score: null
    }
  },
  computed: {
    scoreClass () {
      return 'score-internal-' + this.score
    },
    model: {
      get () { return this.value },
      set (val) {
        this.$emit('input', val)
        process.nextTick(() => this.onPasswordChange())
      }
    }
  },
  methods: {
    onPasswordChange () {
      this.score = zxcvbn(this.value).score
    }
  }
}
</script>


<style lang="less">
  .fpui-input-password-strength {
    position: relative;
    .fpui-input-text-error {
      padding-right: 100px;
    }
    .score {
      position: absolute;
      right:7px;
      top:13px;
      background-color: rgba(151,167,183,.3);
      height:3px;
      width: 80px;
      border-radius: 2px;
      .score-internal {
        height:3px;
      }
      .score-internal-0 {
        background:#facd30;
        width:20%;
      }
      .score-internal-1 {
        background:#facd30;
        width:40%;
      }
      .score-internal-2 {
        background:#facd30;
        width:60%;
      }
      .score-internal-3 {
        background:#9edf10;
        width:80%;
      }
      .score-internal-4 {
        background:#9edf10;
        width:100%;
      }
      .text {
        color:#97a7b7;
        font-size:11px;
        position: absolute;
        bottom:2px;
        right:0;
      }
    }
  }
</style>
