<template lang="pug">
.dashboarding-empty(
  :style="{ padding: fullScreen ? '0' : '0 20px' }"
)
  dashboarding-header(
    v-if="displayHeader"
    :item="item"
    :editing="editing"
    :full-screen="fullScreen"
    :from-empty="true"
  )
  .fp-column
    .fp-box
      img(
        :src="require('@/shared/assets/img/placeholder_preview_chart@2x.png')"
      )
      .text(
        v-if="editing"
        v-html="$t('dashboard.empty.text')"
      )
      .text(
        v-else-if="fromPortal"
        v-html="$t('dashboard.empty.text_deployed_empty')"
      )
      .text(
        v-else
        v-html="$t('dashboard.empty.text_deployed')"
      )
      fpui-button(
        v-if="editing"
        color="blue-flash"
        icon="fp4 fp4-plus"
        iconFontSize="26px"
        iconCenter
        noshadow
        @click="addChart"
      )
</template>

<script>
import DashboardingHeader from './DashboardingHeader.vue'

export default {
  components: {
    DashboardingHeader
  },
  props: {
    disabled: { type: Boolean, default: false },
    editing: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
    fullScreen: { type: Boolean, default: false },
    displayHeader: { type: Boolean, default: true },
    fromPortal: { type: Boolean, default: false }
  },
  computed: {
    story () {
      return this.item?.story || {}
    }
  },
  methods: {
    addChart () {
      if (this.disabled || !this.editing) return
      this.$emit('add-chart')
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.dashboarding-empty {
  height: 100%;
  width: 100%;
  display: block !important;
  .fp-column {
    height: 100%;
    width: 100%;
    .fp-box {
      height: 100%;
      padding-top: 37px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 362px;
        height: 162px;
        margin-bottom: 25px;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #CBD3DB;
        margin-bottom: 28px;
        max-width: 437px;
      }
      .fpui-button {
        width: 381px;
        height: 44px;
      }
    }
  }
}
</style>
