<template lang="pug">
  .mfas
    .description
      div {{ $t('authentication.mfa.description') }}
      div {{ $t('authentication.mfa.description2') }}
    .mfa-select
      .mfa(
        :class="{active:current === mfa}"
        v-for="mfa in configuration.mode"
        :key="mfa"
        v-tooltip="tooltip(mfa)"
        :disabled="isSmsNoPhone(mfa)"
        :style="{'cursor':isSmsNoPhone(mfa)?'not-allowed':'pointer','opacity':isSmsNoPhone(mfa)?0.5:1}"
        @click="select(mfa)"
      )
        i(:class="icons(mfa)")
        span {{ $t('authentication.mfa.'+mfa) }}
    component(
      :is="current"
      :no-mfa-secret="noMfaSecret"
      :login="login"
      :password="password"
      :color="color"
      v-if="current"
      :value="value.otp"
      @mfa-sent="$emit('mfa-sent')"
      @input="updateOTP"
    )

</template>

<script>
import pascalcase from 'pascalcase'
import mfas from './mfas'
export default {
  components: mfas,
  props: {
    login: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    },
    noMfaSecret: {
      type: Boolean,
      required: true
    },
    phone: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      current: null
    }
  },
  methods: {
    isSmsNoPhone (mfa) {
      if (mfa === 'sms' && !this.phone) return true
      return false
    },
    select (mfa) {
      if (this.isSmsNoPhone(mfa)) return
      this.current = mfa
    },
    tooltip (mfa) {
      if (this.isSmsNoPhone(mfa)) {
        const ret = {
          content: this.$t('authentication.mfa.no-phone'),
          delay: {
            show: 0
          }
        }
        return ret
      }
      return ''
    },
    icons (mfa) {
      return mfas[pascalcase(mfa)].icon
    },
    updateOTP (otp) {
      this.$emit('input', {
        otp,
        type: this.current
      })
    }
  }
}
</script>

<style lang="less">
.mfas{
  .description {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.5;
  }
  .mfa-button {
    height: 34px;
    width: 170px;
    line-height: 32px;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.07), 0 5px 8px 0 rgba(0,0,0,.14);
    border: none;
    cursor: pointer;
  }
  margin-bottom: 40px;
  .mfa-select {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mfa {
      cursor: pointer;
      border-radius: 5px;
      width: 146px;
      height: 83px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size:14px;
      border: 0.5px solid rgba(151, 167, 183, 0.21);
      box-shadow: 0 2px 4px 0 rgba(52, 65, 78, 0.05);
      i {
        font-size: 40px;
      }
      &.active {
        border: 2px solid #0089c0;
        color: #0089c0;
      }
    }
  }
}
</style>
