import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import Vue from 'vue'

class Dataplant extends APIObject {
  constructor (options) {
    // Init
    super('FPAPI', options, true, true)
    this.definition = null
    this.budget = this.budget || {}
  }

  get isAborted () {
    return this.status === 'aborted'
  }

  get storage () {
    return parseInt(this.datatank)
  }

  get fpu () {
    let total = 0
    for (const service in this.budget) {
      total += this.budget[service].fpu * this.budget[service].instances
    }
    return total
  }

  get serviceNames () {
    const names = []
    for (const name in this.budget) {
      if (name.indexOf('-ml-api') !== -1) {
        names.push('ml-api')
      } else if (name.indexOf('-api') !== -1) {
        names.push('api')
      } else if (name.indexOf('-app') !== -1) {
        names.push('app')
      } else names.push(name)
    }
    return _uniq(names)
  }

  get freeFpu () {
    if (this.offer === 'mutualized') return 2
    if (this.offer === 'enterprise') return 6
    return 0
  }

  get cpu () {
    return this.fpu * 0.5
  }

  get memory () {
    return this.fpu * 2
  }

  get apps () {
    let total = 0
    for (const service in this.budget) {
      if (service.indexOf('-app') !== -1) { total++ }
    }
    return total
  }

  get apis () {
    let total = 0
    for (const service in this.budget) {
      if (service.indexOf('-api') !== -1 && service.indexOf('-ml-api') === -1) { total++ }
    }
    return total
  }

  get models () {
    let total = 0
    for (const service in this.budget) {
      if (service.indexOf('-ml-api') !== -1) { total++ }
    }
    return total
  }

  // Returns if the Dataplant is ready, 10 minutes
  get isReady () {
    if (this.error) return false
    if (new Date() - new Date(this.created_at) > 1800000) return true
    return this.post_installed
  }

  // Returns if the Dataplant is limited in term of use
  get isLimited () {
    if (this.error === 'IAASRequestError::not found') return false
    return this.error
  }

  // Check if the dataplant has been created less than 15 minutes ago
  get hasBeenCreatedInLast15MIn () {
    return moment().unix() - moment(this.created_at).unix() < 900
  }

  async refresh () {
    const data = await this.request({
      method: 'GET',
      url: `dataplants/${this.dataplant_id}`
    })
    data.budget = data.budget || {}
    this.assign(this._filter(data))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'account_id',
      'acl',
      'created_at',
      'creator_id',
      'dataplant_id',
      'error',
      'error_'
    ])
    return obj
  }

  getUsage (srv = null) {
    if (srv === 'summary') srv = null
    if (!srv) return this.fpu
    if (!this.budget) return 0
    if (srv === 'app') {
      let total = 0
      for (const service in this.budget) {
        const srv = this.budget[service]
        if (service.indexOf('-app') !== -1) { total += srv.instances * srv.fpu }
      }
      return total
    }
    if (srv === 'ml-api') {
      let total = 0
      for (const service in this.budget) {
        const srv = this.budget[service]
        if (service.indexOf('-ml-api') !== -1) { total += srv.instances * srv.fpu }
      }
      return total
    }

    if (srv === 'api') {
      let total = 0
      for (const service in this.budget) {
        const srv = this.budget[service]
        if (service.indexOf('-api') !== -1 && service.indexOf('-ml-api') === -1 && service.indexOf('-model-api') === -1) {
          total += srv.instances * srv.fpu
        }
      }
      return total
    }
    if (!this.budget[srv]) return 0
    return this.budget[srv].instances * this.budget[srv].fpu
  }

  getUsageDisplay (srv = null) {
    if (srv === 'summary') srv = null
    if (!srv) return `${this.fpu} (${this.freeFpu} ${Vue.prototype.$t('dataplant.fpu.free')})`

    const free = {
      datastore: 1,
      dpe: 1,
      querybuilder: 1,
      authentication: 1,
      infrastructure: 1,
      ml: 1
    }
    const usage = this.getUsage(srv)
    if (!usage) return '-'
    return `${usage}${free[srv] ? `(${free[srv]} ${Vue.prototype.$t('dataplant.fpu.free')})` : ''}`
  }


  getServiceDefinition (name) {
    if (!this.definition) return null
    if (name.indexOf('model-api') !== -1) return this.definition['model-api']
    if (name.indexOf('ml-api') !== -1) return this.definition['ml-api']
    if (name.indexOf('ml-score') !== -1) return this.definition['ml-score']
    if (name.indexOf('ml-train') !== -1) return this.definition['ml-train']
    if (name.indexOf('api') !== -1) return this.definition.api
    if (name.indexOf('app') !== -1) return this.definition.app
    return this.definition[name]
  }

  async restartPod (podName) {
    return await this.request({
      method: 'DELETE',
      url: `/dataplants/${this.dataplant_id}/pods/${podName}`
    })
  }

  async logs (podName, tail = 100) {
    return await this.request({
      method: 'GET',
      url: `/dataplants/${this.dataplant_id}/pods/${podName}/logs`,
      params: {
        sinceSeconds: 3600 * 24 * 7,
        tailLines: tail
      }
    })
  }

  async updateBudget (serviceName, { size, instances }) {
    const res = await this.request({
      method: 'PUT',
      url: `/dataplants/${this.dataplant_id}/budget/${serviceName}`,
      data: {
        fpu: size,
        instances
      }
    })
    this.budget[serviceName] = {
      fpu: size,
      instances
    }
    return res
  }

  async delete (token, force = false) {
    // TODO: Handle errors
    const r = await this.request({
      method: 'DELETE',
      url: `/dataplants/${this.dataplant_id}`,
      params: {
        tokenRemove: token,
        force: force
      }
    })
    return r
  }

  async loadDefinition () {
    if (this.definition) return
    this.definition = await this.request({
      method: 'GET',
      url: '/definition',
      params: {
        offer: this.offer
      }
    })
  }
}

class Dataplants extends API {
  constructor () {
    super('FPAPI', true, true)
  }

  async get (dataplantId) {
    return this.request({
      method: 'GET',
      url: `/dataplants/${dataplantId}`
    })
  }

  async list (organizationId) {
    const { data } = await this.request({
      method: 'GET',
      url: '/dataplants',
      params: {
        organization: organizationId || undefined
      }
    })
    return data.map(dataplant => {
      return new Dataplant(_omit(dataplant, ['error']))
    })
  }

  async findOne (dataplantId) {
    const dataplant = await this.request({
      method: 'GET',
      url: `/dataplants/${dataplantId}`
    })
    return new Dataplant(_omit(dataplant, ['error']))
  }

  async create (p) {
    const dataplant = await this.request({
      method: 'POST',
      url: '/dataplants',
      data: {
        organization_id: p.organization_id,
        name: p.name,
        description: p.description,
        template: null,
        domain: p.domain,
        subdomain: p.subdomain,
        region: p.region,
        provider: p.provider,
        storageEngine: p.storageEngine,
        plan: p.plan,
        datatank: p.datatank
      }
    })
    return dataplant
  }

  async checkDomain (subdomain, domain, organizationId) {
    return await this.request({
      method: 'GET',
      url: `/check_domain/${subdomain}/${domain}`,
      params: {
        organization_id: organizationId
      }
    })
  }

  // TRINO
  async activateTrino (dataplantId, budget = { fpu: 4, instances: 1 }) {
    // analytics tracking
    Vue.$analytics.track('Query engine activated', {
      nb_of_instances: budget.instances,
      resources_allocated: budget.fpu * budget.instances,
      resources_type: 'FPU-S',
      engine: 'Trino'
    })

    return this.request({
      method: 'POST',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {
        budget
      }
    })
  }

  async updateTrino (dataplantId, budget) {
    return this.request({
      method: 'PUT',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {
        budget
      }
    })
  }

  async desactivateTrino (dataplantId) {
    // analytics tracking
    Vue.$analytics.track('Query Engine deactivated')

    return this.request({
      method: 'DELETE',
      url: `/dataplants/${dataplantId}/services/trino`,
      data: {}
    })
  }
}

export default Dataplants
export {
  Dataplant,
  Dataplants
}
