<template lang="pug">
.dashboarding-header(
  :class="{ empty: fromEmpty, editing }"
)
  .left
    dashboarding-name(
      :item="item"
      :story="story"
      :editing="editing"
    )
  .right
    fpui-button(
      v-if="displayFiltersButton"
      color="white"
      icon="fp4 fp4-filter-empty"
      auto-width
      icon-left
      v-tooltip="{ content: showFilters ? $t('dashboard.hide_filters') : $t('dashboard.show_filters') }"
      @click="toggleFilters"
    ) {{ showFilters ? $t('dashboard.hide_filters') : $t('dashboard.show_filters') }}
    fpui-button(
      v-if="editing"
      color="white"
      icon="fp4 fp4-plus"
      auto-width
      icon-left
      v-tooltip="{ content: $t('dashboard.add_a_query') }"
      :disabled="!canAmDashboardWrite"
      @click="addQuery"
    ) {{ $t('dashboard.add_a_query') }}
    fpui-button.actions(
      v-if="editing || (!fromDashboard && !fromEmpty)"
      color="white"
      auto-width
      icon-center
      v-tooltip="{ content: $t('dashboard.link'), delay: 0 }"
      :disabled='disabledActions("link")'
      v-clipboard:copy="linkDashboardDeployed"
      v-clipboard:success="clipboardSuccessHandler"
    )
      i.fp4.fp4-link
    fpui-button.actions(
      v-if="editing"
      color="white"
      auto-width
      icon-center
      v-tooltip="{ content: $t('dashboard.preview_tooltip'), delay: 0 }"
      noshadow
      @click="preview"
    )
      i.fp4.fp4-eye
    fpui-button.refresh.actions(
      v-if="!fromEmpty"
      color="white"
      auto-width
      icon-center
      v-tooltip="{ content: $t('dashboard.refresh'), delay: 0 }"
      noshadow
      @click="refresh"
    )
      i.fp4.fp4-arrows-rotate
    //- fpui-button.display-actions.actions(
    //-   v-if="editing"
    //-   color="white"
    //-   auto-width
    //-   icon-center
    //-   noshadow
    //-   @click="toggleDisplayActions"
    //- )
    //-   i.fp4.fp4-ellipsis
    //-   .actions-list
    //-     ul
    //-       li(
    //-         v-for="opt in options"
    //-         :class="{ disabled: opt.disabled }"
    //-         :key="opt.label"
    //-         v-tooltip="opt.tooltip"
    //-         @click="() => opt.action()"
    //-       )
    //-         i.fp4(
    //-           :class="opt.icon"
    //-         )
    //-         span.action-label {{ opt.label }}

</template>

<script>
import Config from '@/shared/Config'
import DashboardingName from './DashboardingName'

export default {
  components: {
    DashboardingName
  },
  props: {
    item: { type: Object, required: true },
    editing: { type: Boolean, default: false },
    fullScreen: { type: Boolean, default: false },
    fromDashboard: { type: Boolean, default: false },
    fromEmpty: { type: Boolean, default: false },
    showFilters: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null,
      displayActions: false
    }
  },
  computed: {
    displayFiltersButton () {
      return (this.editing || Object.keys(this.item.filter).length) && !this.fromEmpty
    },
    story () {
      return this.item?.story || {}
    },
    canAmDashboardWrite () {
      if (!this.editing) return
      return this.$store.getters.ACL('canAmDashboardWrite')
    },
    linkDashboardDeployed () {
      return `${this.config?.FPUI}/dashboard/#/${this.config?.DATAPLANT_ID}/${this.item.shared_id}`
    },
    options () {
      return [
        {
          label: this.$t('dashboard.snapreports'),
          icon: 'fp4-camera',
          // tooltip: { content: this.$t('dashboard.snapreports_tooltip'), delay: 0 },
          action: () => {
            this.snapreports()
            // this.displayActions = false
          },
          disabled: true
        },
        {
          label: this.$t('dashboard.download'),
          icon: 'fp4-down-to-line',
          // tooltip: { content: this.$t('dashboard.download'), delay: 0 },
          action: () => {
            this.download()
            // this.displayActions = false
          },
          disabled: this.disabledActions('download')
        },
        {
          label: this.$t('dashboard.share'),
          icon: 'fp4-paper-plane',
          // tooltip: { content: this.$t('dashboard.share'), delay: 0 },
          action: () => {
            this.share()
            // this.displayActions = false
          },
          disabled: this.disabledActions('share')
        }
      ]
    }
  },
  async mounted () {
    this.config = await Config()
  },
  methods: {
    addQuery () {
      this.$store.commit('FOREPAAS_QB_ADD_TILE_IN_DASHBOARD')
    },
    toggleDisplayActions () {
      this.displayActions = !this.displayActions
    },
    disabledActions (action) {
      // TODO - See with Nico conditions
      // TODO check acls

      switch (action) {
        case 'download':
          return true
        case 'link':
          return false
        case 'share':
          return true
        default:
          return false
      }
    },
    preview () {
      this.$analytics.track('Preview dashboard', {
        organization_id: this.config.ORGANIZATION_ID,
        shared_id: this.item.shared_id
      })
      const url = `${this.config.FPUI}/am/#/${this.config.DATAPLANT_ID}/dashboard/${this.$route.params.id}/preview`
      window.open(url, '_blank')
    },
    snapreports () {
      console.log('snapreports')
    },
    download () {
      console.log('download')
    },
    share () {
      console.log('share')
    },
    refresh () {
      this.$analytics.track('Refresh dashboard', {
        organization_id: this.config?.ORGANIZATION_ID,
        shared_id: this.item.shared_id
      })
      this.$store.commit('FOREPAAS_QB_REFRESH_DASHBOARD')
    },
    clipboardSuccessHandler ({ value, event }) {
      this.$analytics.track('Copy link of dashboard', {
        organization_id: this.config?.ORGANIZATION_ID,
        shared_id: this.item.shared_id
      })
      this.$fpuiMessageBlock.pop('success', this.$t('edit_powerbi.config.copy_button.success'))
    },
    toggleFilters () {
      this.$emit('toggle-filters')
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.dashboarding-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 30px 50px 0;
  padding-left: 43px;
  padding-right: 48px;

  &.empty {
    padding-left: 0;
    padding-right: 5px;
    &.editing {
      padding-right: 48px;
    }
  }

  &.editing:not(.empty) {
    padding-right: 55px;
  }

  .left {
    width: 100%;
  }
  .right {
    display: flex;
    align-items: center;

    .button-container {
      .fpui-button {
        margin-left: 2px;
        margin-right: 2px;

      }
      &.actions {
        .fpui-button {
          width: 34px;
          .btn-content {
            line-height: 0;
          }
          i {
            font-size: 20px;
          }
        }
      }
      &.display-actions {
        .fpui-button {
          background: transparent;
          border: none;
        }

        .actions-list {
          display: none;
          position: absolute;
          top: 28px;
          right: 0;
          background: white;
          box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
          border-radius: 7px;
          z-index: 10;
          ul {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              padding: 12px 20px;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #3E4550;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .action-label {
                margin-left: 10px;
                text-transform: none;
              }

              &:hover {
                background: rgba(246, 249, 252);
              }

              &:last-of-type {
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
              }

              &.disabled {
                border: unset !important;
                cursor: not-allowed !important;
                color: rgba(62,69,80,0.65);
              }
            }
          }
        }

        &:hover {
          .actions-list {
            display: block
          }
        }
      }
    }
    .actions-container {
      display: flex;
      align-items: center;
      border: 1px solid rgba(151,167,183,0.21);
      border-radius: 5px;
      margin-left: 5px;
      height: 34px;
      a {
        font-size: 20px;
        display: inline-block;
        color: #97A7B7;
        height: 32px;
        width: 34px;
        padding: 4px 6px;
        cursor: pointer;
        border-right: 0.5px solid rgba(151,167,183,0.21);
        border-left: 0.5px solid rgba(151,167,183,0.21);
        &:first-child {
          border-radius: 4px 0 0 4px;
          border-left: none;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          border-right: none;
        }
        &.disabled {
          cursor: not-allowed;
          box-shadow: none;
          color: #E4E7EC;
        }
        &:hover:not(.disabled) {
          color: white;
          background-color: @grey;
          box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
          border: none;
        }
      }
    }
  }
}
</style>
