import API from '@/shared/plugins/Api/API'
import Attributes from './Attributes'
import Databases from './Databases'
import Instances from './Instances'
import Jobs from './Jobs'
import LogicalAttributes from './LogicalAttributes'
import LogicalObjects from './LogicalObjects'
import LogicalReferentials from './LogicalReferentials'
import Metas from './Metas'
import Rules from './Rules'
import Views from './Views'
import VirtualAttributes from './VirtualAttributes'

class DM extends API {
  constructor (id) {
    super(id)
    this.jobs = new Jobs(id)
    this.databases = new Databases(id)
    this.attributes = new Attributes(id)
    this.instances = new Instances(id)
    this.rules = new Rules(id)
    this.views = new Views(id)
    this.metas = new Metas(id)
    this.logicalObjects = new LogicalObjects(id)
    this.logicalReferentials = new LogicalReferentials(id)
    this.logicalAttributes = new LogicalAttributes(id)
    this.virtualAttributes = new VirtualAttributes(id)
  }

  async workflowBuilder (repository, repository_version, tables = null) {
    const res = await this.request({
      url: 'v4/workflow_builder',
      params: {
        tables: JSON.stringify(tables),
        repository,
        repository_version
      }
    })
    return res
  }

  async physicalBuild (objects) {
    return await this.request({
      method: 'put',
      socketId: false,
      url: 'v4/build/start',
      data: {
        objects
      }
    })
  }

  async stopPhysicalBuild () {
    return await this.request({
      method: 'delete',
      url: 'v4/build/stop'
    })
  }

  async metrics () {
    return await this.request({
      url: 'v4/metrics'
    })
  }
}
export default DM
