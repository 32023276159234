<template lang="pug">
  .fpui-input-tags(:class='getCls')
    .fpui-input-label-container(v-if='label')
      span {{ label | translate }}
      .required-container(v-if='required')
        i.fpui.fpui-required
      fpui-helper(
        v-if='helper'
        :text="helper | translate"
        :title="helperTitle ? helperTitle : ''"
      )
      span.error(v-if="error") {{ $t(errorMessage) }}
    .fpui-input-tags-content(
      :class="autocompleteDirection"
    )
      i.icon(v-if="icon",:class="icon")
      vue-tags-input(
        ref='input'
        v-model="model"
        :validation="computedValidation"
        :tags='tags'
        :placeholder="placeholderValue"
        @tags-changed="tagsChanged"
        :disabled='disabled'
        :autocomplete-items="autocompleteFiltered"
        :isDuplicate="allowDuplicates ? (() => false) : isDuplicate"
        :add-only-from-autocomplete="addOnlyFromAutocomplete"
        :autocomplete-always-open="autocompleteAlwaysOpen"
        :max-tags="maxTags"
      )
        template(
          slot="tag-center"
          slot-scope="props"
        )
          slot(name="tag-center" v-bind="props") {{ props.tag.text }}
        template(
          slot="autocomplete-item"
          slot-scope="props"
        )
          slot(name="autocomplete-item" v-bind="props")
            div(@click="props.performAdd(props.item)") {{ props.item.text }}

</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: { VueTagsInput },
  props: {
    value: { type: [Array, String], default: () => [] },
    color: { type: String, default: 'grey' },
    validation: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    id: { type: String, required: false, default: '' },
    name: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    autocomplete: { type: Array, default: () => [] },
    icon: { type: String, default: '' },
    type: { type: String, default: 'text' },
    autofocus: { type: Boolean, default: false },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    autocompleteValidation: { type: Boolean, default: false },
    modelIsObject: { type: Boolean, default: false },
    modelIsString: { type: Boolean, default: false },
    allowDuplicates: { type: Boolean, default: false },
    addOnlyFromAutocomplete: { type: Boolean, default: false },
    autocompleteAlwaysOpen: { type: Boolean, default: false },
    maxTags: { type: Number, default: () => null }
  },
  data () {
    return {
      model: '',
      elementTop: 0
    }
  },
  computed: {
    getCls () {
      const cls = [this.color]
      if (this.disabled) cls.push('disabled')
      return cls.join(' ')
    },
    autocompleteFiltered () {
      return this.autocomplete.filter(item => {
        if (!item.text) return false
        if (!this.model) return true
        return item.text.toLowerCase().indexOf(this.model.toLowerCase()) !== -1
      })
    },
    tags () {
      if (typeof this.value === 'string' && this.modelIsString) {
        if (this.value === '') return []
        return this.value.split(',').map(tag => { return { tiClasses: ['valid'], text: tag } }).filter(tag => {
          return tag.text !== ''
        })
      }
      return (
        this.value &&
        this.value.map(tag => (
          typeof tag !== 'object' ? { tiClasses: ['valid'], text: tag } : { tiClasses: ['valid'], ...tag }
        ))
      )
    },
    computedValidation () {
      if (this.autocompleteValidation && this.autocomplete) {
        return [
          ...this.validation,
          {
            classes: 'autocomplete',
            rule: ({ text }) => {
              return !this.autocomplete.map(field => field.text).includes(text)
            },
            disableAdd: true
          }
        ]
      }
      return this.validation
    },
    autocompleteDirection () {
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return 'bottom'
      let dropdownHeight = this.autocomplete.length * 36 + 20
      if (dropdownHeight > 300) dropdownHeight = 300
      const height = dropdownHeight + 58
      if (this.elementTop - view.top + height > view.height) return 'top'
      return 'bottom'
    },
    placeholderValue () {
      return this.$t(this.placeholder)
    }
  },
  created () {
    if (!this.value) this.$emit('input', [])
  },
  mounted () {
    this.elementTop = this.$el?.getBoundingClientRect()?.top
  },
  methods: {
    tagsChanged (val) {
      let tags = []
      if (this.modelIsObject) { tags = val } else if (this.modelIsString) {
        const a = val.reduce((p, c) => {
          p.push(c.text)
          return p
        }, [])
        tags = a.toString()
      } else { val.forEach(v => tags.push(v.text)) }
      this.$emit('input', tags)
    },
    isDuplicate (tags, tag) {
      return tags.map(t => t.text).indexOf(tag.text) !== -1
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fpui-input-tags {
  min-height: 34px;
  &.disabled {
    .ti-icon-close {
      display: none;
    }
  }
  .ti-input {
    padding: 0 !important;
    border-radius: 3px;
  }
  .ti-autocomplete {
    ul {
      max-height: 300px;
      overflow: auto;
    }
    .ti-item {
      line-height: 30px;
    }
  }
  .ti-selected-item {
    background: #ebf9fe;
    color: inherit;
  }
  .ti-tags {
    min-height: 34px;
    border: 1px solid rgba(151, 167, 183, 0.21);
    border-radius: 3px;
    transition: border 200ms;
  }
  .ti-focus .ti-tags {
    border-color: @blue !important;
  }
  .ti-input {
    max-height: 102px;
    overflow: auto;
    border: none !important;
  }
  ul li .ti-icon-close {
    font-family: "forepaas-icons-v4" !important;
    &:before {
      font-size: 20px;
      color: rgba(62, 69, 80, 0.3);
      content: "\e958";
    }
  }
  .fpui-input-label-container {
    padding-left: 0;
    font-size: 12px !important;
    line-height: 15px;
    margin-bottom: 5px;
    color: #748294;
    cursor: pointer;
    .required-container {
      display: inline;
      vertical-align: middle;
      font-size: 22px;
      line-height: 18px;
      text-align: center;
      border-radius: 50%;
    }
    .error {
      margin-left: 10px;
      color: @red;
    }
  }
  .vue-tags-input {
    width: 100%;
    max-width: none !important;
    background-color: rgba(151, 167, 183, 0.06) !important;
    border: none;
    .input {
      border-radius: 3px;
      padding: 0px;
      height: 34px;
      overflow-y: hidden;
    }
    font-size: 14px;
    .tags {
      padding-left: 3px;
      padding-right: 3px;
      flex-wrap: nowrap;
      .content {
        padding: 2px;
      }
    }
    .ti-input {
      input {
        font-size: 14px;
        color: #3e4550;
        font-family: "Source Sans Pro", sans-serif;
        /* font-weight: 400; */
        letter-spacing: normal;

        &::placeholder {
          color: rgba(62,69,80,0.35);
        }
      }
      .ti-new-tag-input-wrapper {
        padding-left: 11px;
      }
    }
    &.ti-disabled {
      background-color: rgba(151, 167, 183, 0.06);
      border: none;
      opacity: 0.6;
      .ti-input {
        border: unset;
      }
    }
  }
  .fpui-input-tags-content {
    min-height: 34px;
    .actions i {
      font-size: 12px;
    }
    .icon {
      position: absolute;
      left: 8px;
      z-index: 2;
      top: 6px;
      font-size: 20px;
      color: #97A7B7;
    }

    &.top {
      .ti-autocomplete {
        bottom: 34px;
        border: 1px solid #ccc;
        border-bottom: none;
      }
    }
  }
  &.grey {
    .tag,
    .ti-tag {
      background-color: #e4e7ec;
      color: rgba(62, 69, 80, 0.65);
      font-family: "Source Sans Pro";
      font-size: 13px;
      line-height: 24px;
      height: 24px;
      margin: 4px 6px;
      padding: 0 8px 0 16px;
      border-radius: 12px;
      box-shadow: inset 0 -1px 0 0 rgba(62, 69, 80, 0.1),
        inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
      &.deletion-mark {
        background-color: @red !important;
        color: white !important;
      }
      &.ti-invalid {
        background-color: @red;
        opacity: 0.6;
        .ti-input {
          border: unset;
        }
      }
    }
    input {
      background: unset;
      font-size: 14px;
      &::placeholder {
        color: rgba(62,69,80,0.25);
      }
    }
  }
}
</style>
