<template lang="pug">
.filter-component(
  :class="{ editing: editing }"
)
  .filter-container(
    :style="{ cursor: loading ? 'not-allowed' : 'pointer' }"
  )
    .filter-label(
      @click="open"
    )
      i.fp4.fp4-filter-empty(
        v-if="!loading"
        v-tooltip="{ content: editing ? $t('dashboard.open_filter_advanced_settings') : '', delay: 0 }"
        @click.stop="openAdvancedFiltersModal"
      )
      fp-loading(
        v-if="loading"
        :size="20"
      )
      span.label {{ labelFilter }}
      i.fp4.fp4-xmark(
        v-if="!filterEmpty"
        v-tooltip="$t('dashbaord.filters.reset')"
        @click.stop="resetFilter"
      )

    .filter-selection(
      v-if="showFilter"
      :class="direction"
    )
      .date-picker-filter-type(
        v-if="filterIsDateOrDatetime"
      )
        fpui-date-picker(
          ref="datepicker"
          v-model="filterDate"
          no-button
          hide-refresh
          :hasUTC="false"
          :has-time="filterIsDatetime"
          :opened-right="datepickerRight"
          :custom-shortcut="shortcutsDatePicker"
          :display-switch-time="filterIsDatetime"
          :on-close="closeDatePicker"
          :timestamp="false"
          from-component="qb"
          display-date-footer
        )
          template(
            slot="header"
          )
            .filter-header(
              :style="{ 'margin-bottom': '15px' }"
            )
              .fp4.fp4-arrow-left(
                v-if="editing"
                v-tooltip="{ content: $t('dashboard.filter.change_filter_attribute'), delay: 0 }"
                @click="openAddFilter"
              )

              span.header-label(
                v-tooltip="{content : filter.label, classes: ['no-max-width']}"
              ) {{ filter.label }}

              .fp4.fp4-gear(
                v-if="editing"
                v-tooltip="{ content: $t('dashboard.open_filter_advanced_settings'), delay: 0 }"
                @click="openAdvancedFiltersModal"
              )
              .fp4.fp4-trash-can(
                v-if="editing"
                v-tooltip="{ content: $t('dashboard.remove_this_filter'), delay: 0 }"
                @click="removeFilter"
              )

      .classic-filter-type(
        v-if="!filterIsDateOrDatetime"
      )
        .filter-header(
          :style="{ 'margin-bottom': '10px' }"
        )
          .fp4.fp4-arrow-left(
            v-if="editing"
            v-tooltip="{ content: $t('dashboard.filter.change_filter_attribute'), delay: 0 }"
            @click="openAddFilter"
          )

          span.header-label(
                v-tooltip="{content : filter.label, classes: ['no-max-width']}"
              ) {{ filter.label }}

          .fp4.fp4-gear(
            v-if="editing"
            v-tooltip="{ content: $t('dashboard.open_filter_advanced_settings'), delay: 0 }"
            @click="openAdvancedFiltersModal"
          )
          .fp4.fp4-trash-can(
            v-if="editing"
            v-tooltip="{ content: $t('dashboard.remove_this_filter'), delay: 0 }"
            @click="removeFilter"
          )
        .filter-condition-selection
          fpui-input-select(
            :value='conditionSelected'
            :options='conditionOptions'
            :clearable="false"
            @input="updateConditionSelected"
          )
        .search(
          v-if="displayValues"
        )
          fpui-input-search(
            ref="search"
            v-model="search"
            :placeholder="$t('dashboard.start_typing')"
            autofocus
          )

        //- For Equal, not equal, in, not in
        .values-list(
          v-if="displayValues"
        )
          recycle-scroller(
            v-if="valuesFiltered.length"
            class="scroller"
            :items="valuesFiltered"
            :item-size="44"
            v-slot="{ item, index }"
            :style="{ height: valuesFiltered.length < 7 ? 'auto' : '308px', 'overflow-y': valuesFiltered.length < 7 ? 'hidden' : 'auto' }"
          )
            .value(
              :class="{ active: valuesSelected.includes(item), multiple: multiSelect }"
              v-tooltip="{ content: item, classes: ['no-max-width'] }"
              @click.stop="updateValuesSelected(item)"
            )
              fpui-input-checkbox(
                v-if="multiSelect"
                :value="valuesSelected.includes(item)"
                @input="updateValuesSelected(item)"
              ) {{ item }}
              span(v-else) {{ item }}

          //- Always display filter on value on screen
          .value(
            v-if="search && !valuesWithCustom.includes(search)"
            :class="{ active: valuesSelected.includes(search), multiple: multiSelect }"
            v-tooltip="{ content: search, classes: ['no-max-width'] }"
            @click.stop="updateValuesSelected(search)"
          )
            fpui-input-checkbox(
              v-if="multiSelect"
              :value="valuesSelected.includes(search)"
              @input="updateValuesSelected(search)"
            )
              span
                span.filter-on {{ $t('dashboard.filters.filter_on') }}
                span {{ search }}
            span(v-else)
              span.filter-on {{ $t('dashboard.filters.filter_on') }}
              span {{ search }}

          .value.empty(
            v-if="!valuesFiltered.length && !search"
          )
            span {{ $t('dashboard.filters.no_values') }}

        //- For Gt, gte, lt, lte
        .filter-input-container(
          v-if="displayInputText"
        )
          fpui-input-text(
            ref="text"
            :value="valuesSelected[0]"
            :placeholder="'e.g. 15.2'"
            autofocus
            @input="v => updateValuesText(v)"
          )

        //- For between, not between
        .filter-input-container.between(
          v-if="displayBetween"
        )
          fpui-input-text(
            ref="between"
            :value="valuesSelected[0]"
            :placeholder="'15.2'"
            autofocus
            @input="v => updateValuesBetween(v, 0)"
          )
          span {{ $t('query.filter.to') }}
          fpui-input-text(
            :value="valuesSelected[1]"
            :placeholder="'20'"
            @input="v => updateValuesBetween(v, 1)"
          )

        .apply-filter-button(
          v-if="!equalSelect"
        )
          fpui-button(
            color="blue-flash"
            @click="applyFilters"
          ) {{ $t('dashboard.filter.apply_filters') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _intersection from 'lodash/intersection'
import RecycleScroller from 'vue-virtual-scroller/src/components/RecycleScroller.vue'
import moment from 'moment'

import AdvancedFiltersModal from '../Modals/AdvancedFiltersModal.vue'
import shortcutsDatePicker from '../utils.js'

export default {
  components: {
    RecycleScroller
  },
  props: {
    item: { type: Object, default: () => ({}) },
    filter: { type: Object, default: () => ({}) },
    fromDashboard: { type: Boolean, default: false },
    editing: { type: Boolean, default: false },
    layout: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showFilter: false,
      search: '',
      values: [],
      valuesSelected: [],
      valuesCreated: [],
      loading: false,
      error: null,
      conditionSelected: 'equal',
      labelFilter: '',
      preventCloseIfOpen: false,
      elementLeft: 0,
      elementRight: 0,
      direction: 'right',
      datepickerRight: 'unset',
      shortcutsDatePicker
    }
  },
  computed: {
    valuesWithCustom () {
      return this.values.concat(this.valuesCreated).sort()
    },
    valuesFiltered () {
      if (!this.search) return this.valuesWithCustom

      return this.valuesWithCustom.filter(val => `${val}`.toLowerCase().includes(this.search.toLowerCase()))
    },
    query () {
      if (!this.filter.query) return {}
      const isString = typeof this.filter.query === 'string'
      if (isString && this.fromDashboard) return {}
      if (isString) return this.$store.getters.QB_QUERY_BY_SHARED_ID(this.item.repository, this.filter.query, this.item.repository_version) || {}
      return this.filter.query
    },
    filterType () {
      return this.filter?.type || 'categorical'
    },
    filterIsDateOrDatetime () {
      return ['date', 'datetime'].includes(this.filterType)
    },
    filterIsDate () {
      return this.filterType === 'date'
    },
    filterIsDatetime () {
      return this.filterType === 'datetime'
    },
    conditionsList () {
      if (this.filterType === 'boolean') {
        return [
          // 'equal_true',
          // 'equal_false',
          'equal',
          'not_equal',
          'is_null',
          'is_not_null'
        ]
      }

      return [
        'equal',
        'not_equal',
        'in',
        'not_in',
        'is_null',
        'is_not_null',
        'gt',
        'gte',
        'lt',
        'lte',
        'between',
        'not_between'
      ]
    },
    conditionOptions () {
      return this.conditionsList.map(c => {
        return {
          label: this.$t(`dashboard.filter.condition.${c}`),
          value: c
        }
      })
    },
    displayValues () {
      return ['equal', 'not_equal', 'in', 'not_in'].includes(this.conditionSelected) && ['number', 'categorical', 'boolean'].includes(this.filterType)
    },
    displayInputText () {
      return ['gt', 'gte', 'lt', 'lte'].includes(this.conditionSelected) && ['number', 'categorical'].includes(this.filterType)
    },
    displayBetween () {
      return ['between', 'not_between'].includes(this.conditionSelected) && ['number', 'categorical'].includes(this.filterType)
    },
    multiSelect () {
      return ['in', 'not_in'].includes(this.conditionSelected)
    },
    nullSelect () {
      return ['is_null', 'is_not_null', 'equal_true', 'equal_false'].includes(this.conditionSelected)
    },
    equalSelect () {
      return ['equal', 'not_equal'].includes(this.conditionSelected)
    },
    filterEmpty () {
      const filters = this.filtersFromUrl
      if (!filters || !filters[this.filter.label]) return true
      return !Object.keys(filters[this.filter.label]).length
    },
    filters () {
      return Object.keys(this.item.filter || {})
    },
    filtersFromUrl () {
      const query = this.$route.query
      const filtersFormatted = {}

      Object.keys(query).forEach(q => {
        if (this.filters.includes(q)) {
          const details = query[q].split('-')
          const condition = details.shift() // Get condition
          const values = details.join('-').slice(1, -1).split('","') // Put all together + remove first " and last " + split values
          filtersFormatted[q] = {
            [condition]: values
          }
        }
      })

      return filtersFormatted
    },
    filterDate: {
      get () {
        if (this.filterIsDateOrDatetime && this.valuesSelected && this.valuesSelected.length) {
          if (this.conditionSelected === 'equal') {
            const date = moment(this.valuesSelected[0])
            if (date.isValid()) {
              return {
                start: date,
                end: date,
                shortcut: 'custom'
              }
            } else {
              return {
                shortcut: this.valuesSelected[0]
              }
            }
          } else if (this.conditionSelected === 'gte') {
            const date = moment(this.valuesSelected[0])
            return {
              start: date,
              end: null,
              shortcut: 'after'
            }
          } else if (this.valuesSelected.length === 2) {
            const start = moment(this.valuesSelected[0])
            const end = moment(this.valuesSelected[1])

            if (start.isValid() && end.isValid()) {
              return {
                start,
                end,
                shortcut: 'custom'
              }
            }
          }
        }

        return {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          shortcut: 'custom'
        }
      },
      set (value) {
        const format = this.filterIsDate ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
        const start = value.start.format(format)
        const end = value.end.format(format)
        const shortcut = value.shortcut

        if (shortcut === 'custom') {
          if (this.filterIsDate && start === end) {
            this.valuesSelected = [start]
            this.conditionSelected = 'equal'
          } else {
            this.valuesSelected = [start, end]
            this.conditionSelected = 'between'
          }
        } else if (shortcut === 'after') {
          this.valuesSelected = [start]
          this.conditionSelected = 'gte'
        } else {
          this.valuesSelected = [shortcut]
          this.conditionSelected = 'equal'
        }
        this.preventCloseIfOpen = false
        this.applyFilters()
      }
    },
    ignoredTiles () {
      const ignoredTiles = []
      if (this.layout?.cleanLayout) {
        this.layout.cleanLayout.forEach(t => {
          // TODO check this filter.lable
          if (t.ignored_filters && t.ignored_filters.includes(this.filter.label)) ignoredTiles.push(t.i)
        })
      }

      return ignoredTiles
    }
  },
  watch: {
    '$store.getters.QB_REFRESH_DASHBOARD_ID': {
      handler (value, oldValue) {
        if (value !== oldValue && !this.filterIsDateOrDatetime) this.loadValues()
      }
    },
    filter: {
      deep: true,
      handler (value, oldValue) {
        this.labelFilter = this.getLabelFilter(value)

        if (value?.type !== oldValue?.type) {
          this.conditionSelected = this.conditionsList[0]
        }
      }
    },
    '$route.query': {
      deep: true,
      handler () {
        this.labelFilter = this.getLabelFilter(this.filter)
      }
    },
    '$route.params.id': {
      handler (val) {
        if (val) this.initFilter()
      }
    }
  },
  async mounted () {
    window.addEventListener('click', this.close)
    window.addEventListener('resize', this.onResize)
    process.nextTick(() => {
      this.onResize()
    })

    this.conditionSelected = this.conditionsList[0]
    this.initFilter()
    if (!this.filterIsDateOrDatetime) await this.loadValues()
  },
  destroyed () {
    window.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    openAddFilter () {
      this.close()
      this.$emit('open-add-filter')
      this.removeFilter()
    },
    closeDatePicker () {
      this.preventCloseIfOpen = false
      this.showFilter = false
    },
    initFilter () {
      if (this.filtersFromUrl[this.filter?.label]) {
        this.conditionSelected = Object.keys(this.filtersFromUrl[this.filter?.label]).length ? Object.keys(this.filtersFromUrl[this.filter?.label])[0] : null
        if (this.conditionSelected) this.valuesSelected = _cloneDeep(this.filtersFromUrl[this.filter?.label][this.conditionSelected])

        // Spec
        // if (this.conditionSelected === 'equal' && [true, false].includes(this.valuesSelected[0]) && this.filterType === 'boolean') {
        //   this.conditionSelected = `equal_${this.valuesSelected[0]}`
        // }
      } else {
        const filterHasCond = _intersection(Object.keys(this.filter), this.conditionsList)
        if (filterHasCond.length) {
          this.conditionSelected = filterHasCond[0]
          this.valuesSelected = _cloneDeep(this.filter[this.conditionSelected])
          this.updateRouter()
        }
      }
      this.labelFilter = this.getLabelFilter(this.filter)
    },
    updateRouter (add = true) {
      const query = _cloneDeep(this.$route.query)

      if (!Array.isArray(this.valuesSelected)) this.valuesSelected = [this.valuesSelected]
      if (add) query[this.filter.label] = `${this.conditionSelected}-"${this.valuesSelected?.join('","')}"`
      else delete query[this.filter.label]

      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    getLabelFilter (filter) {
      const labelCapitalize = filter.label[0].toUpperCase() + filter.label.substr(1)
      const filterHasCond = _intersection(Object.keys(filter), this.conditionsList)
      if (filterHasCond.length || this.filtersFromUrl[this.filter.label]) {
        let condition = ''
        let values = ''

        if (this.filtersFromUrl[this.filter.label]) {
          condition = Object.keys(this.filtersFromUrl[this.filter.label]).length ? Object.keys(this.filtersFromUrl[this.filter.label])[0] : null
          values = condition ? this.filtersFromUrl[this.filter?.label][condition] : []
        } else if (this.editing) {
          condition = filterHasCond[0]
          values = filter[condition]
        }
        const conditionLowercase = this.$t(`dashboard.filter.condition.${condition}`).toLowerCase()

        if (!this.nullSelect && values.length) {
          if (this.filterIsDateOrDatetime) {
            if (values.length === 2) return `${labelCapitalize} ${conditionLowercase} "${values[0]}" & "${values[1]}"`
            return `${labelCapitalize} ${conditionLowercase} "${moment(values[0]).isValid() ? values[0] : this.$t(`datepicker.${values[0]}`)}"`
          }
          // Case where it's not a date time value and will add "&" the the second last and if three will add a ","
          if (values.length === 1) return `${labelCapitalize} ${conditionLowercase} "${values[0]}"`
          else if (values.length < 4) return `${labelCapitalize} ${conditionLowercase} "${values.slice(0, -1).join(', ')} & ${values.slice(-1)}"`
          return `${labelCapitalize} ${conditionLowercase} "${values[0]}" ${this.$t('dashboard.filter.more_than_1', [values.length - 1], values.length - 1)}`
        }
        if (this.nullSelect) return `${labelCapitalize} ${conditionLowercase}`
      }
      return labelCapitalize
    },
    async loadValues () {
      if (!this.query || !Object.keys(this.query).length) return

      this.loading = true
      try {
        const response = await this.$api.QB.getResult(this.query, null, {
          legacy: false,
          source: 'AM',
          dashboardId: this.item._id,
          queryId: this.query.shared_id,
          fromDashboard: this.fromDashboard
        })

        this.formatValues(response)

        this.error = null
      } catch (err) {
        this.error = true
      }

      this.loading = false
    },
    formatValues (response) {
      this.values = (response?.results || []).map(r => {
        return r.data[this.filter.label]?.select_distinct[0]?.value
      }).sort()
    },
    async resetFilter () {
      this.conditionSelected = this.conditionsList[0]
      this.valuesSelected = []

      this.updateRouter(false)

      this.$analytics.track('Interact with dashboard filter', {
        shared_id: this.item.shared_id || this.item._id,
        operator: this.conditionSelected,
        in_preview_mode: !this.editing,
        type: this.filter.type || 'categorical',
        ignored_tiles: this.ignoredTiles.length
      })
      if (this.editing) {
        this.item.update('filter', {
          ...this.item.filter,
          [this.filter.label]: {
            type: this.filter.type,
            query: this.filter.query
          }
        }, false)
        if (this.item._id) await this.item.save()
      }
    },
    updateValuesText (val) {
      const value = val
      this.valuesSelected = [value]
    },
    updateValuesBetween (val, i) {
      this.$set(this.valuesSelected, i, val)
    },
    updateValuesSelected (val) {
      if (!this.valuesWithCustom.includes(val)) this.valuesCreated.push(val)

      if (this.multiSelect) {
        if (this.valuesSelected.includes(val)) {
          this.valuesSelected = this.valuesSelected.filter(v => v !== val)
        } else {
          this.valuesSelected.push(val)
        }
      }

      if (!this.multiSelect) {
        this.search = ''
        this.valuesSelected = [val]
        this.applyFilters()
      }
    },
    async applyFilters () {
      this.close()

      this.updateRouter()

      this.$analytics.track('Interact with dashboard filter', {
        shared_id: this.item.shared_id || this.item._id,
        operator: this.conditionSelected,
        in_preview_mode: !this.editing,
        type: this.filter.type,
        ignored_tiles: this.ignoredTiles.length
      })
      if (this.editing) {
        this.item.update('filter', {
          ...this.item.filter,
          [this.filter.label]: {
            query: this.filter.query,
            type: this.filter.type || 'categorical',
            [this.conditionSelected]: this.valuesSelected
          }
        }, false)
        if (this.item._id) await this.item.save()
      }
    },
    async removeFilter () {
      if (!this.editing) return

      const filterEmpty = this.filterEmpty
      const filters = _cloneDeep(this.item.filter)
      delete filters[this.filter.label]

      this.item.update('filter', filters, false)
      if (!filterEmpty) this.updateRouter(false)

      if (this.item._id) await this.item.save()

      this.$analytics.track('Remove dashboard filter', {
        shared_id: this.item.shared_id || this.item._id
      })
    },
    updateConditionSelected (condition) {
      if (['equal', 'not_equal'].includes(condition) && this.valuesSelected.length > 1) this.valuesSelected = [this.valuesSelected[0]]
      if (['is_null', 'is_not_null', 'in', 'not_in'].includes(condition) && this.valuesSelected.length) this.valuesSelected = []
      if (['gt', 'gte', 'lt', 'lte'].includes(condition) && this.valuesSelected.length) this.valuesSelected = ['']
      if (['between', 'not_between'].includes(condition) && this.valuesSelected.length) this.valuesSelected = ['', '']

      this.conditionSelected = condition

      // Spec
      // if (['equal_true', 'equal_false'].includes(condition)) {
      //   console.log('la')
      //   const value = condition === 'equal_true'
      //   this.conditionSelected = 'equal'
      //   this.valuesSelected = [value]
      // }

      process.nextTick(() => {
        if (this.displayValues && this.$refs.search?.setInputFocus) this.$refs.search.setInputFocus()
        if (this.displayInputText && this.$refs.text?.focus) this.$refs.text.focus()
        if (this.displayBetween && this.$refs.between?.focus) this.$refs.between.focus()
      })
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if (this.preventCloseIfOpen) return
      if ($event && this.isParent($event.target)) return
      this.showFilter = false
      this.search = ''
    },
    open () {
      if (!this.showFilter) {
        this.showFilter = true
        process.nextTick(() => {
          if (this.$refs.search?.focus) this.$refs.search.focus()
          if (this.$refs.datepicker) this.$refs.datepicker.open()
        })
      } else {
        this.close()
      }
    },
    openAdvancedFiltersModal () {
      if (!this.editing) {
        this.open()
        return
      }

      this.$analytics.track('Open advanced filter settings', {
        shared_id: this.item.shared_id || this.item._id
      })

      this.preventCloseIfOpen = true
      this.$modal.show(AdvancedFiltersModal, {
        filter: this.filter,
        item: this.item,
        layout: this.layout
      }, {
        height: 'auto',
        width: '603px'
      },
      {
        closed: () => {
          this.preventCloseIfOpen = false
        }
      })
    },
    filtersClasses () {
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return 'right'

      const width = this.filterIsDateOrDatetime ? 786 : 294 // 786 is the width of the datepicker
      this.datepickerRight = 'unset'

      if (this.elementLeft - view.left + width > view.width) {
        if (this.elementRight - view.left < width) this.datepickerRight = Math.round(-(width - (this.elementRight - view.left - 30))) + 'px'
        else this.datepickerRight = '0'

        return 'left'
      }
      return 'right'
    },
    onResize () {
      this.elementLeft = this.$el?.getBoundingClientRect()?.left
      this.elementRight = this.$el?.getBoundingClientRect()?.right
      this.direction = this.filtersClasses()
    }
  }
}
</script>

<style lang="less">
.filter-component {
  position: relative;
  margin: 5px;

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    background: white;
    height: 32px;
    cursor: pointer;

    &:hover {
      background: #f9fafb;
    }
    .input-transition();

    .filter-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .fp4-filter-empty {
        color: #97A7B7;
        font-size: 16px;
        margin-right: 6px;
      }
      .fp-loading {
        margin-right: 3px;
      }
      .label {
        color: #748294;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-right: 15px;
        padding-bottom: 0;
      }
      .fp4-xmark {
        color: #CBD3DB;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #0089c0;
        }
      }
    }

    .filter-selection {
      position: absolute;
      top: 36px;
      left: 0;
      background: white;
      width: 294px;
      box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
      border-radius: 7px;
      z-index: 1000;

      &.right {
        left: 0;
        .date-picker-filter-type {
          .opened {
            right: unset;
          }
        }
      }
      &.left {
        left: unset;
        right: 0;
        .date-picker-filter-type {
          .opened {
            right: 0;
          }
        }
      }

      .filter-header {
        height: 52px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3E4550;
        border-bottom: 1px solid #E9ECF0;

        .fp4-arrow-left {
          position: absolute;
          font-size: 28px;
          left: 15px;
          top: 13px;
          color: #97a7b7;
          cursor: pointer;

          &:hover {
            color: #0089c0;
          }
        }

        .header-label {
          width: 178px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .fp4-gear {
          position: absolute;
          font-size: 20px;
          right: 38px;
          top: 16px;
          color: #97a7b7;
          cursor: pointer;

          &:hover {
            color: #0089c0;
          }
        }
        .fp4-trash-can {
          position: absolute;
          font-size: 20px;
          right: 10px;
          top: 16px;
          color: #97a7b7;
          cursor: pointer;

          &:hover {
            color: #F62172;
          }
        }
      }

      .date-picker-filter-type {
        .opened {
          top: 0;

          .date-picker-container {
            .short-cuts, .date-picker, .time-selection {
              padding-top: 0;
            }
          }
        }
      }

      .classic-filter-type {
        .fpui-input-select-container {
          margin-top: 8px;
          margin-right: 20px;
          margin-left: 20px;
        }

        .search {
          width: 100%;
          height: 52px;
          padding: 10px 20px;
        }

        .values-list {
          overflow-x: hidden;

          .scroller {
            height: 100%;
          }

          .value {
            height: 44px;
            font-weight: 400 !important;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #3E4550;
            display: flex;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;

            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              .filter-on {
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: #00CCF9;
                margin-right: 5px;
              }
            }

            .fpui-input-checkbox {
              margin-right: 0;
              cursor: pointer;

              .check {
                background: white;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
              }

              .content-checkbox {
                width: 220px;
              }
            }

            &.empty {
              font-style: italic;
              color: #97a7b7;
            }

            &:hover {
              background: rgba(246, 249, 252);
              box-shadow: inset 0px -1px 0px #F6F9FC;
              font-weight: 400;
              cursor: pointer;
            }
            &.active:not(.multiple) {
              color:#0089C0;
              font-weight: 400;
            }
          }
        }

        .filter-input-container {
          width: 100%;
          height: 52px;
          padding: 10px 20px;

          &.between {
            display: flex;
            align-items: center;
            > span {
              margin: 0 5px;
              color: @grey-chart;
              font-size: 14px;
            }
          }
        }
      }
    }

    .apply-filter-button {
      width: 100%;
      height: 52px;
      padding: 10px;

      .fpui-button {
        width: 100%;
      }
    }
  }

  &.editing {
    .fp4-filter-empty {
      &:hover {
        color: #0089c0;
        &:before {
          color: #0089c0; // Don't know why
        }
      }
    }
  }
}
</style>
