import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
class LogicalAttribute extends APIObject {
  constructor (options = {}) {
    // Init
    super('DM', options)

    this._type = 'logical'
    // Default variables
    this.name = this.name || ''
    this.display_name = this.display_name || this.name || ''
    this.tags = this.tags || {}
    this.tags.mapping_table = this.tags.mapping_table || null
    this.description = this.description || ''
    this.path = this.path || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.is_dimension = this.is_dimension || false
    this.is_measure = this.is_measure || false
    this.type = this.type || {}
    this.type.main = this.type.main || 'txt'
    if (!this.is_dimension && !this.is_measure) {
      this.is_dimension = true
    }
    this.linked_tables = this.linked_tables || []
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    const clone = new LogicalAttribute(_cloneDeep(this._filter(this)))
    return clone
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      '_type',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'children'
    ])
    return obj
  }

  async create () {
    this.display_name = this.display_name || this.name
    return super.create({
      method: 'POST',
      url: 'v4/logical/attributes'
    })
  }

  async save () {
    this.display_name = this.display_name || this.name
    return super.save({
      method: 'PUT',
      url: `v4/logical/attributes/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/logical/attributes/${this._id}`
    })
  }
}
class LogicalAttributes extends API {
  new (opts) {
    return new LogicalAttribute(opts)
  }

  async list () {
    const attributes = await this.request({
      method: 'get',
      url: 'v4/logical/attributes'
    })
    return attributes.map(attribute => {
      try {
        return new LogicalAttribute(attribute)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(a => a)
  }
}

export default LogicalAttributes
export {
  LogicalAttribute,
  LogicalAttributes
}
