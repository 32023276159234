<template lang="pug">
.chart-settings(v-if="type")
  slot

  general-panel(
    v-if="(fromDashboardEdit && !['line'].includes(type)) || !fromDashboardEdit"
    :key="`${type}-general`"
    :type="type"
    :options="options"
    :from-dashboard-edit="fromDashboardEdit"
    @update="update"
  )

  //- Not for Table
  labels-and-legends-panel(
    :key="`${type}-labels-panel`"
    :type="type"
    :options="options"
    @update="update"
    v-if="!['table', 'big-number'].includes(type)"
  )

  //- Not for Pie chart and Table
  axis(
    :key="`${type}-axis`"
    :type="type"
    :options="options"
    :result="result"
    @update="update"
    v-if="!['pie', 'table', 'big-number'].includes(type)"
  )

  //- Only for Table
  advanced(
    :key="`${type}-advanced`"
    :type="type"
    :options="options"
    @update="update"
    v-if="['table'].includes(type)"
  )
</template>

<script>
import GeneralPanel from './GeneralPanel'
import LabelsAndLegendsPanel from './LabelsAndLegendsPanel'
import Axis from './Axis'
import Advanced from './Advanced'

export default {
  components: {
    GeneralPanel,
    LabelsAndLegendsPanel,
    Axis,
    Advanced
  },
  props: {
    type: { type: String, default: '' },
    item: { type: Object, default: () => ({}) },
    result: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false },
    fromDashboardEdit: { type: Boolean, default: false }
  },
  computed: {
    options () {
      return this.item.chart_settings[this.type]
    }
  },
  methods: {
    update (key, value) {
      this.$analytics.track('Edit chart configuration', {
        shared_id: this.item.shared_id,
        chart_type: this.type
      })
      this.item.update(`chart_settings.${this.type}.${key}`, value, !this.readOnly)
    }
  }
}
</script>

<style lang="less">
.chart-settings {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(246, 249, 252, 0.3);
  padding: 10px 25px 10px 25px;

  .box-subtitle {
    font-size: 12px;
    color: #0089c0;
    font-weight: 600;
    margin-top: 17px;
    margin-bottom: 5px;
  }
}
</style>
