import axios from 'axios'
import _merge from 'lodash/merge'
import urljoin from 'url-join'
import Vue from 'vue'
import later from './later'

let config = {
  APP_ID: 'forepaas'
}

const setEnv = (env = 'production') => {
  const defaultValues = {
    STORE: 'https://store.forepaas.io',
    FPUI: 'https://hq.forepaas.io',
    DOCS: 'https://docs.forepaas.io',
    STATUS: 'https://status.forepaas.io',
    KING: 'https://hq-auth.forepaas.io',
    FPAPI: 'https://hq-api.forepaas.io',
    SUPPORT: 'https://hq.forepaas.com/#/support',
    SUPPORT_API: 'https://hq-support.forepaas.io',
    STORAGE: 'https://storage-engine.forepaas.io',
    SIGNUP: false
  }
  const PLATFORM = {
    local: {
      STORE: 'https://store-dev.forepaas.com',
      FPUI: 'https://hq-dev.forepaas.com',
      DOCS: 'https://hq-dev-docs.forepaas.com',
      STATUS: 'https://status.forepaas.io',
      KING: 'https://hq-dev-auth.forepaas.com',
      FPAPI: 'https://hq-dev-api.forepaas.com',
      SUPPORT: 'https://hq-dev.forepaas.com/#/support',
      SUPPORT_API: 'https://hq-dev-support.forepaas.com',
      STORAGE: 'https://storage-engine-dev.forepaas.com',
      SIGNUP: true
    }
  }
  config = _merge(config, defaultValues)
  if (PLATFORM[env]) _merge(config, PLATFORM[env])


  config.DATAPLANT_MODE = false
  config.STORE = process.env.VUE_APP_STORE || config.STORE
  config.FPUI = process.env.VUE_APP_FPUI || config.FPUI
  config.DOCS = process.env.VUE_APP_DOCS || config.DOCS
  config.STATUS = process.env.VUE_APP_STATUS || config.STATUS
  config.KING = process.env.VUE_APP_KING || config.KING
  config.FPAPI = process.env.VUE_APP_FPAPI || config.FPAPI
  config.SUPPORT = process.env.VUE_APP_SUPPORT || config.SUPPORT
  config.SUPPORT_API = process.env.VUE_APP_SUPPORT_API || config.SUPPORT_API
  config.STORAGE = process.env.VUE_APP_STORAGE || config.STORAGE
  config.SIGNUP = process.env.VUE_APP_SIGNUP === 'true' || config.SIGNUP
}

setEnv(process.env.VUE_APP_ENV || 'production')

let cache = null

const setConfig = (appConfig = {}) => {
  config = _merge(config, appConfig)
  config._init = true
}

const setDataplantBaseUrl = (dataplant) => {
  config.ORGANIZATION_ID = dataplant.organization_id
  config.BASE_URL = `https://${dataplant.url_proxy}`
  config.DATAPLANT_ID = dataplant.dataplant_id
  config.FPRN = dataplant.fprn
  config.DATAPLANT_NAME = dataplant.name
  config.OFFER = dataplant.offer || 'mutualized'
  config.CLUSTER = dataplant.cluster

  config.IAM = process.env.VUE_APP_IAM || urljoin(config.BASE_URL, 'iam')
  config.DPE = process.env.VUE_APP_DPE || urljoin(config.BASE_URL, 'dpe')
  config.DWH = process.env.VUE_APP_DWH || urljoin(config.BASE_URL, 'dwh')
  config.DM = process.env.VUE_APP_DM || urljoin(config.BASE_URL, 'dwh')
  config.QB = process.env.VUE_APP_QB || urljoin(config.BASE_URL, 'qb')
  config.QUERY_ADMIN = process.env.VUE_APP_QUERY_ADMIN || urljoin(config.BASE_URL, 'query-admin')
  config.ML = process.env.VUE_APP_ML || urljoin(config.BASE_URL, 'ml')
  config.DTL = process.env.VUE_APP_DTL || urljoin(config.BASE_URL, 'datalab')
  config.DS = process.env.VUE_APP_DS || urljoin(config.BASE_URL, 'datastore')
  config.APPSERVICE = process.env.VUE_APP_APPERVICE || urljoin(config.BASE_URL, 'appservice')

  config.DPE_WS = `${config.DPE.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.IAM_WS = `${config.IAM.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.ML_WS = `${config.ML.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.DWH_WS = `${config.DM.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.QB_WS = `${config.QB.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
}

const cacheDataplantInfo = {}
const cacheOrganizationInfo = {}

/* istanbul ignore next */
const getConfig = async () => {
  if (!config._init) return later(10, getConfig)
  config.setEnv = setEnv

  if (!config.CMP) {
    const dataplantId = (window.location.hash.split('#/')[1] || '').split('/')[0].split('?')[0]

    // Use that variable to force dataplant url when fpapi is down (dev only)
    if (process.env.VUE_APP_DATAPLANT) {
      setDataplantBaseUrl({
        url_proxy: process.env.VUE_APP_DATAPLANT,
        organization_id: 'fpdevenv',
        dataplant_id: 'fpdevenv',
        fprn: 'fpdevenv-fpdevenv',
        name: 'Local mode'
      })
      return config
    }
    cacheDataplantInfo[dataplantId] = cacheDataplantInfo[dataplantId] || axios.get(urljoin(config.FPAPI, `dataplant/${dataplantId}/info`))
    try {
      const dataplantMetadata = await cacheDataplantInfo[dataplantId]
      setDataplantBaseUrl(dataplantMetadata.data)
    } catch (err) {
      throw new Error('DATAPLANT_NOT_FOUND')
    }
  }

  if (config.ORGANIZATION_ID) {
    cacheOrganizationInfo[config.ORGANIZATION_ID] = cacheOrganizationInfo[config.ORGANIZATION_ID] || axios.get(urljoin(config.KING, `v1/organizations/${config.ORGANIZATION_ID}/info`))
    try {
      const organizationMetadata = await cacheOrganizationInfo[config.ORGANIZATION_ID]
      config.ORGANIZATION_NAME = organizationMetadata.data.name
      config.SUB_STATUS = organizationMetadata.data.subscription_status
      config.SUB_SCHEDULED_STATUS = organizationMetadata.data.subscription_scheduled_status
    } catch (err) {
      throw new Error('ORGANIZATION_NOT_FOUND')
    }
  }

  return config
}

/* istanbul ignore next */
export default async () => {
  if (!cache) cache = await getConfig()
  const routeParams = Vue.$router.currentRoute.params
  if (routeParams.dataplantId) cache.DATAPLANT_ID = routeParams.dataplantId
  if (routeParams.organizationId) cache.ORGANIZATION_ID = routeParams.organizationId
  return cache
}

export {
  setConfig,
  setDataplantBaseUrl
}
