import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import Vue from 'vue'

// Exporting this model so that we won't need to duplicate the code everywhere
// for pipelines and notebooks
const environmentModel = (obj = {}) => {
  obj.fpu_configurations = obj.fpu_configurations || {}
  obj.fpu_configurations.dataset = obj.fpu_configurations.dataset || { instances: 1, size: 4, type: 'fpu-s' }
  obj.fpu_configurations.score = obj.fpu_configurations.score || { instances: 1, size: 4, type: 'fpu-s' }
  obj.fpu_configurations.train = obj.fpu_configurations.train || { instances: 1, size: 4, type: 'fpu-s' }
  obj.log_level = obj.log_level || 'info'
  obj.variables = obj.variables || {}
  obj.params = typeof (obj.params) === 'object' ? obj.params : {}
  obj.params.auto_flushall = typeof (obj.params.auto_flushall) !== 'undefined' ? obj.params.auto_flushall : true
  obj.params.segmentation = obj.params.segmentation || {}
  obj.params.perimeter = obj.params.perimeter || {}
  return obj
}

class Environment extends APIObject {
  constructor (options) {
    // Init
    super('ML', options)

    this.tags = this.tags || {}
    this.tags.tags = this.tags.tags || []
    this.description = this.description || ''
    this.type = 'environment'
    this.fpu_configurations = this.fpu_configurations || {}
    this.fpu_configurations.dataset = this.fpu_configurations.dataset || { instances: 1, size: 4, type: 'fpu-s' }
    this.fpu_configurations.score = this.fpu_configurations.score || { instances: 1, size: 4, type: 'fpu-s' }
    this.fpu_configurations.train = this.fpu_configurations.train || { instances: 1, size: 4, type: 'fpu-s' }
    this.log_level = this.log_level || 'info'
    this.variables = this.variables || {}
    this.params = typeof (this.params) === 'object' ? this.params : {}
    this.params.segmentation = this.params.segmentation || {}
    this.params.perimeter = this.params.perimeter || {}
    this.params.auto_flushall = typeof (this.params.auto_flushall) !== 'undefined' ? this.params.auto_flushall : true
    this.display_name = this.display_name || ''
  }

  clone () {
    const environment = new Environment(_omit(_cloneDeep(this), ['_id']))
    return environment
  }

  _filter (object) {
    return _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v2/environments'
    })
  }

  async save () {
    Vue.$analytics.track('Edit environment', {
      environment_id: this._id,
      dataset_resources_allocated: `${this.fpu_configurations.dataset.size}x${this.fpu_configurations.dataset.instances}`,
      train_resources_allocated: `${this.fpu_configurations.train.size}x${this.fpu_configurations.train.instances}`,
      score_resources_allocated: `${this.fpu_configurations.score.size}x${this.fpu_configurations.dataset.instances}`,
      deployment_resources_allocated: `${this.fpu_configurations.deploy.size}x${this.fpu_configurations.deploy.instances}`,
      segmentation_option: this.params?.segmentation?.type ? this.$t(`preferences.editor.type.${this.params?.segmentation?.type}`) : 'None',
      log_level: this.log_level,
      number_of_environment_variables: Object.keys(this.variables).length
    })

    return super.save({
      method: 'PUT',
      url: `v2/environments/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v2/environments/${this._id}`
    })
  }
}

class Environments extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v2/environments'
    }).then(environments => {
      return environments.map(environment => {
        try {
          return new Environment(environment)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Environment(item)
  }

  createFromObject (object) {
    return new Environment(object)
  }
}

export default Environments

export {
  Environment,
  Environments,
  environmentModel
}
