<template lang="pug">
.overview-container.no-trino-placeholder.fp-page
  fp-loader(v-if="loading")
  .fp-container
    i.icon.fp4(
      :class="icon"
    )
    .title {{ $t(title) }}
    .text {{ text || $t('overview.no_trino.text') }}
    a.info-container(
      href="https://docs.forepaas.io/#/en/product/am/resources"
      target="_blank"
    )
      .img-container
        .img
          img(:src="require('@/qb/assets/img/trino-logo.png')")
      .text-container
        .title-info {{ $t('overview.no_trino.info.title') }}
        .text-info(v-html="textInfo || $t('overview.no_trino.info.text')")
    .button(
      v-if="!noButton"
    )
      fpui-button(
        :auto-width="true"
        color='blue-flash'
        @click='activateTrino'
      ) {{ $t('overview.button.activate_a_query_engine') }}
</template>

<script>
export default {
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    textInfo: { type: String, default: '' },
    redirect: { type: String, default: '' },
    noButton: { type: Boolean, default: false }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async activateTrino () {
      this.loading = true
      try {
        await this.$api.FPAPI.dataplants.activateTrino(this.$route.params.dataplantId)
        this.$store.commit('FOREPAAS_QB_RESOURCES_UPDATE', { active: true })
        this.$fpuiMessageBlock.success(this.$t('resources.activate_trino'))
      } catch (err) {
        this.$store.commit('FOREPAAS_QB_RESOURCES_UPDATE', { active: false })
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.no-trino-placeholder{
  margin-top: 40px;
  background: rgba(246, 249, 252, 0.3);
  .fp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 561px;
    text-align: center;
    .icon {
      font-size: 72px;
      color: #97a7b7;
    }
    .title {
      margin-top: 15px;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #3e4550;
    }
    .text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 25px;
      color: #3e4550;
    }
    .info-container {
      margin-top: 20px;
      padding: 10px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #E9ECF0;
      box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .img-container {
        width: 113px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DD00A1;
        border: 1px solid #E9ECF0;
        border-radius: 6px;
        margin-right: 20px;
          .img {
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
            border-radius: 14px;

            img {
              height: 46px;
            }
          }
      }
      .text-container {
        text-align: left;
        .title-info {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #3e4550;
        }
        .text-info {
          margin-top: 10px;
          font-size: 14px;
          line-height: 25px;
          color: #3e4550;
        }
      }
    }
    .button {
      margin-top: 20px;
    }
  }
}
</style>
