<template lang="pug">
.query-data
  .current-data(
    @click="open"
    :key="changed"
    ref="currentData"
    :class="{ disabled: readOnly }"
  )
    .placeholder(
      v-if="!Object.keys(fields).length"
    ) {{ $t('query.fields.data.placeholder') }}
    .data-container(
      v-if="Object.keys(fields).length"
      v-for="key in Object.keys(fields)"
    )
      .data-item(:class="{ error: dataError.includes(key) }")
        .text {{ key }}
        .selector
          multi-type-selector(
            :needOldValue="true"
            :key="key"
            :value="fields[key]"
            @input="value => update(key, value)"
            @close="close()"
            :typesWithLabel="types"
            :read-only="readOnly"
          )
        i.fp4.fp4-circle-xmark(
          @click.stop="remove(key)"
        )
  .data-values(
    v-if="state"
    :style="{ width: currentDataWidth }"
  )
    fpui-input-search.search(
      :key-id="'search-data'"
      v-model="search"
      autofocus
    )
    ul(
      :style="{ height: `${(dataOptions.length > 7 ? 7 * 43 : (dataOptions.length || 1) * 43) + 20}px` }"
    )
      .li-container(v-if="dataOptions.length")
        li.value(v-for="data in dataOptions" @click="select(data)")
          .li-value-container
            span {{ data }}
      .li-container.no-attribute(v-else)
        span {{ $t('query.fields.no_attributes') }}

</template>

<script>
// import _uniq from 'lodash/uniqBy'
import _cloneDeep from 'lodash/cloneDeep'
import MultiTypeSelector from './Selector/MultiTypeSelector'

export default {
  components: { MultiTypeSelector },
  props: {
    fields: { type: Object, default: () => { return {} } },
    scales: { type: Array, default: () => { return [] } },
    order: { type: Object, default: () => { return {} } },
    configuration: { type: Object, default: () => { return {} } },
    attributes: { type: Array, default: () => { return [] } },
    readOnly: { type: Boolean, default: false },
    fromTable: { type: Object, default: () => { } }
  },
  data () {
    return {
      changed: false,
      state: false,
      search: '',
      types: [
        { value: 'select', label: 'SELECT' },
        { value: 'sum', label: 'SUM' },
        { value: 'max', label: 'MAX' },
        { value: 'min', label: 'MIN' },
        { value: 'count', label: 'COUNT' },
        { value: 'count_distinct', label: 'COUNT_DISTINCT' },
        { value: 'select_distinct', label: 'SELECT_DISTINCT' },
        { value: 'avg', label: 'AVG' }
      ],
      dataError: []
    }
  },
  computed: {
    currentDataWidth () {
      return this.$refs.currentData.offsetWidth + 'px'
    },
    dataOptions () {
      return this.attributes.filter(data => data.toLowerCase().includes(this.search.toLowerCase()) && !Object.keys(this.fields).includes(data))
    },
    allAttributes () {
      return this.$store.getters.DWH_ALL_ATTRIBUTES
    }
  },
  mounted () {
    window.addEventListener('click', this.close)

    if (this.fromTable) {
      let automaticAdd = []
      if (window.localStorage.getItem('table-infos-from-data-manager')) {
        automaticAdd = JSON.parse(window.localStorage.getItem('table-infos-from-data-manager'))
      } else {
        automaticAdd = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(this.fromTable._id).map(att => att.name) || []
      }
      this.select(automaticAdd)
    }
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    select (data) {
      const fields = _cloneDeep(this.fields)
      const attributesToAdd = Array.isArray(data) ? data : [data]
      attributesToAdd.forEach(d => { fields[d] = ['select'] })

      this.state = false
      this.search = ''
      // Update CurrentQueries in store
      this.$emit('update', { key: 'data', value: { ...this.configuration.data, fields } })
    },
    update (key, value) {
      const fields = _cloneDeep(this.fields)
      fields[key] = value

      if (value.length) {
        // Remove error
        const indexError = this.dataError.indexOf(key)
        if (indexError > -1) this.dataError.splice(indexError, 1)
      } else {
        // Add error
        this.dataError.push(key)
      }

      // Update CurrentQueries in store
      this.$emit('update', { key: 'data', value: { ...this.configuration.data, fields } })
    },
    remove (key) {
      if (this.readOnly) return

      const fields = _cloneDeep(this.fields)
      delete fields[key]

      // Remove error
      const indexError = this.dataError.indexOf(key)
      if (indexError > -1) this.dataError.splice(indexError, 1)

      // Update CurrentQueries in store
      this.$emit('update', { key: 'data', value: { ...this.configuration.data, fields } })

      // If no fields selected and table_name is set, unset it
      if (!Object.keys(fields).length) {
        this.$emit('update', { key: 'table_name', value: null })
      }

      // if we remove an attribute from data or scale and it is used in order, we remove it from order
      if (Object.keys(this.order).includes(key) && !this.scales.includes(key)) {
        const newOrder = _cloneDeep(this.order)
        delete newOrder[key]

        this.$emit('update', { key: 'order', value: newOrder })
      }

      this.changed = !this.changed
    },
    async open () {
      if (this.readOnly) return
      this.state = !this.state
      if (!this.state) this.search = ''
      await this.$nextTick()
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.state = false
      this.search = ''
    },
    isParent (target) {
      // Click on the type-selector we only open the type-selector
      if (target.className === 'type-selector') return false
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    }
  }
}
</script>
<style lang="less">
.query-data {
  position: relative;
  .current-data {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    min-height: 37px;
    padding: 0px 15px 6px 15px;
    border: 1px solid rgba(151,167,183,0.21);
    border-radius: 3px;
    background-color: rgba(151,167,183,0.06);
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .placeholder {
      margin-top: 6px;
      color: rgba(62,69,80,0.25);
      font-size: 14px;
      line-height: 18px;
    }
    .data-container {
      display: flex;
      .data-item {
        display: flex;
        align-items: center;
        margin-right: 5px;
        margin-top: 6px;
        padding: 3px 9px;
        border-radius: 12px;
        background-color: #E4E7EC;
        box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
        .text {
          margin-right: 5px;
          color: rgba(62,69,80,0.65);
          font-family: "Source Sans Pro";
          font-size: 13px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .selector {
          margin-right: 5px;
          .multi-type-selector {
            margin-left: 0px;
            .current-value{
              height: 16px;
              background-color: rgba(255,255,255,0.5);
              color: #748294;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 15px;
              span {
                line-height: 15px;
              }
            }
          }
        }
        .fp4-circle-xmark {
          font-size: 14px;
          color: #B2BECA;
        }

        &.error {
          background-color: @red;
          .text, i {
            color: white;
          }
        }
      }
    }
  }
  .data-values {
    max-height: 375px;
    position     : absolute;
    background   : white;
    border-radius: 9px;
    box-shadow   : 0 2px 10px 0 rgba(62, 69, 80, 0.25);
    padding-top  : 5px;
    z-index      : 2;
    margin       : 0;
    margin-bottom: 20px;
    .search {
      input {
        height      : 50px;
        border-left : 0;
        border-right: 0;
        border-top  : 0;
        background  : white;
        border-radius: 0px;
      }
      i.fp4 {
        top: 8px;
      }
    }
    ul {
      padding: 10px;
      overflow-y: auto;
      height: calc(~"100% - 50px");
      margin-top: 0;
      margin-bottom: 0;
      .li-container {
        li {
          color      : #3E4550;
          line-height: 18px;
          cursor     : pointer;

          &:hover,
          &.active {
            color           : #0089C0;
            background-color: rgba(0, 204, 249, 0.08);
          }

          font-size    : 14px;
          list-style   : none;
          padding      : 0 6px;
          border-radius: 6px;

          .li-value-container {
            display: flex;
            align-items: center;
            height: 43px;
            padding: 6px 19px;
          }
        }

        &.no-attribute {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 12px;
          color: #97a7b7;
          font-style: italic;
        }
      }
    }
  }
}
</style>
