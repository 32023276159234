<template lang='pug'>
.fp-header
  .preload-font
    i.fa.fa-spinner

  .left
    .logo.logo-organization
      a.home-link(
        v-if="config && config.ORGANIZATION_ID"
        :href="`#/home/${config.ORGANIZATION_ID}`"
      )
        img.forepaas-logo(src="@/shared/assets/img/logo.png")
    .logo.logo-dataplant(v-if="returnUrl")
      a.dataplant-link(:href="returnUrl")
        i.fp4.fp4-dataplants
    .logo.logo-service(
      :style="{ left: returnUrl ? '82px' : '34px' }"
    )
      a.service-link(:href="returnToOverview")
        img(
          :src="image"
          :class="[service]"
        )
    .service-name-container(
      :style="{ left: returnUrl ? '143px' : '96px' }"
    )
      .organization-dataplant-name-container
        a.organization-name(
          v-if='config && config.ORGANIZATION_ID'
          :href="`#/home/${config.ORGANIZATION_ID}`"
        ) {{ organizationName }}
        span(v-if="dataplantName") {{ ' | ' }}
        a.dataplant-name(
          v-if="dataplantName"
          :href="returnUrl"
        ) {{ dataplantName }}
      .service-selector-container
        service-selector(
          v-if="dataplantName"
          :service="service"
        )
        .control-center-title(
          v-if="!dataplantName"
          :style="{ left: 0, height: '26px' }"
        )
          .name-container
            span.name Control Center
        .flag
          span.beta-flag(v-if="beta") BETA
          span.beta-flag(v-if="beta4") V4 BETA
          span.alpha-flag(v-if="alpha") ALPHA
          span.alpha-flag(v-if="alpha4") V4 ALPHA

  .right
    .actions
      button.button.button-lang
        .current {{ lang }}
        ul.dropdown
          li.lang(:class="{active:isActiveLang(l)}" @click="setLang(l)" v-for="l in locales") {{ l | translate }}
      button.button.button-profile
        i.fp4.fp4-gear
        ul.dropdown(v-if="links.organization")
          li.organization.organization-name
            a.no-flex(:href="links.organization")
              .organization-container
                .organization-name {{ config.ORGANIZATION_NAME }}
                .dataplants-length(v-if="dataplantsList && dataplantsList.length") {{ dataplantsList.length }} {{ `Dataplant${dataplantsList.length > 1 ? 's' : ''}` }}
                .dataplants-length(v-else) {{ $t('header.your_current_organization') }}
              .flex
                span {{ $t('header.my_organization') }}
          li.organization
            a(:href="`${links.organization}/members`")
              span {{ $t('header.my_organization_members') }}
          li.organization
            a(:href="`${links.organization}/member`")
              i.fp4.fp4-plus-grid-2
              span {{ $t('header.my_organization_member') }}
          li.organization
            a(:href="`${links.organization}/plan`")
              span {{ $t('header.my_organization_billing') }}
          li.control-center(v-if="config && config.ORGANIZATION_ID")
            a(:href="`cc/#/${config.ORGANIZATION_ID}`")
              span {{ $t('header.control_center') }}
      button.button.button-help
        i.fp4.fp4-circle-info-small
        ul.dropdown
          li.docs
            a(:href="links.docs" target="blank" v-if="links.docs")
              i.fp4.fp4-book
              span {{ $t('header.docs') }}
          li.status
            a(:href="links.status" target="blank" v-if="links.status")
              i.fp4.fp4-heart-pulse
              span {{ $t('header.status') }}
          li.changelogs
            a(href="https://docs.forepaas.io/#/en/changelog" target="blank")
              i.fp4.fp4-arrows-rotate
              span {{ $t('header.changelog') }}
          li.roadmap
            a(href="#/features" target="blank")
              i.fp4.fp4-rocket
              span {{ $t('header.roadmap') }}
          li.support-link(v-if="config && config.ORGANIZATION_ID")
            a(:href="`#/support/${config.ORGANIZATION_ID}`" target="blank")
              i.fp4.fp4-life-ring
              span {{ $t('header.support') }}
      button.button.button-user
        img.profile-picture(
          :src="picture"
        )
        ul.dropdown
          li.profile-container
            a(v-if="links.profile" :href="links.profile")
              .profile-info(v-if="localProfile")
                img.profile-picture(:src="picture")
                span {{ localProfile.given_name }} {{ localProfile.family_name }}
              .profile-info(v-else)
                i.fp4.fp4-circle-user
                span {{ $t('header.my_profile') }}
          li.account
            a(v-if="links.account" :href="links.account")
              span {{ $t('header.my_account') }}
          li.logout
            span(@click="logout") {{ $t('header.logout') }}
</template>

<script>
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import Config from '@/shared/Config'
import ServiceSelector from './ServiceSelector.vue'

export default {
  components: {
    ServiceSelector
  },
  props: {
    service: { type: String, default: null },
    info: { type: Object, default: () => ({}) },
    image: { type: String, default: null },
    icon: { type: String, default: null },
    beta: { type: Boolean, default: false },
    beta4: { type: Boolean, default: false },
    alpha: { type: Boolean, default: false },
    alpha4: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null,
      localProfile: null,
      picture: require('@/core/assets/img/default_avatar.png'),
      dataplantsList: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.KING_SESSION || this.$store.getters.SESSION
    },
    organizationName () {
      return this.config?.ORGANIZATION_NAME || this.info?.organizationName
    },
    dataplantName () {
      return this.config?.DATAPLANT_NAME || this.info?.dataplantName
    },
    dataplantMode () {
      return this.config?.DATAPLANT_MODE || false
    },
    locales () { return this.$i18n.locales() },
    lang () { return this.$i18n.locale().toUpperCase() },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS,
        support: this.config?.SUPPORT,
        profile: this.config?.FPUI ? `${this.config.FPUI}/#/user` : null,
        account: this.config?.FPUI ? `${this.config.FPUI}/#/accounts` : null,
        organization: this.config?.FPUI ? `${this.config.FPUI}/#/organization/${this.config.ORGANIZATION_ID}` : null,
        features: this.config?.FPUI ? `${this.config.FPUI}/#/features` : null,
        return: this.returnUrl
      }
    },
    returnUrl () {
      if (!this.config?.DATAPLANT_ID) return null
      return `${window.location.origin}/#/dataplant/${this.config?.DATAPLANT_ID}`
    },
    returnToOverview () {
      if (!this.config?.DATAPLANT_ID || !this.dataplantName) {
        if (this.service === 'cc') return `${window.location.origin}/${this.service}/#/${this.config?.ORGANIZATION_ID}`
        return window.location.origin
      }
      return `${window.location.origin}/${this.service}/#/${this.config?.DATAPLANT_ID}`
    }
  },
  created () {
    if (this.user && this.user.profile) {
      this.localProfile = _cloneDeep(this.user.profile.find(p => p.main) || this.user.profile[0])
      if (this.localProfile.picture) this.picture = this.localProfile.picture
    }
  },
  async mounted () {
    moment.locale(this.lang)
    this.config = await Config()

    // We do not want this call because it is too heavy. To replace in the future
    // try {
    //   if (!this.dataplantsList) this.dataplantsList = await this.$api.FPAPI.dataplants.list(this.config.ORGANIZATION_ID)
    // } catch (err) {}
  },
  methods: {
    setLang (lang) {
      this.$i18n.set(lang)
      moment.locale(lang)
      window.localStorage.setItem('hq_lang', lang)
    },
    isActiveLang (lang) {
      return lang === this.lang.toLowerCase()
    },
    logout () {
      if (this.service === 'cc') this.$store.dispatch('LOGOUT_CMP')
      else this.$store.dispatch('LOGOUT')
    }
  }
}
</script>

<style lang='less'>
// Style in src/shared/styles/header.less
</style>
