<template lang='pug'>
  fpui-input-text.fpui-input-password(
    :name='name'
    :placeholder="placeholder"
    type="password"
    :color='color'
    v-model='model'
    :error='error'
    :error-message='errorMessage'
    :disabled="disabled"
    :autofocus='autofocus'
    :with-padding='withPadding'
    :label-size='labelSize'
    :label='label'
    :required='required'
    :helper='helper'
    :helper-title='helperTitle'
    @click="$emit('click', $event)"
  )
</template>

<script>

export default {
  props: {
    name: { type: String, default: '' },
    value: { type: String, required: true, default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    autofocus: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    label: { type: String, default: '' },
    labelSize: { type: Number, default: 3 },
    withPadding: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  }
}
</script>
