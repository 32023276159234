import { API } from '@/shared/plugins/Api/API'
import Accounts from './Accounts'
import Authentications from './Authentications'
import Organizations from './Organizations'
import Users from './Users'

class KING extends API {
  constructor (id) {
    super(id, true)
    this.organizations = new Organizations(id)
    this.users = new Users(id)
    this.accounts = new Accounts(id)
    this.authentications = new Authentications(id)
  }

  async refresh (options = {}) {
    const data = await this.request({
      method: 'POST',
      url: 'v1/refresh',
      options
    })
    return data
  }

  async getToken (authenticationId) {
    const { token } = await this.request({
      method: 'GET',
      url: `v1/users/me/token/${authenticationId}`
    })
    return token
  }

  switchDataplant (dataplant, organizationTo) {
    return this.request({
      method: 'POST',
      url: 'v1/organizations/dataplants/send/switch',
      data: {
        dataplant: {
          id: dataplant.dataplant_id,
          name: dataplant.name
        },
        to: organizationTo,
        from: dataplant.organization_id
      }
    })
  }


  async forgotPassword (email) {
    const data = await this.request({
      method: 'GET',
      url: `/v1/password_forget/${email}/question`
    })
    return data
  }

  async forgotPasswordWithQuestion (email, id, answer) {
    const data = await this.request({
      method: 'POST',
      url: '/v1/password_forget',
      data: {
        id,
        answer,
        email
      }
    })
    return data
  }

  async checkEmail (email) {
    const data = await this.request({
      method: 'POST',
      url: '/v1/check_email',
      data: {
        email
      }
    })
    return data
  }

  async destroyAccount () {
    return await this.request({
      method: 'DELETE',
      url: 'v1/users/me'
    })
  }

  async resetPassword (token, password) {
    const data = await this.request({
      method: 'POST',
      url: `/v1/password_reset/${token}`,
      data: {
        password
      }
    })
    return data
  }

  async resetMFA (data) {
    return await this.request({
      method: 'POST',
      url: '/v1/mfa/lost',
      data
    })
  }

  async signup (data) {
    return this.request({
      method: 'POST',
      url: '/v1/users',
      data
    })
  }

  async validateEmail (data) {
    return this.request({
      method: 'POST',
      url: '/v1/validations/email',
      data
    })
  }
}

export default KING
