<template lang="pug">
.query-filter
  .filter-container(
    v-if="filterList.length"
    v-for="(f, i) in filterList"
  )
    .text {{ i === 0 ? $t('query.filter.on') : $t('query.filter.and') }}
    fpui-input-select.filter-select(
      :clearable="false",
      :value="f.attribute"
      :options="attributesOptions"
      :disabled="readOnly"
      :width-fit="false"
      @input="v => update(i, v, 'attribute')"
    )
    type-selector(
      :value="$t(`query.filter.cond.${f.filter}`)"
      :typesWithLabel="condsOptions(f.attribute)"
      :read-only="readOnly"
      @input="v => update(i, v, 'filter')"
    )
    fpui-input-text(
      v-if='conds[f.filter] === "text"'
      :value="f.value"
      :disabled="readOnly"
      :placeholder="getPlaceholder(f)"
      @input="v => update(i, v, 'value')"
    )
    fpui-input-tags(
      v-if='conds[f.filter] === "tags"'
      :value="f.value"
      :disabled="readOnly"
      :placeholder="getPlaceholder(f)"
      @input="v => update(i, v, 'value')"
    )
    fpui-input-number(
      v-if='conds[f.filter] === "number"'
      :value="f.value"
      :disabled="readOnly"
      :placeholder="getPlaceholder(f)"
      @input="v => update(i, v, 'value')"
    )
    .multiple-values(v-if='conds[f.filter] === "text-2"')
      fpui-input-text(
        :value="f.value[0]"
        :disabled="readOnly"
        :placeholder="getPlaceholder(f)[0]"
        @input="v => updateMultiple(i, v, 0)"
      )
      span {{ $t('query.filter.to') }}
      fpui-input-text(
        :value="f.value[1]"
        :disabled="readOnly"
        :placeholder="getPlaceholder(f)[1]"
        @input="v => updateMultiple(i, v, 1)"
      )
    i.fp4.fp4-trash-can(
      :class="{ disabled: readOnly }"
      @click="remove(i)"
    )
  fpui-button.add-filter(
    color="grey"
    reverse
    icon-left
    noshadow
    unbordered
    autoWidth
    icon="fp4 fp4-plus"
    :disabled="!attributes.length || readOnly"
    @click="addFilter()"
  ) {{ $t('query.filter.add') }}
</template>

<script>
import _uniq from 'lodash/uniqBy'
import _cloneDeep from 'lodash/cloneDeep'
import TypeSelector from './Selector/TypeSelector'

export default {
  components: { TypeSelector },
  props: {
    filter: { type: Object, default: () => { return {} } },
    attributes: { type: Array, default: () => { return [] } },
    configuration: { type: Object, default: () => { return {} } },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      state: false,
      conds: {
        equal: 'text',
        not_equal: 'text',
        in: 'tags',
        not_in: 'tags',
        gt: 'text',
        gte: 'text',
        lte: 'text',
        lt: 'text',
        between: 'text-2',
        not_between: 'text-2',
        is_null: null,
        is_not_null: null,
        like: 'text',
        not_like: 'text'
      }
    }
  },
  computed: {
    attributesOptions () {
      return this.attributes.map(a => ({ label: a, value: a }))
    },
    filterList () {
      const filter = []
      for (const key in this.filter) {
        for (const cond in this.filter[key]) {
          let value = ''
          if (this.filter[key][cond].length && !['tags', 'text-2'].includes(this.conds[cond])) value = this.filter[key][cond][0]
          else if (['tags', 'text-2'].includes(this.conds[cond])) value = this.filter[key][cond] || []

          filter.push({
            attribute: key,
            filter: cond,
            value
          })
        }
      }

      return filter
    },
    metasTables () {
      return this.$store.getters.DWH_METAS_TABLES
    }
  },
  methods: {
    getPlaceholder (filter) {
      let type = 'txt'
      if (this.configuration.table_name) {
        const attributes = this.metasTables.find(table => table.name === this.configuration.table_name)?.attributes || {}
        const attributeKey = Object.keys(attributes).find(att => attributes[att].name === filter.attribute)
        if (attributes[attributeKey]) type = attributes[attributeKey].type?.main
      } else {
        const tablesWithAttribute = this.$store.getters.DWH_TABLES_BY_ATTRIBUTE_NAME_BY_NAME(filter.attribute)?.tables
        let types = []
        if (tablesWithAttribute) {
          this.metasTables.forEach(table => {
            if (tablesWithAttribute.includes(table.name)) {
              const attributesFromTable = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(table?._id)
              const attribute = attributesFromTable.find(att => att.name === filter.attribute)
              types = types.concat(attribute?.type?.main)
            }
          })
        }

        types = _uniq(types.filter(t => t))
        type = types.length === 1 ? types[0] : null
      }

      if (['equal', 'not_equal', 'gt', 'gte', 'lt', 'lte'].includes(filter.filter)) {
        switch (type) {
          case 'txt':
            return 'e.g. Female'
          case 'num':
            return 'e.g. 15.2'
          case 'bool':
            return 'e.g. true'
          case 'date':
            return 'e.g. 2020-02-24 11:25:21'
          case 'datetime':
            return 'e.g. 2020-02-24 11:25:21'
          default:
            return 'e.g. Female'
        }
      }
      if (['between', 'not_between'].includes(filter.filter)) {
        switch (type) {
          case 'txt':
            return ['q', 'w']
          case 'num':
            return ['15.2', '20']
          case 'bool':
            return ['', '']
          case 'date':
            return ['2020-02-24', '2020-02-28 11:30:00']
          case 'datetime':
            return ['2020-02-24', '2020-02-28 11:30:00']
          default:
            return ['q', 'w']
        }
      }
      if (['like', 'not_like'].includes(filter.filter)) {
        return 'e.g. %tion'
      }
      if (['in', 'not_in'].includes(filter.filter)) {
        return 'Press Enter after each value'
      }
    },
    update (index, value, type) {
      const newFilterList = _cloneDeep(this.filterList)
      newFilterList[index][type] = value

      const newFilter = {}
      newFilterList.forEach(f => {
        newFilter[f.attribute] = newFilter[f.attribute] || {}
        newFilter[f.attribute][f.filter] = ['tags', 'text-2'].includes(this.conds[f.filter])
          ? ((f.value && Array.isArray(f.value)) ? f.value.filter(v => v) : [f.value])
          : [Array.isArray(f.value) ? f.value[0] : f.value]
      })
      this.$emit('update', { key: 'filter', value: newFilter })
    },
    updateMultiple (index, value, arrayIndex) {
      const newFilterList = _cloneDeep(this.filterList)
      if (!newFilterList[index].value || !Array.isArray(newFilterList[index].value)) newFilterList[index].value = []
      newFilterList[index].value[arrayIndex] = value

      const newFilter = {}
      newFilterList.forEach(f => {
        newFilter[f.attribute] = newFilter[f.attribute] || {}
        newFilter[f.attribute][f.filter] = ['tags', 'text-2'].includes(this.conds[f.filter]) ? ((f.value && Array.isArray(f.value)) ? f.value : []) : f.value
      })
      this.$emit('update', { key: 'filter', value: newFilter })
    },
    addFilter () {
      const unSelectedCondsByAttribute = this.unSelectedCondsByAttribute(this.attributes[0])
      const newFilterList = _cloneDeep(this.filterList)
      newFilterList.push({
        attribute: this.attributes[0],
        filter: unSelectedCondsByAttribute[0],
        value: ''
      })

      const newFilter = {}
      newFilterList.forEach(f => {
        newFilter[f.attribute] = newFilter[f.attribute] || {}
        newFilter[f.attribute][f.filter] = ['tags', 'text-2'].includes(this.conds[f.filter]) ? ((f.value && Array.isArray(f.value)) ? f.value : []) : [f.value]
      })

      this.$emit('update', { key: 'filter', value: newFilter })
    },
    remove (index) {
      if (this.readOnly) return

      const newFilterList = _cloneDeep(this.filterList)
      newFilterList.splice(index, 1)

      const newFilter = {}
      newFilterList.forEach(f => {
        newFilter[f.attribute] = newFilter[f.attribute] || {}
        newFilter[f.attribute][f.filter] = ['tags', 'text-2'].includes(this.conds[f.filter])
          ? ((f.value && Array.isArray(f.value)) ? f.value : [])
          : [f.value]
      })

      this.$emit('update', { key: 'filter', value: newFilter })
    },
    unSelectedCondsByAttribute (attribute) {
      return Object.keys(this.conds).filter(t => !Object.keys(this.filter[attribute] || {}).includes(t))
    },
    condsOptions (attribute) {
      return Object.keys(this.conds).map(type => {
        return {
          label: this.$t(`query.filter.cond.${type}`),
          value: type,
          disabled: Object.keys(this.filter[attribute] || {}).includes(type)
        }
      })
    }
  }
}
</script>
<style lang="less">
.query-filter {
  .filter-container {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    .text {
      margin-right: 10px;
      color: @grey-chart;
      font-size: 14px;
    }
    .filter-select {
      width: 100%;
      min-width: 150px;
      .dropdown {
        ul {
          max-height: 200px;
        }
      }
    }
    .type-selector {
      min-width: 130px;
      margin-right: 10px;
      .values {
        height: 200px;
        overflow: auto;
      }
    }
    .fpui-input-text, .fpui-input-number, .fpui-input-tags {
      margin-right: 10px;
      width: 100%;
    }
    .fpui-input-tags {
      .ti-tag {
        padding: 0 5px;
        margin: 3px 5px 5px;
        border-radius: 6px;
      }
    }
    .fp4-trash-can {
      font-size: 20px;
      color: #B2BECA;
      cursor: pointer;
      &:hover {
        color: @red;
      }
      &.disabled {
        cursor: not-allowed;
        color: #dedede;
      }
    }
    .multiple-values {
      display: flex;
      align-items: center;
      .fpui-input-number {
        margin-right: initial;
      }
      > span {
        margin: 0 5px;
        color: @grey-chart;
        font-size: 14px;
      }
    }
  }

  @media (min-width: 1350px) and (max-width: 1400px) {
    .filter-container {
      .filter-select {
        max-width: 150px;
      }
    }
  }
}
</style>
