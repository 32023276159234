import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'


class Meta extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by'
    ])
    return obj
  }
}

class Metas extends API {
  async getMetas (params = {}) {
    const response = await this.request({
      method: 'get',
      url: '/metas',
      params
    })
    return response.metas
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Meta(item)
  }
}

export default Metas
export {
  Meta,
  Metas
}
