<template lang='pug'>
  .fpui-sql-editor
    monaco-editor.editor(
      ref="editor"
      v-model="model"
      language="sql"
      :read-only='readOnly'
      :class="{active:!expand || expanded}"
      @customRun="$emit('customRun')"
    )
    .inline(
      v-show="expand && !expanded"
      v-html="inlineValue"
      @click="open"
    )
</template>

<script>
import MonacoEditor from './MonacoEditor'
import Prism from 'prismjs'
import 'prismjs/components/prism-sql.js'

export default {
  components: {
    MonacoEditor
  },
  props: {
    expand: {
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    inlineOnly: {
      default: false,
      type: Boolean
    },
    value: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    inlineValue () {
      return Prism.highlight((this.value || '').replace(/\n/g, ' '), Prism.languages.sql, 'sql')
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if ($event && this.isParent($event.target)) {
        return
      }
      this.expanded = false
      this.$emit('toggle', false)
    },
    open () {
      if (this.inlineOnly) return
      this.expanded = true
      process.nextTick(_ => {
        if (this.$refs.editor) this.$refs.editor.focus()
      })
      this.$emit('toggle', true)
    }
  }
}
</script>

<style lang='less'>
  .fpui-sql-editor {
    background: #3e4550;
    overflow-x: hidden;
    .inline {
      cursor: text;
      color: #e9ecf0;
      min-height: 34px;
      padding: 6px 15px 5px;
      line-height: 21px;
      outline: none;
      z-index:2;
      position: relative;
    }
    .editor {
      opacity:0;
      height: 1px;
      z-index:1;
      position: absolute;
      &.active {
        position: relative;
        height: auto;
        z-index:3;
        opacity:1;
      }
    }
  }
</style>
