import { API } from '@/shared/plugins/Api/API'

class Authentications extends API {
  constructor (ID) {
    super(ID, true)
  }

  async list () {
    const authentications = await this.request({
      method: 'GET',
      url: 'v1/authentications'
    })
    return authentications
  }
}

export default Authentications
