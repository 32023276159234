import Queries from './Queries'
import Dashboards from './Dashboards'
import Repositories from './Repositories'
import Resources from './Resources'
import QueriesHistory from './QueriesHistory'
import API from '@/shared/plugins/Api/API'

class QUERY_ADMIN extends API {
  constructor (id) {
    super(id)
    this.queries = new Queries(id)
    this.dashboards = new Dashboards(id)
    this.repositories = new Repositories(id)
    this.queriesHistory = new QueriesHistory(id)
    this.resources = new Resources(id)
  }

  fetchRepositoryBranches (url) {
    return this.request({
      method: 'GET',
      url: '/v3/git/branches/list',
      params: {
        url: url
      }
    })
  }

  fetchPublicKey () {
    return this.request({
      method: 'GET',
      url: '/v3/git/public_key'
    })
  }
}

export default QUERY_ADMIN
