import Config from '@/shared/Config'
import Store from '@/shared/store'
import _get from 'lodash/get'
import Vue from 'vue'
export default {
  state: {
    socketId: null
  },
  mutations: {
    SET_SOCKET_ID (state, socketId) {
      state.socketId = socketId
    }
  },
  actions: {
    async WEBSOCKET_LOAD (_, name) {
      const config = await Config()
      if (_get(config, `WS.${name}`)) {
        const onEvent = (event) => {
          const actionName = event.type.toUpperCase().replace(/\./g, '_')
          event.data.__socketId = event.fpsocketid
          Store.commit(actionName, event.data)
        }
        const onConnect = (_) => {
          Vue.WebSockets.send(name, {
            method: 'subscribe',
            data: {
              channel: 'events'
            }
          })
          // That kind of event will launch store dispatch
          Vue.WebSockets.unsubscribe(`${name.toLowerCase()}-event`, onEvent)
          Vue.WebSockets.subscribe(`${name.toLowerCase()}-event`, onEvent)
        }
        await Vue.WebSockets.init(name, onConnect)
      }
    },

    async WEBSOCKET_INIT ({ dispatch }) {
      dispatch('WEBSOCKET_LOAD', 'DPE')
      dispatch('WEBSOCKET_LOAD', 'IAM')
      dispatch('WEBSOCKET_LOAD', 'ML')
      dispatch('WEBSOCKET_LOAD', 'DWH')
    },

    async WEBSOCKET_CLOSE () {
      Vue.WebSockets.close()
    }
  },
  getters: {
    SOCKET_ID (state) {
      return state.socketId
    }
  }
}
