<template lang='pug'>
.fpui-modal
  slot

</template>

<script>

export default {
  name: 'FpuiModal'
}
</script>

<style lang='less'>
  @import "~@/shared/styles/_variables.less";

  .fpui-modal {
    height: 100%;
  }
</style>
