/* eslint-disable no-case-declarations */
const { Parser } = require('node-sql-parser')
const parser = new Parser()
const stream = require('readable-stream')
const { PassThrough } = stream

/**
 * Query
 *
 * A standard interface to allow an input to be executed as a query and return an output
 */
class Query {
  /**
   * get sql
   * A standard method that should resolve a request as an SQL syntax
   */
  get sql () {
    throw new Error('sql must be implemented in the QueryFormatter class')
  }

  get formatedSql () {
    return this.sql
      .split('\n').filter(l => l).join(' ')
      .split(' ').filter(c => c).join(' ')
  }

  /**
   * get ast
   * An internal method to resolve a parsed sql query
   */
  get ast () {
    // The library of sql handle only LIMIT OFFSET in this order
    const sql = this.reverseOffsetLimit(this.sql, 'LIMIT', 'OFFSET')
    const ast = parser.astify(sql)
    if (Array.isArray(ast)) return ast[0]
    return ast
  }

  /**
   * toSync
   * Internal : Allow to convert the standard stream response to a raw data response
   */
  async toSync () {
    let data = []
    await new Promise((resolve, reject) => {
      this._data.on('data', (d) => {
        for (let i = 0; i < d.length; i += 10_000) {
          const t = d.slice(i, i + 10_000)
          for (let idx = 0; idx < t.length; idx++) {
            t[idx].id = idx
          }
          data = data.concat(t)
        }
      })
      this._data.on('end', async () => {
        this._data = data
        resolve()
      })
      this._data.on('error', (err) => {
        reject(err)
      })
    })
  }

  /**
   * reverseOffsetLimit
   * Reverse order of LIMIT OFFSET, to OFFSET LIMIT
   * @param String      sql             A sql request
   * @param String      first           The first value to display in the result
   * @param String      second          The second value to display in the result
  */
  reverseOffsetLimit (sql, first = 'OFFSET', second = 'LIMIT') {
    const offsets = sql.split(first)
    if (offsets.length === 1) return sql
    const limits = offsets[0].split(second)
    if (limits.length === 1) return sql
    return `${limits[0]} ${first} ${offsets[1]} ${second} ${limits[1]}`
  }

  /**
   * setLimitFromDisplay
   * If limit is set as an optional parameter, it replace the value from the request (only if < to the current value)
   * @param Object      ast             A parsed sql with the library node-sql-parser
  */
  setLimitFromDisplay (ast) {
    if (this.limit) {
      const prevLimit = ast.limit?.value?.[0]?.value
      if (!prevLimit || this.limit < prevLimit) {
        ast.limit = {
          separator: '',
          value: [{
            type: 'number',
            value: this.limit
          }]
        }
      }
    }
    return ast
  }

  /**
   * run
   * Allow to run the query in a specific context
   * @param Dataplant   context         A context is a dataplant, where the query will be run
   * @param Object      options         An optional parameter
   * @param Boolean     options.stream  If set to true, it will return an object with metadata and a stream instead of the result directly
   */
  async run (context, options = {}) {
    let sql = ''
    this._data = new PassThrough({ objectMode: true })
    try {
      let ast = this.ast
      ast = this.setLimitFromDisplay(ast)
      sql = parser.sqlify(ast, { database: 'BigQuery' }) // BigQuery seem to be the most closer with prestodb
    } catch (error) {
      // DM-2452: Temporarily avoid raising error when user specifies CATALOG.SCHEMA.TABLE in his query
      // And the problem is still existing in the Parser of node-sql-parser
      console.error(error.stack)
      sql = this.sql.toString()
    }
    await this.execute(context, sql)

    if (!options.stream) {
      await this.toSync()
    }
    return this.display()
  }

  /**
   * execute
   * Internal, launch the query, and stream it or not with a recursive loop
   * @param Dataplant   context         A context is a dataplant, where the query will be run
   * @param String      data            A SQL request
   */
  async execute (context, data) {
    const options = {
      url: 'v1/statement',
      method: 'POST',
      data,
      query_id: this.query_id,
      dashboard_id: this.dashboard_id || null,
      source: this.source
    }

    let response = await context.requestTrino(options)

    try {
      response = await this.parse(context, response, true)
      this.parse(context, response)
        .then(() => {
          this._data.end()
        })
        .catch(err => {
          this._data.emit('error', err)
        })
    } catch (err) {
      this._data.emit('error', err)
      this._data.end()
    }
  }

  /**
   * executeURL
   * Internal, in the recursive loop, trino will respond a potential nextURL, this method is call to execute that url and parse result
   * @param Dataplant   context         A context is a dataplant, where the query will be run
   * @param String      url             An URL to trino
   * @param Boolean     waitColumns     A boolean to avoid releasing the promise without the column, the stream will be return with the standard metadata only when column will be defined
   */
  async executeUrl (context, url, waitColumns) {
    const data = await context.requestTrino({
      baseURL: null,
      url,
      method: 'GET',
      data: this.sql,
      query_id: this.query_id,
      dashboard_id: this.dashboard_id || null,
      source: this.source
    })
    return await this.parse(context, data, waitColumns)
  }

  /**
   * later
   * Internal, a tool method to be able to easy wait some ms in code (like a sleep)
   * @param Integer   ms    A time in millisecond to wait
   */
  async later (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  /**
   * parse
   * Internal, A method to parse the result of trino
   * @param Dataplant   context         A context is a dataplant, where the query will be run
   * @param Object      data            A trino answer
   * @param Boolean     waitColumns     A boolean to avoid releasing the promise without the column, the stream will be return with the standard metadata only when column will be defined
   */
  async parse (context, data, waitColumns = false) {
    if (data.stats.state === 'FAILED') {
      const error = new Error(data.error.message)
      error.stack = JSON.stringify(data.error)
      throw error
    }

    if (data.columns) this.columns = data.columns
    if (waitColumns && data.columns) {
      return data
    }
    if (data.data) {
      this._data.write(data.data.map(row => this.formatRow(row)))
    }
    await this.later(10)
    if (data.nextUri) {
      return await this.executeUrl(context, data.nextUri, waitColumns)
    }
  }

  /**
   * toJSON
   * An interface to fill with a render method for the query
   */
  toJSON () {
    throw new Error('toJSON must be implemented in the QueryFormatter class')
  }

  /**
   * display
   * An interface to fill with a render method for the response
   */
  display () {
    throw new Error('display must be implemented in the QueryFormatter class')
  }

  /**
   * formatRow
   * An optional interface to fill with a render for each row of the result
   */
  formatRow (data) {
    return data
  }
}

module.exports = Query
