import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import moment from 'moment'

class Ticket extends APIObject {
  constructor (options) {
    // Init
    super('SUPPORT_API', options, true, true)
    this.updateInfo(options)
  }

  updateInfo (options) {
    this.attachments = options.attachments || []
    this.author = options.author || ''
    this.subject = options.subject || ''
    this.body = options.body || ''
    this.contacts = options.contacts || []
    this.created_at = options.created_at
    this.updated_at = options.updated_at
    this.state = options.state || 'open'
    this.messages = options.messages || []
    this.meta = options.meta || {}
    this.read = typeof options.read === 'undefined' ? true : options.read
  }

  get duration () {
    return moment.duration(moment(this.created_at * 1000) - moment()).humanize(true)
  }

  _filter (object) {
    return _omit(super._filter(object), [
      'id',
      'created_by',
      'updated_by'
    ])
  }

  async load () {
    const item = await this.request({
      timeout: 60000,
      method: 'GET',
      url: `v1/support/tickets/${this.id}`
    })
    item.read = true
    this.updateInfo(item)
  }

  async reply (body, attachments) {
    const message = await this.request({
      timeout: 60000,
      method: 'PUT',
      url: `v1/support/tickets/${this.id}/reply`,
      data: {
        body,
        attachments
      }
    })
    this.messages.push(message)
  }

  async addContacts (emails) {
    await Promise.all(emails.map(async email => {
      await this.request({
        timeout: 60000,
        method: 'POST',
        url: `v1/support/tickets/${this.id}/contacts/${email}`
      })
      this.contacts.push({
        email: email
      })
    }))
  }

  async create () {
    return super.create({
      timeout: 60000,
      method: 'POST',
      url: 'v1/support/tickets'
    })
  }

  async save () {
    return super.save({
      timeout: 60000,
      method: 'PUT',
      url: `v1/support/tickets/${this.id}`
    })
  }

  toJSON () {
    return {
      state: this.state,
      subject: this.subject,
      body: this.body,
      meta: this.meta,
      contacts: this.contacts,
      attachments: this.attachments
    }
  }
}

class Support extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Ticket(item)
  }

  async list (state) {
    const tickets = await this.request({
      timeout: 60000,
      method: 'GET',
      url: 'v1/support/tickets',
      params: {
        state
      }
    })
    return tickets.map(ticket => {
      return new Ticket(ticket)
    })
  }
}

export default Support
