import Config from '@/shared/Config'
import Store from '@/shared/store'
import _get from 'lodash/get'
import Vue from 'vue'

class WebSockets {
  constructor () {
    this.sockets = {}
    this.listeners = {}
    this.WEBSOCKET_PING_PONG_INTERVAL = 5000
  }

  async ping (clientName) {
    this.send(clientName, {
      method: 'ping'
    })
  }

  /* istanbul ignore next */
  async init (name, onConnect = null) {
    console.info(`InitSocket::${name}`)
    const config = await Config()
    const client = new WebSocket(config[name + '_WS'])
    this.sockets[name] = new Promise(resolve => {
      client.onmessage = (event) => {
        let message
        try {
          message = JSON.parse(event.data)
        } catch (_) {
          message = event.data
        }
        if (message.type === 'event' && message.event === 'connected') {
          try {
            const SocketId = _get(message, 'data.socket_id')
            if (SocketId) Store.commit('SET_SOCKET_ID', SocketId)
          } catch (err) {
            console.error(err.stack)
          }
          this.ping(name)
          if (onConnect) onConnect(client)
          return resolve(client)
        }
        if (message.type === 'result' && message.data && message.data.type === 'PONG') {
          client.pingTimeout = setTimeout(_ => this.ping(name), this.WEBSOCKET_PING_PONG_INTERVAL)
        }
        if (message.event) {
          if (this.listeners[message.event]) this.listeners[message.event].forEach(l => l(message.data))
        }
      }
      client.onclose = (event) => {
        if (client.pingTimeout) clearTimeout(client.pingTimeout)
        // We add a timer to reconnect only after a delay (to prevent loop of reconnect)
        setTimeout(_ => {
          this.init(name, onConnect)
        }, 5000)
      }
    })
  }

  async close () {
    for (const name in this.sockets) {
      console.info(`CloseSocket::${name}`)
      const client = await this.sockets[name]
      client.onclose = () => { /* ignore this stop event */ }
      client.close()
      delete this.sockets[name]
    }
  }

  async subscribe (event, callback) {
    this.listeners[event] = this.listeners[event] || []
    this.listeners[event].push(callback)
  }

  async unsubscribe (event, callback) {
    this.listeners[event] = this.listeners[event] || []
    const idx = this.listeners[event].indexOf(callback)
    if (idx !== -1) this.listeners[event].splice(idx, 1)
    if (!this.listeners[event].length) delete this.listeners[event]
  }

  async send (name, message) {
    if (!this.sockets[name]) return
    const client = await this.sockets[name]
    client.send(JSON.stringify(message))
  }
}
Vue.use({
  install (Vue) {
    Vue.WebSockets = new WebSockets()
  }
})
