/* eslint-disable camelcase */
const Metas = require('./Metas')
const axios = require('axios')
const _merge = require('lodash/merge')
const moment = require('moment')

let cache = null

/**
 * Dataplant
 *
 * Dataplant can be use as a context to execute API Calls, or run a query
 */
class Dataplant {
  /**
   * constructor
   * Allow to create a new dataplant context
   *
   * @param   String  dataplant_url   Base URL of the dataplant (ex : https://mydataplant.forepaas.io)
   * @param   Object  auth            Authentication object
   * @param   String  auth.token      [Optional]  can be use to authentify each request
   * @param   String  auth.apikey     [Optional]  can be use to authentify each request
   * @param   String  auth.secretkey     [Optional]  can be use to authentify each request
   */
  constructor (options) {
    this.dataplant_url = options.dataplant_url
    if (!this.dataplant_url) throw new Error('No dataplant_url set in Dataplant constructor')
    this.auth = options.auth || {}
    this.Metas = new Metas(this)
  }

  /**
   * get trino_url
   * Accessor to trino base url in dataplant
   * @return String Trino URL (ex : https://mydataplant-query.forepaas.io)
   */
  get trino_url () {
    const [baseURL, domain, root] = this.dataplant_url.split('.')
    const trinoUrl = `${baseURL}-query.${domain}.${root}`
    return trinoUrl
  }

  /**
   * get auths
   * Accessor to parameter of authentication
   * @return Object Formatted object for axios request with token
   */
  get auths () {
    if (this.auth.token) {
      return {
        headers: {
          Authorization: `Bearer ${this.auth.token}`
        }
      }
    }
    return {
      withCredentials: true
    }
  }

  /**
   * getTrinoHeaders
   * Return the trino additional header to send, usefull for history api
   * @param   String  queryId   The idenfifier of the query, if not set, there will be no header send
   * @param   String  source    The source idenfier, can be Analytics Manager, Explorer or anything else
   * @return  Object            An object to merge to trino request, axios format
   */
  getTrinoHeaders (queryId, source, dashboardId) {
    if (!queryId) return {}
    const tags = {
      query_id: queryId,
      dashboard_id: dashboardId,
      dataplant_id: this.auth.dataplant_id,
      organization_id: this.auth.organization_id,
      fprn: this.auth.fprn
    }
    const tagsStr = Object.keys(tags).reduce(function (a, k) { a.push(k + '=' + encodeURIComponent(tags[k])); return a }, []).join(',')

    return {
      headers: {
        'X-Trino-User': this.auth.uid,
        'X-Trino-Source': source,
        'X-Trino-Client-Tags': tagsStr
      }
    }
  }

  /**
   * login
   * Convert an apikey/secretkey to a valid token
   */
  async login () {
    if (cache && moment().utc(true).diff(moment(this.auth.token_expire).utc(true)) > 0) cache = null
    if (this.auth.apikey) {
      cache = cache || this.request({
        url: 'iam/v4/login',
        baseURL: this.dataplant_url,
        method: 'POST',
        data: {
          apikey: this.auth.apikey,
          secretkey: this.auth.secretkey,
          auth_mode: 'apikey'
        }
      }, true)
    } else {
      cache = cache || this.request({
        url: 'iam/v4/checksession',
        baseURL: this.dataplant_url,
        method: 'GET'
      }, true)
    }

    const { token, uid, fprn, dataplant_id, organization_id, token_expire } = await cache
    this.auth.token = token
    this.auth.uid = uid
    this.auth.fprn = fprn
    this.auth.dataplant_id = dataplant_id
    this.auth.organization_id = organization_id
    this.auth.token_expire = token_expire
  }

  /**
   * requestTrino
   * Request trino api
   * @param Object  options   A standard axios object, baseURL will be autoset
   */
  async requestTrino (options) {
    await this.login() // Do not remove, it is used for Trino Headers
    return await this.request(_merge({
      baseURL: this.trino_url
    }, this.getTrinoHeaders(options.query_id, options.source, options.dashboard_id), options))
  }

  /**
   * requestAPI
   * Request dataplant APIS
   * @param Object  options   A standard axios object, baseURL will be autoset
   */
  async requestAPI (options) {
    await this.login() // Do not remove, it is used for Trino Headers
    return await this.request(_merge({
      baseURL: this.dataplant_url
    }, this.getTrinoHeaders(options.query_id, options.source, options.dashboard_id), options))
  }

  /**
   * request
   * Internal request method, it will be use by the others
   * @param Object  options   A standard axios object, authentication will be added
   */
  async request (options, bypasslogin = false) {
    if (!bypasslogin) await this.login()
    const { data } = await axios(_merge(this.auths, options))
    return data
  }

  /**
   * get am
   * Get some method to simplify AM querying
   */
  get am () {
    return {
      /**
       * run
       * @param Query   Query           A query that can be created from SQLQuery, JSONQuery or custom query object
       * @param Object  options         An optional parameter
       * @param Boolean options.stream  If set to true, it will return an object with metadata and a stream instead of the result directly
       */
      run: async (Query, options) => {
        return await Query.run(this, options)
      }
    }
  }
}

Dataplant.AM = {
  JSONQuery: require('./Formatter/JSONQuery'),
  SQLQuery: require('./Formatter/SQLQuery'),
  DashboardJSONQuery: require('./Formatter/DashboardJSONQuery'),
  DashboardSQLQuery: require('./Formatter/DashboardSQLQuery')
}

module.exports = Dataplant
