<template lang="pug">
  span.fpui-table_cell-content(
    v-html="cellContent"
    v-tooltip="tooltip"
  )
</template>

<script>
export default {
  props: {
    column: { type: Object, default: () => ({}) }
  },
  computed: {
    tooltip () {
      if (this.column.tooltipHeader === false) return null
      switch (typeof (this.column.tooltipHeader)) {
        case 'string':
          return this.column.tooltipHeader
        case 'function':
          return this.column.tooltipHeader(this.row)
      }
      return null
    },
    cellContent () {
      switch (typeof (this.column.label)) {
        case 'string':
          return this.column.label
        case 'function':
          return this.column.label(this.row)
      }
      return '-'
    }
  }
}
</script>
<style lang="less">
</style>
