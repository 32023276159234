const _intersection = require('lodash/intersection')
const _minBy = require('lodash/minBy')
const _flatten = require('lodash/flatten')

let cacheDatabases = null
let cacheTables = null
let cacheAttributes = null
let cacheVirtualAttributes = null

/**
 * Metas
 *
 * MetaData loader from DWH component
 *
 */
class Metas {
  /**
   * constructor
   * The only parameter here will be internaly call
   * @param   Object  context   Context will be an instance of QueryBuilder object, giving access to dataplant api calls
   */
  constructor (context) {
    this.context = context
  }

  /**
   * findBestTableByAttributes
   * Get the best table from a list of attributes
   * It will select the table where there is the lower number of line, not empty, and where all attributes from the list are existing
   *
   * @param   Array<String>   attrs   List of attributes which need to exist in the table
   * @return  Object                  Return the table found as an object (definition of object related to DWH API)
   */
  findBestTableByAttributes (attrs = []) {
    let tables = _flatten(attrs
      .map(attr => {
        const vattr = this.virtual_attributes.find(vattr => vattr.name === attr)
        if (vattr) return vattr.required_attributes_names || []
        return attr
      }))
      .map(attr => {
        return this.attributes.filter(a => a.name === attr).map(a => a.table_id)
      })
    tables = _intersection(...tables)
    tables = this.tables.filter(table => {
      return tables.includes(table._id) && table.nb_rows > 0
    })

    if (!tables.length) throw new Error(`There is no table with this combination of attributes ${attrs}`)

    return _minBy(tables, 'nb_rows')
  }

  /**
   * load
   * Load all the databases, tables and attributes from dwh if not already loaded
   */
  async load () {
    if (!this.databases) {
      cacheDatabases = cacheDatabases || this.context.requestAPI({
        url: 'dwh/v4/databases'
      })

      const data = await cacheDatabases
      this.databases = data.filter(db => {
        return ['mart', 'prim', 'ml'].includes(db.level)
      })
    }

    if (!this.tables) {
      cacheTables = cacheTables || this.context.requestAPI({
        url: 'dwh/v4/tables',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheTables
      this.tables = data.filter(t => {
        return this.databases.map(db => db._id).includes(t.database_id)
      })
    }

    if (!this.attributes) {
      cacheAttributes = cacheAttributes || this.context.requestAPI({
        url: 'dwh/v4/attributes',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheAttributes
      this.attributes = data.filter(t => {
        return this.databases.map(db => db._id).includes(t.database_id)
      })
    }

    if (!this.virtual_attributes) {
      cacheVirtualAttributes = cacheVirtualAttributes || this.context.requestAPI({
        url: 'dwh/v4/virtual/attributes',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheVirtualAttributes
      this.virtual_attributes = data
    }
  }
}

module.exports = Metas
