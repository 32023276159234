<template lang='pug'>
.modal-footer
  .left-footer
    slot
  .footer-actions
    //- HACK: $parent.$parent is not ideal, let's find another solution
    fpui-button(
      v-for='(button, idx) in buttons'
      :key='idx'
      :color='button.type == "close" ? "white" : button.color'
      :icon="button.icon"
      v-if='buttonConditional(button.if)'
      :disabled='buttonConditional(button.disabled, false)'
      @click.once='button.handler ? button.handler.bind($parent.$parent)() : ""'
      :auto-width="button.autoWidth"
    ) {{ button.label || button.text }}
</template>

<script>

export default {
  props: {
    buttons: { type: Array, default: () => [] }
  },
  methods: {
    buttonConditional (parameter, fallback = true) {
      if (parameter !== undefined) {
        if (typeof parameter === 'function') return parameter()
        return parameter
      } else if (parameter === undefined) return fallback
      return parameter
    }
  }
}
</script>

<style lang='less'>
  .modal-footer {
    border-top: 1px solid rgba(151,167,183,0.21);
    padding: 14px 29px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    .left-footer {
      flex: 0 1 auto;
      align-self: auto;
    }

    .footer-actions {
      display: flex;
      flex: 0 1 100%;
      align-self: auto;
      justify-content: flex-end;
      .button-container:not(:first-child) {
        margin-left: 13px;
      }
    }
  }
</style>
