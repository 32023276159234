import Config from '@/shared/Config'
import API from '@/shared/plugins/Api/API'
import qs from 'qs'
import urljoin from 'url-join'
import ACLS from './ACLS'
import Applications from './Applications.js'
import AuthenticationProviders from './AuthenticationProviders.js'
import Configuration from './Configuration.js'
import Groups from './Groups.js'
import Mails from './Mails'
import Metrics from './Metrics.js'
import Roles from './Roles.js'
import ServiceAccounts from './ServiceAccounts.js'
import Users from './Users.js'

class IAM extends API {
  constructor (id) {
    super(id)
    this.metrics = new Metrics(id)
    this.authentication_providers = new AuthenticationProviders(id)
    this.roles = new Roles(id)
    this.applications = new Applications(id)
    this.users = new Users(id)
    this.groups = new Groups(id)
    this.configuration = new Configuration(id)
    this.service_accounts = new ServiceAccounts(id)
    this.mails = new Mails(id)
    this.acls = new ACLS(id)
    this.cacheAcls = {}
  }

  async getCacheKey (key) {
    if (!key) return null
    const config = await Config()
    return `${config.DATAPLANT_ID}::${key}`
  }

  async can (Service, Resource, Action, object) {
    const keyCache = await this.getCacheKey(!object && `${Service}::${Resource}::${Action}`)
    let data = null
    if (keyCache) {
      this.cacheAcls[keyCache] = this.cacheAcls[keyCache] || this.request({
        method: 'POST',
        url: 'v4/opa/query',
        data: {
          Service,
          Resource,
          Action
        }
      })
      data = await this.cacheAcls[keyCache]
    } else {
      data = await this.request({
        method: 'POST',
        url: 'v4/opa/query',
        data: {
          Service,
          Resource,
          Action,
          Object: object
        }
      })
    }

    return data.allow
  }

  async reply (token) {
    return this.request({
      url: 'v4/reply',
      params: {
        token_mode: 'cookie',
        authentication_provider: 'forepaas'
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  async checksession () {
    return this.request({
      url: 'v4/checksession',
      retries: 0
    })
      .catch(err => {
        if (err.response?.status === 401 || err.response?.status === 403) return null
        throw err
      })
  }

  async authenticationProvidersStore () {
    return this.request({
      url: 'v4/store/authentication_providers'
    })
  }

  async applicationsStore () {
    return this.request({
      url: 'v4/store/applications'
    })
  }

  async preferences (clientId = null) {
    const config = await Config()
    return this.request({
      url: `v4/preferences/${clientId || config.APP_ID}`
    })
  }

  async login (login, password, mfa, otp) {
    const config = await Config()
    return this.request({
      url: 'v4/login',
      method: 'POST',
      data: {
        auth_mode: 'login_password',
        login,
        password,
        mfa,
        otp,
        app_id: config.APP_ID
      }
    })
  }

  async passwordReset (login, email) {
    return this.request({
      url: 'v4/sendRenewRequest',
      method: 'POST',
      data: {
        login: login,
        email: email
      }
    })
  }

  async passwordRenew (oldPassword, newPassword) {
    return this.request({
      url: 'v4/passwordChange',
      method: 'POST',
      data: {
        password: newPassword,
        oldpassword: oldPassword
      }
    })
  }

  async unblock (id) {
    return this.request({
      method: 'PUT',
      url: `v4/users/${id}/unblock`
    })
  }

  async loginRedirect (loginMode, forceAuth = false) {
    const config = await Config()
    const queryString = qs.stringify({
      authentication_provider: loginMode._id,
      app_id: config.APP_ID,
      redirect_uri: window.location.href,
      token_mode: 'cookie',
      force_auth: forceAuth
    })
    const loginUrl = urljoin(config.IAM, '/v4/login', `?${queryString}`)
    window.location.href = loginUrl
  }

  async sendMFACode (type, login, password) {
    return this.request({
      method: 'post',
      url: `v4/sendMFA/${type}`,
      data: {
        login,
        password
      }
    })
  }

  async logout () {
    return this.request({
      method: 'delete',
      url: 'v4/logout'
    })
      .catch(err => {
        if (err.response.status === 400) return null
        throw err
      })
  }
}

export default IAM
