<template lang='pug'>
  .fpui-input-fpu
    .instances.item
      label {{'fpui-input-fpu.instances' | translate}}
        i.fa.fa-chevron-right(
          @click="instances++"
          v-if="!disabled.instances"
        )
        i.fa.fa-chevron-left(
          @click="instances--"
          v-if="!disabled.instances"
        )
      fpui-input-text.input(
        :disabled="disabled.instances"
        type="number"
        :value="instances"
        @input="v=>instances=v"
      )
    .separator X
    .size.item(v-if="!instanceSizeArray")
      label {{'fpui-input-fpu.size' | translate}}
        i.fa.fa-chevron-up(
          @click="size++"
          v-if="!disabled.size"
        )
        i.fa.fa-chevron-down(
          @click="size--"
          v-if="!disabled.size"
        )
      fpui-input-text.input(
        :disabled="disabled.size"
        type="number"
        :value="size"
        @input="v=>size=v"
      )
    .size.item(v-else)
      label {{'fpui-input-fpu.size' | translate}}
      fpui-input-select(
        :options="selectboxValues('size')"
        :clearable="false"
        :value="size"
        @input="v => size = v"
      )
    .separator =
    .total.item
      label {{'fpui-input-fpu.total' | translate}}
      input.input(
        type="number"
        :disabled="true"
        :value="total"
      )
    .type.item.right
      label {{ this.title }}&nbsp;
      fpui-input-select.input(
        :style="{ 'margin-top': this.title ? '0' : '15.5px' }"
        :disabled="disabled.type"
        :clearable="false"
        :options="options.types.map(t=>({label:`fpui-input-fpu.type.${t}`,value:t }))"
        v-model="type"
      )

</template>

<script>

export default {
  props: {
    // Disable some values
    disabled: {
      type: Object,
      default () {
        return {
          size: false,
          instances: false,
          type: true
        }
      }
    },
    // Default values
    default: {
      type: Object,
      default () {
        return {
          size: 1,
          instances: 1,
          type: 'fpu-s'
        }
      }
    },
    // Title to put on top
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default () {
        return {
          // list of types available in selectbox
          types: ['fpu-s']
        }
      }
    },
    value: {
      type: Object,
      required: true
    },
    // A simple object to give maximum for each type
    quotas: {
      type: Object,
      default () {
        return {
          'fpu-s': {
            size: 16,
            instances: 16
          }
        }
      }
    },
    // External async function to validate values
    validator: {
      type: Function,
      default: null,
      required: false
    }
  },
  computed: {
    type: {
      get () {
        return this.value.type || 'fpu-s'
      },
      async set (v) {
        let value = { ...this.value }
        value.type = v || 'fpu-s'
        if (this.validator) value = await this.validator(value, this.value)
        this.$emit('input', value)
        process.nextTick(_ => {
          if (this.default?.size) {
            this.size = this.default.size
          }
        })
      }
    },
    size: {
      get () {
        return this.value.size
      },
      async set (v) {
        if (v || v === 0 || v === '0') {
          if (v < 1) v = 1
          if (this.quotas[this.type]?.size) {
            if (v > this.quotas[this.type].size) {
              v = this.quotas[this.type].size
            }
          }
          let value = { ...this.value }
          value.size = parseInt(v)
          if (this.validator) value = await this.validator(value, this.value)
          this.$emit('input', value)
        }
      }
    },
    instances: {
      get () {
        return this.value.instances
      },
      async set (v) {
        if (v || v === 0 || v === '0') {
          if (v < 1) v = 1
          if (this.quotas[this.type]?.instances) {
            if (v > this.quotas[this.type].instances) {
              v = this.quotas[this.type].instances
            }
          }
          let value = { ...this.value }
          value.instances = parseInt(v)
          if (this.validator) value = await this.validator(value, this.value)
          this.$emit('input', value)
        }
      }
    },
    total () { return this.value.instances * this.value.size },
    instanceSizeArray () {
      return Array.isArray(this.quotas[this.type]?.size)
    }
  },
  methods: {
    selectboxValues (t) {
      const ret = this.quotas[this.type][t].map(v => (
        {
          label: `${v}`,
          value: v
        }
      ))
      return ret
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-fpu {
  display:flex;
  align-items: center;
  .separator {
    color: #3E4550;
    font-size: 14px;
    font-family: "Source Sans Pro";
    line-height: 37px;
    margin:12px 1.3% 0;
    width: 1.3%;
  }
  input {
    border: 1px solid rgba(151, 167, 183, 0.21);
    background: rgba(151, 167, 183, 0.06);
    height:34px;
    padding:8px 8px 8px 11px;
    border-radius:3px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
  }
  select {
    height:34px;
    border: 1px solid rgba(151, 167, 183, 0.21);
    padding:8px 8px 8px 11px;
    background: rgba(151, 167, 183, 0.06);
    color: rgba(62,69,80,0.65);
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 18px;
  }
  .fpui-input-select {
    padding-left: 0;
  }
  .item {
    width: 22.8%;
    &.size {
      width: 150px;
    }
    &.type.right {
      margin-left: 1%;
    }
    &.type.left {
      margin-right: 1%;
    }
    margin-bottom:0;
    .input {
      width:100%;
    }
    label {
      color: #748294;
      text-transform:none;
      font-weight:normal;
      font-family: "Source Sans Pro";
      font-size: 12px;
      vertical-align: top;
      line-height: 15px;
      i {
        float:right;
        margin:2px 4px 0 3px;
        font-size:9px;
        vertical-align: top;
        cursor: pointer;
        font-weight:lighter;
      }
    }
  }
}

</style>
