<template lang="pug">
.service-selector(@click="$event.stopPropagation()")
  .closed(v-if="!opened" @click="open")
    .name-container
      span.name(v-if="item") {{item.name}}
    i.fp4.fp4-chevron-down
  .opened(
    v-if="opened"
  )
    ul.items
      li(v-for="(i, idx) in filteredItems")
        .item(:class="{ active: isActive(i), first: idx === 0, last: idx === filteredItems.length - 1, disabled: blockByAcls(i) }")
          a(:href="goToItem(i)" @click="openService(i)")
            img(
              :src="i.image"
              :class="[i.type]"
            )
            span.name {{i.name}}
            span.beta(v-if="i.beta") BETA
            i.fp4.fp4-lock-keyhole(v-if="blockByAcls(i)")
</template>

<script>
import Config from '@/shared/Config'

export default {
  props: {
    service: { type: String, default: null }
  },
  data () {
    return {
      opened: false,
      config: null,
      hasAccess: {
        dpe: true,
        ml: true,
        iam: true,
        dm: true,
        datastore: true,
        am: true,
        appservice: true,
        qb: true,
        cc: true
      }
    }
  },
  computed: {
    items () {
      return [
        {
          type: 'dm',
          aclKey: ['dm', 'datastore'],
          name: 'Data Manager',
          image: require('@/shared/assets/img/services/dm_icon.svg')
        },
        {
          type: 'am',
          aclKey: ['am'],
          name: 'Analytics Manager',
          image: require('@/shared/assets/img/services/am_dark_no_background.svg')
        },
        {
          type: 'dpe',
          aclKey: ['dpe'],
          name: 'Data Processing Engine',
          image: require('@/shared/assets/img/services/dpe_dark_no_background.svg')
        },
        {
          type: 'ml',
          aclKey: ['ml'],
          name: 'Machine Learning Manager',
          image: require('@/shared/assets/img/services/ml_dark_no_background.svg')
        },
        {
          type: 'infra',
          aclKey: null,
          name: 'Infrastructure Monitoring',
          image: require('@/shared/assets/img/services/infra_dark_no_background.svg'),
          hidden: true
        },
        {
          type: 'cc',
          aclKey: ['cc'],
          name: 'Control Center',
          image: require('@/shared/assets/img/services/infra_dark_no_background.svg')
        },
        {
          type: 'iam',
          aclKey: ['iam'],
          name: 'Identity Access Manager',
          image: require('@/shared/assets/img/services/iam_dark_no_background.svg')
        },
        {
          type: 'api',
          aclKey: ['appservice'],
          name: 'APIs',
          image: require('@/shared/assets/img/services/api_dark_no_background.svg'),
          hidden: true
        },
        {
          type: 'app',
          aclKey: ['appservice'],
          name: 'APPs',
          image: require('@/shared/assets/img/services/app_dark_no_background.svg'),
          hidden: true
        },
        {
          type: 'qb',
          aclKey: ['qb'],
          name: 'Query Builder',
          image: require('@/shared/assets/img/services/am_dark_no_background.svg'),
          hidden: true
        }
      ]
    },
    filteredItems () {
      return this.items.filter(item => !item.hidden)
    },
    item () {
      if (!this.service) return null
      return this.items.find(item => item.type === this.service)
    }
  },
  async mounted () {
    window.addEventListener('click', () => {
      this.opened = false
    })
    this.config = await Config()
    await this.loadAcls()
  },
  async destroyed () {
    window.removeEventListener('click', () => {
      this.opened = false
    })
  },
  methods: {
    open () {
      this.opened = true
    },
    isActive (item) {
      return item.type === this.item.type
    },
    async loadAcls () {
      for (const srv in this.hasAccess) {
        if (this.config?.TITLE === 'CC') { // If Control Center we need to pass dataplantId to acl
          this.hasAccess[srv] = await this.$ccapi.IAM.can(srv, null, null, null, this.$route.params.dataplantId)
        } else {
          this.hasAccess[srv] = await this.$api.IAM.can(srv)
        }
      }
    },
    goToItem (item) {
      if (this.blockByAcls(item)) return
      if (['infra', 'api', 'app'].includes(item.type)) {
        return `${window.location.origin}/#/${item.type}/${this.$route.params.dataplantId}`
      }
      if (['cc'].includes(item.type)) {
        if (this.$route.params.dataplantId) return `${window.location.origin}/${item.type}/#/${this.config.ORGANIZATION_ID}/${this.$route.params.dataplantId}`
        return `${window.location.origin}/${item.type}/#/${this.config.ORGANIZATION_ID}`
      }
      return `${window.location.origin}/${item.type}/#/${this.$route.params.dataplantId}`
    },
    openService (item) {
      if (this.blockByAcls(item)) return
      if (item.type === 'dpe') this.$analytics.track('Open DPE Component', { from: 'header' })
      else if (item.type === 'dm') this.$analytics.track('Open Data Manager Component', { from: 'header' })
      else if (item.type === 'am') this.$analytics.track('Open QB Component', { from: 'header' })
      else if (item.type === 'cc') this.$analytics.track('Open CC Component', { from: 'header' })
      else if (item.type === 'iam') this.$analytics.track('Open IAM Component', { from: 'header' })
      else if (item.type === 'ml') this.$analytics.track('Open MLM Component', { from: 'header' })
    },
    blockByAcls (service) {
      if (!service.aclKey || !Array.isArray(service.aclKey)) return false
      let check = 0

      service.aclKey.forEach(key => {
        if (!this.hasAccess[key]) check += 1
      })

      return service.aclKey.length === check
    }
  }
}
</script>

<style lang="less">
.service-selector {
  float:left;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  user-select: none;
  color: #3E4550;
  position: relative;
  ul, li {
    list-style: none;
    padding:0;
    margin:0;
    text-align: left;
  }
  .item {
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    padding-left: 20px;
    color: #3E4550;
    font-weight: 400;
    z-index: 2;
    position: relative;
    border-top: 1px solid #F6F9FC;
    font-weight: 400;
    margin: 0;
    a {
      display: flex;
      align-items: center;
      color: #3E4550;
      font-size: 14px;
      img {
        position: absolute;
        height: 24px;

        &.am {
          height: 28px;
        }
        &.ml {
          height: 15px;
          margin-left: 2px;
        }
      }
      .name {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 40px;
      }
      .beta {
        font-size: 12px;
        border-radius: 30px;
        margin-left: 10px;
        background: @blue_flash;
        padding: 0 10px;
        color: white;
        line-height: 20px;
      }
      .fp4-lock-keyhole {
        position: absolute;
        right: 10px;
      }
    }
    &.first {
      border-top: none;
    }
    &.active {
      a {
        color: #0089C0;
      }
    }
    &.disabled {
      background: #dedede !important;
      box-shadow: none !important;
      cursor: not-allowed;
      a {
        color: #3E4550;
      }
      &.first {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
    }
    &:hover {
      background: rgba(246, 249, 252);
      box-shadow: inset 0px -1px 0px #F6F9FC;

      &.first {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      &.last {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }

  .opened {
    position: absolute;
    top: -34px;
    border: 0px;
    min-width: 263px;
    z-index: 1000;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    .search-container {
      padding: 10px 20px;
    }
  }

  .closed {
    cursor: pointer;
    border-radius: 5px;
    line-height: 34px;
    height: 26px;
    position: relative;
    max-width: 300px;
    display: flex;
    align-items: center;
    .name-container {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      .name {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        font-size: 15px;
      }
    }
    i {
      float: right;
      line-height: 34px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
</style>
