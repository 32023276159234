/* istanbul ignore file */
import FpStore from '@/shared/store'
import _get from 'lodash/get'
import _set from 'lodash/set'
import 'moment/locale/fr'
import 'prismjs'
import 'prismjs/components/prism-bash.js'
import 'prismjs/components/prism-python.min.js'
import 'prismjs/themes/prism-okaidia.css'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import VueClipboard from 'vue-clipboard2'
import VModal from 'vue-js-modal'
import ToggleButton from 'vue-js-toggle-button'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueResizeObserver from 'vue-resize-observer'
import vuexI18n from 'vuex-i18n'
import resize from 'vue-element-resize-detector'
import './Acl'
import './Api'
import './FpMessageBlock'
import './VirtualScroll'
import './WebSockets'
Vue.use(VueResizeObserver)

Vue.use(resize)

Vue.use(vuexI18n.plugin, FpStore)
Vue.i18n.fallback('en')
Vue.use(VTooltip, {
  defaultDelay: {
    show: 750
  },
  defaultPopperOptions: {
    modifiers: {
      preventOverflow: {
        escapeWithReference: true
      }
    }
  }
})
Vue.use(VModal, { dynamic: true })
Vue.use(VueClipboard)
Vue.use(ToggleButton)
Vue.use(AsyncComputed)
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)

Vue.use({
  install (Vue) {
    Vue.modal = Vue.prototype.$modal
    Vue._get = Vue.prototype.$_get = _get
    Vue._set = Vue.prototype.$_set = _set
  }
})
