import Config from '@/shared/Config'
import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import { Attribute } from '@/shared/plugins/Api/DM/Attributes'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import { join } from 'path'
import slugify from 'slugify'

class Table extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    // Default variables
    this._type = 'table'
    this.toDelete = false
    this.toUpdate = false
    this.attributes = null
    this.progress = null
    if (!this.name && this.filename) {
      const tmp = this.filename.split('/')
      this.name = tmp.pop()
    }
    this.physical_status = this.physical_status || null
    this.display_name = this.display_name || this.name
    this.metas_extract = this.metas_extract || null
    this.parameters = this.parameters || {}
    if (this.file) this.filename = this.file._id || this.file.name
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      '_type',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'attributes',
      'metas_extract',
      'name',
      'toDelete',
      'toUpdate',
      'file'
    ])
    return obj
  }

  /**
  * Refresh attributes of the current table
  *
  * @return {Array} Attributes list
  */
  async refreshAttributes () {
    const attributes = await this.request({
      method: 'GET',
      url: `v4/databases/${this.database_id}/tables/${this._id}/attributes`
    })
    this.attributes = attributes.map(attr => {
      try {
        return new Attribute(attr)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
    return this.attributes
  }

  /**
  * Extract metadata from the table
  *
  * @param   String  databaseName     Database name from which the table is, to run the extract
  * @param   Boolean isSample         Either launch a full or just a sample of the extact
  * @return {Object} Confirm job init
  */
  async metaExtract (databaseName, isSample) {
    const response = await this.request({
      method: 'PUT',
      url: `v4/databases/${databaseName}/tables/${this.name}/extract`,
      data: {
        preview: true,
        sample: isSample
      }
    })
    return response
  }

  /**
  * Stop metadata extract job
  *
  * @param   String  databaseName      Database name from which the table is running the metadata extract job to stop
  * @return {Object} Confirm job stop
  */
  async stopExtract (databaseName) {
    const response = await this.request({
      method: 'DELETE',
      url: `v4/databases/${databaseName}/tables/${this.name}/extract`
    })
    return response
  }
}

class Database extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)
    this._original_id = this._id

    // Default variables
    this.accounts = this.accounts || []
    this.parameters = this.parameters || {}
    this.default = this.default || false
    this.tags = this.tags || {}
    this._type = 'database'
    this.level = this.level || 'source'
    this.type = this.type || 'database'
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
    this.tables = []
  }

  /**
  * Update table
  *
  * @param   String  name      Display name of the table to modify
  * @param   String  status
  * @param   String  file
  * @param   String  sourcename
  * @param   String  targetTable
  * @return  void
  */
  updateTable (name, status = true, file = null, sourcename = true, targetTable = {}) {
    const table = this.tables.find(table => table.display_name === name)
    if (!table && status === true) {
      const newTable = {
        display_name: name,
        file
      }
      if (targetTable.parameters) newTable.parameters = targetTable.parameters
      if (sourcename) newTable.sourcename = name
      this.tables.push(new Table(newTable))
    } else if (!table && status === false) {
      // Nothing to do
    } else if (table && status === true) {
      table.toDelete = false
    } else if (table && status === false) {
      if (!table._id) {
        const idx = this.tables.indexOf(table)
        this.tables.splice(idx, 1)
      } else {
        table.toDelete = true
      }
    }
  }

  /**
  * Update table parameters
  *
  * @param   String  filename   Filename to match the table
  * @param   Object  body
  * @return  void
  */
  updateTableParameters (filename, body) {
    const table = this.tables.find(table => (table.filename === filename) && !table.toDelete)
    const parameters = {
      toUpdate: !!table?._id,
      parameters: body?.parameters
    }
    table.assign(parameters)
  }

  tableFromId (id) {
    return this.tables.find(table => table.filename === id && !table.toDelete)
  }

  // In body you should never send name, only display name
  createEndpoint (body) {
    body.name = slugify(body.display_name).toLowerCase().replace(/-/g, '_')
    this.tables.push(new Table(body))
  }

  // Name should be here, and will never be display name ("name")
  deleteEndpoint (name) {
    const table = this.tables.find(table => table.name === name)
    if (!table) return
    if (!table._id) {
      const idx = this.tables.indexOf(table)
      this.tables.splice(idx, 1)
    } else {
      table.toDelete = true
    }
  }

  // Name should be here, and will never be display name ("name")
  // In body you should never send name, only display name
  updateEndpoint (name, body) {
    const table = this.tables.find(table => table.name === name && !table.toDelete)
    if (!table._id) body.name = slugify(body.display_name).toLowerCase().replace(/-/g, '_')
    if (table._id) body.toUpdate = true
    table.assign(body)
  }

  get currentTables () {
    return this.tables.filter(table => !table.toDelete)
  }

  get tablesToCreate () {
    return this.tables.filter(table => !table._id)
  }

  get tablesToDelete () {
    return this.tables.filter(table => table._id && table.toDelete)
  }

  get tablesToUpdate () {
    return this.tables.filter(table => table._id && table.toUpdate)
  }

  get hasChanges () {
    if (this.tablesToCreate.length || this.tablesToDelete.length || this.tablesToUpdate.length) return true
    return !!Object.keys(this._filter(this.__saveQueue)).length
  }

  getBucketPath (filename) {
    // Handle legacy path for old database
    if (this.parameters.bucket && !this.parameters.path) return filename

    const path = this.parameters.path ? join(this.parameters.path, filename) : join('uploads', this.name, filename)
    return path
  }

  async getBucketName () {
    const config = await Config()
    return this.parameters.bucket ? this.parameters.bucket.replace(`dataplant-${config.DATAPLANT_ID}-`, '') : 'dwh'
  }

  assign (object) {
    super.assign(object)
    this._original_id = this._id
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Database(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '_type',
      '_original_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
    return obj
  }

  get isFileUpload () {
    return this.package === 'file-upload'
  }

  async saveTables () {
    if (this.tablesToUpdate.length) {
      // TODO add bulk when back is ready
      for (const table of this.tablesToUpdate) {
        await this.request({
          method: 'PUT',
          url: `v4/databases/${this._id}/tables/${table._id}`,
          data: table
        })
      }
    }
    if (this.tablesToCreate.length) {
      for (const table of this.tablesToCreate) {
        if (!this.isFileUpload || !table.file) continue
        // Do not set that external to the loop, otherwise it will get it for every source instead of just file-upload
        const bucketName = await this.getBucketName()
        const path = this.getBucketPath(table.file.name)
        const bucket = Store.getters.DS_BUCKET(bucketName)
        if (!bucket) {
          this.__saving = false
          throw new Error('No bucket dwh found')
        }

        await bucket.uploadObject(table.file, path, (event) => {
          table.progress = event
        })
      }
      await this.request({
        method: 'POST',
        url: `v4/databases/${this._id}/tables`,
        data: this.tablesToCreate
      })
    }
    if (this.tablesToDelete.length) {
      for (const table of this.tablesToDelete) {
        if (!this.isFileUpload) continue
        // Do not set that external to the loop, otherwise it will get it for every source instead of just file-upload
        const bucketName = await this.getBucketName()
        const path = this.getBucketPath(table.filename)
        const bucket = Store.getters.DS_BUCKET(bucketName)
        if (!bucket) {
          this.__saving = false
          throw new Error('No bucket dwh found')
        }
        await bucket.removeObject({ key: path })
      }
      await this.request({
        method: 'DELETE',
        url: `v4/databases/${this._id}/tables`,
        params: {
          ids: JSON.stringify(this.tablesToDelete.map(t => t._id))
        }
      })
    }
    this.tables = []
    await this.refreshTables()
  }

  async copy () {
    this.__saving = true
    await super.create({
      method: 'POST',
      url: 'v4/databases/copy',
      params: {
        from: this._original_id
      },
      data: {
        display_name: this.display_name,
        name: this.name,
        tags: this.tags
      }
    })
    await this.saveTables()
    this._original_id = this._id
    this.__saving = false
    return this
  }

  async create () {
    if (this.accounts && this.accounts.length) {
      for (const acc of this.accounts) {
        if (!acc.name) acc.name = slugify(this.display_name).toLowerCase().replace(/-/g, '_')
        if (!acc.display_name) acc.display_name = this.display_name
      }
    }
    await super.create({
      method: 'POST',
      url: 'v4/databases'
    })
    this.__saving = true
    await this.saveTables()
    this._original_id = this._id
    this.__saving = false
    return this
  }

  async save () {
    await super.save({
      method: 'PUT',
      url: `v4/databases/${this._id}`
    })
    this.__saving = true
    await this.saveTables()
    this._original_id = this._id
    this.__saving = false
    return this
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/databases/${this._id}`
    })
  }

  async test_list (path = null) {
    let fullpath = this.parameters.path
    if (path) fullpath += '/' + path
    const fileList = await this.request({
      method: 'POST',
      url: 'v4/test/databases/list',
      params: {
        update: this._id ? 'true' : 'false',
        database_name: this._id ? this.name : undefined
      },
      data: {
        package: this.package,
        parameters: {
          ...this.parameters,
          path: fullpath
        }
      }
    })
    return fileList
  }

  async test_health (path = null) {
    let fullpath = this.parameters.path
    if (path) fullpath += '/' + path
    const fileList = await this.request({
      method: 'POST',
      url: 'v4/test/databases/health',
      params: {
        update: this._id ? 'true' : 'false',
        database_name: this._id ? this.name : undefined
      },
      data: {
        package: this.package,
        parameters: {
          ...this.parameters,
          path: fullpath
        }
      }
    })
    return fileList
  }

  /**
  * Refresh tables of the current database
  *
  * @return  Array    List of Tables
  */
  async refreshTables () {
    if (!this._id) return
    if (this.tables.length) return this.tables
    const tables = await this.request({
      method: 'GET',
      url: `v4/databases/${this._id}/tables`
    })
    this.tables = tables.map(table => {
      try {
        return new Table(table)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
    return this.tables
  }

  async getAttributes (tableId) {
    const attributes = await this.request({
      method: 'GET',
      url: `v4/databases/${this._id}/tables/${tableId}/attributes`
    })
    return attributes
  }

  async updateAttributes (attribute) {
    const response = await this.request({
      method: 'PUT',
      url: `v4/databases/${this._id}/tables/${attribute.table_id}/attributes/${attribute._id}`,
      data: attribute
    })
    return response
  }

  async getPreview (tableId, rules) {
    const preview = await this.request({
      method: 'POST',
      url: `/v4/databases/${this._id}/tables/${tableId}/preview`,
      data: rules
    })
    return preview
  }
}
class Databases extends API {
  /**
  * List all databases
  *
  * @return {Array} Database array
  */
  list () {
    return this.request({
      method: 'GET',
      url: 'v4/databases'
    })
      .then(databases => {
        return databases.map(database => {
          try {
            return new Database(database)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i)
      })
  }

  /**
  * List all tables
  *
  * @return {Array} Table array
  */
  async listTables () {
    const tables = await this.request({
      method: 'GET',
      url: 'v4/tables'
    })
    return tables.map(table => new Table(table))
  }

  /**
  * Add new database
  *
  * @return {Array} Database item
  */
  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Database(item)
  }
}

export default Databases
export {
  Database,
  Databases,
  Table
}
