<template lang='pug'>
  fpui-input-text.fpui-input-email(
    :name='name',
    :placeholder="placeholder",
    type="text",
    :color='color',
    v-model='model',
    :error='errorInternal',
    :error-message="errorMessageInternal"
    :disabled="disabled",
    :autofocus='autofocus',
    :with-padding='withPadding',
    :label-size='labelSize',
    :label='label',
    :required='required',
    :helper='helper',
    @keyup="$emit('keyup')"
    @click="$emit('click', $event)"
    @blur="$emit('blur', $event)"
  )
</template>

<script>

export default {
  props: {
    errorMessage: { type: String, default: null },
    error: { type: Boolean, default: false },
    name: { type: String, default: '' },
    value: { type: String, required: true, default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    autofocus: { type: Boolean, default: false },
    label: { type: String, default: '' },
    labelSize: { type: Number, default: 3 },
    withPadding: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' }
  },
  computed: {
    errorInternal () {
      if (this.error) return this.error
      if (!this.value) return false
      return !this.emailRegexp.exec(this.value)
    },
    errorMessageInternal () {
      if (this.error) {
        if (this.errorMessage) return this.errorMessage
        return this.$t('fpui-input-email.error')
      }
      return null
    },
    emailRegexp () {
      return /^([\w_.\-+])+@([\w-]+.)+([\w]{2,10})+$/
    },
    model: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  }
}
</script>
