<template lang="pug">
  .fp-app(:class="{fullscreen:fullscreen,'no-aside':!aside,'iframe-full':iframeFull}")
    fp-splash-error(v-if="$store.getters.GLOBAL_ERROR",:error="$store.getters.GLOBAL_ERROR")
    fp-header(
      :service="service"
      :icon="icon"
      :image="image"
      :beta="beta"
      :beta4="beta4"
      :alpha="alpha"
      :alpha4="alpha4"
      v-if="!$store.getters.LOADING && $store.getters.SESSION && !fullscreen && !iframeFull && !$store.getters.GLOBAL_ERROR"
    )
    .header-information(
      v-if="headerInformation"
    )
      slot
    fp-loader(v-if="$store.getters.LOADING && !$store.getters.GLOBAL_ERROR")
    main(v-if="!$store.getters.GLOBAL_ERROR")
      authentication(v-if="!$store.getters.LOADING && !$store.getters.VALID_SESSION")
      fp-aside(
        :dataplant-id="dataplantId"
        :aside='aside'
        v-if="aside && !$store.getters.LOADING && $store.getters.VALID_SESSION && !fullscreen && !iframeFull"
        :style="{ top: headerInformation ? '95px' : '70px' }"
      )
      .view(
        v-if="!$store.getters.LOADING && $store.getters.VALID_SESSION"
        :style="{ top: viewTopStyle }"
      )
        fp-loader(v-if="$store.getters.INTERNAL_LOADING")
        router-view(v-if="!$store.getters.INTERNAL_LOADING",:key="$i18n.locale()")
</template>

<script>
import _get from 'lodash/get'
import Config from '@/shared/Config'
export default {
  props: {
    service: { type: String, default: null },
    image: { type: String, default: null },
    icon: { type: String, default: null },
    beta: { type: Boolean, default: false },
    beta4: { type: Boolean, default: false },
    alpha: { type: Boolean, default: false },
    alpha4: { type: Boolean, default: false },
    headerInformation: { type: Boolean, default: false }
  },
  data () {
    return {
      aside: {},
      dataplantId: null
    }
  },
  computed: {
    fullscreen () {
      return _get(this, '$route.meta.fullscreen')
    },
    iframeFull () {
      return _get(this, '$route.meta.iframeFull')
    },
    viewTopStyle () {
      if (this.fullscreen) return '0'
      if (this.headerInformation) return '95px'
      return '70px'
    }
  },
  async created () {
    this.aside = (await Config()).aside
    this.dataplantId = (await Config()).DATAPLANT_ID
    let lang = _get(this.$route, 'query.lang') || window.localStorage.getItem('hq_lang') || 'en'
    try {
      lang = JSON.parse(lang)
    // eslint-disable-next-line no-empty
    } catch (err) {}

    this.$i18n.set(lang)
    window.localStorage.setItem('hq_lang', lang)
    await this.$store.dispatch('APP_INIT')

    // const hasAccess = this.$router?.options?.base ? await this.$api.IAM.can(this.$router?.options?.base) : true
    // if (!hasAccess) window.location.href = `${window.location.origin}/#/dataplant/${this.dataplantId}`
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-app {
  background-color: @background-color_aside;
  &.v3 {
    background-color: #eff0ef;
  }
  height: 100%;
  width: 100%;
  .header-information {
    width: 100%;
    height: 25px;
  }
  .view {
    position: absolute;
    bottom: 0px;
    right: 20px;
    overflow: auto;
    top:93px;
    transition:0.3s all;
    background-color: #FFFFFF;
    box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
    left: 124px;
  }
  &.no-aside .view {
    left: 20px;
  }
  .fp-aside {
    width:124px;
    left:0;
    top:93px;
    bottom:0;
    position: absolute;
  }
  .view {
    top:60px;
  }
  .fp-aside {
    top:60px;
  }
  &.fullscreen .view {
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  &.iframe-full .view {
    left:84px;
    right:83px;
    top:6px;

    @media screen and (max-width: 1200px) {
      left: 10px;
      right: 10px;
    }
  }
}
</style>
