<template lang="pug">
.store
  fp-loader(v-if="loading")
  .head
    h2 {{ name }}
    i.fp4.fp4-xmark.close(@click="$emit('close')")
  .sidebar
    h3(
      @click="sidebar.categories=!sidebar.categories"
    ) {{ 'store.categories' | translate }}
      i.fa(:class="{'fa-chevron-down': sidebar.categories, 'fa-chevron-up': !sidebar.categories}")
    .sidebar-items(v-if="sidebar.categories")
      .sidebar-item(
        :class="{active:!category}"
        @click="category=null"
      ) {{ 'store.all' | translate }}
      .sidebar-item(
        :class="{active: ctg===category}"
        v-for="ctg in categories"
        @click="category=ctg"
        v-tooltip="ctg.label"
      ) {{ ctg.label }}
    h3(@click="sidebar.sortBy=!sidebar.sortBy") {{ 'store.sort_by' | translate }}
      i.fa(:class="{'fa-chevron-down': sidebar.sortBy, 'fa-chevron-up': !sidebar.sortBy}")
    .sidebar-items(v-if="sidebar.sortBy")
      .sidebar-item(
        :class="{active: sort === sortOn}"
        v-for="sort in sortables"
        @click="sortOn=sort"
        v-tooltip="$t('store.sort.'+sort)"
      ) {{ 'store.sort.'+sort | translate }}
  .search
    i.fp4.fp4-magnifying-glass
    fpui-input-text(
      v-model="search"
      type="text"
      :placeholder="'input-search.placeholder' | translate"
      autofocus
    )
  .packages
    .pckg(
      v-for="pckg in packagesOrderedAndFiltered"
      :class="{ active: isActive(pckg), disable: pckg.disable }"
    )
      .pckg-content(@click="select(pckg)")
        fpui-canvas-icon(
          :name="pckgName(pckg)"
          backgroundColor="white"
          :logo="pckg.image"
          fontSize="68px"
          :size="110"
        )
        .pckg-right
          .name {{pckgName(pckg)}}
          .categories
            .category(v-for="tag in tagsAndCategories(pckg)") {{ tag }}
          .stars
            img(src="./favicon.png",width="16")
            span Created by ForePaaS
          .description {{pckgDescription(pckg)}}
          .info(@click="$emit('details',pckg);$event.stopPropagation()")
            i.fp4-angle-right.fp4
            span {{ 'store.infos' | translate }}
  .foot
    img(:src="icForepaasStore")
    .pull-right.buttons
      fpui-button(
        v-if="onCustomSelect"
        @click="confirmCustom"
        :icon="customIcon"
        auto-width
        icon-left
        color="white"
      ) {{ $t(customButtonLabel) }}
      fpui-button(
        :disabled="!ready"
        v-if="onSelect"
        @click="confirm"
        color="green"
      ) {{ $t(createButtonLabel) }}

</template>

<script>
import _get from 'lodash/get'
import _merge from 'lodash/merge'
import _sortBy from 'lodash/sortBy'
import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'
import Config from '@/shared/Config'

export default {
  props: {
    items: { type: Object, default: null },
    onSelect: { type: Function, default: null },
    name: { type: String, default: '' },
    type: { type: String, default: '' },
    createButtonLabel: { type: String, default: 'store.select' },
    onCustomSelect: { type: Function, default: null },
    customButtonLabel: { type: String, default: 'store.custom' },
    customIcon: { type: String, default: null },
    multiple: {
      type: Boolean,
      default: false
    },
    filterPackages: { type: Function, default: (pkg) => { return true } }
  },
  data () {
    return {
      sortables: ['downloaded', 'name', 'category[0]'],
      sortOn: 'downloaded',
      category: null,
      sidebar: {
        categories: true,
        sortBy: false,
        filter: false
      },
      icForepaasStore: require('./ic_forepaas_store@2x.png'),
      packages: [],
      search: '',
      selected: null,
      loading: false,
      config: null
    }
  },
  computed: {
    categories () {
      const ctgs = _uniq(_flatten(this.packages.map(this.pckgCategory)))
      return ctgs.map(ctg => {
        return {
          label: ctg,
          id: ctg
        }
      })
    },
    packagesOrderedAndFiltered () {
      const packages = _sortBy(this.packages.filter(pckg => {
        if (pckg.hidden) return false
        if (this.category?.id && !this.pckgCategory(pckg).includes(this.category.id)) return false

        return !this.search || this.pckgName(pckg).toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      }), this.sortOn)
      if (this.sortOn === 'downloaded') return packages.reverse()
      return packages
    },
    ready () {
      if (this.multiple) return !!this.selected.length
      return !!this.selected
    },
    locale () {
      return this.$i18n.locale()
    }
  },
  async created () {
    if (this.multiple) this.selected = []
  },
  async mounted () {
    this.loading = true
    this.config = await Config()
    let packages
    packages = this.items ? this.items : {}

    if (this.type) {
      const types = this.type.split(';')
      const consumersToLoad = types.map(t => t.split('.')[0])
      await this.$store.dispatch('STORE_INIT', consumersToLoad)
      for (const type of types) {
        const packagesFromType = _get(this.$store.getters.STORE_PACKAGES, type)
        const packagesFiltered = {}

        Object.keys(packagesFromType).forEach(key => {
          // Filter only for the current organization or 'forepaas' owner
          if (packagesFromType[key].owner && [this.config?.ORGANIZATION_ID, 'forepaas'].includes(packagesFromType[key].owner)) {
            packagesFiltered[key] = packagesFromType[key]
            packagesFiltered[key].type = packagesFiltered[key].type || type
          }
        })
        packages = _merge(packages, packagesFiltered)
      }
    }
    this.packages = Object.keys(packages).map(key => {
      packages[key].id = key
      // Fallback for custom imported items
      packages[key].type = packages[key].type || ''
      return packages[key]
    }).filter(pkg => this.filterPackages(pkg))
    this.loading = false
  },
  methods: {
    pckgType (pckg) {
      const type = pckg.type || ''
      return type.split('.')[0]
    },
    pckgName (pckg) {
      const nameKey = `${this.pckgType(pckg)}.${_get(pckg, 'id')}.name`
      return this.$i18n.keyExists(nameKey) ? this.$t(nameKey) : pckg.name
    },
    pckgDescription (pckg) {
      const descriptionKey = `${this.pckgType(pckg)}.${_get(pckg, 'id')}.description`
      return this.$i18n.keyExists(descriptionKey) ? this.$t(descriptionKey) : (pckg?.type === '' ? (pckg?.description || this.$t('store.no_description')) : this.$t('store.no_description'))
    },
    pckgCategory (pckg) {
      // Category can be string (ie: 'database') or array (ie: ['database', 'protocol'])
      let category = pckg.category
      if (!pckg.category) return []
      if (!Array.isArray(category)) category = [category]
      category = category.filter(c => c !== 'forepaas')
      return category.map(c => {
        // const localCategoryKey = `${this.pckgType(pckg)}.category.${c}`
        const globalCategoryKey = `store.package.category.${c}`
        // return this.$i18n.keyExists(localCategoryKey) ? this.$t(localCategoryKey) : (this.$i18n.keyExists(globalCategoryKey) ? this.$t(globalCategoryKey) : c)
        return this.$i18n.keyExists(globalCategoryKey) ? this.$t(globalCategoryKey) : c
      })
    },
    tagsAndCategories (pckg) {
      return [...pckg.tags || [], ...this.pckgCategory(pckg)]
    },
    select (pckg) {
      if (pckg.disable) return
      if (this.multiple && this.isActive(pckg)) return this.selected.splice(this.selected.indexOf(pckg), 1)
      if (this.multiple) return this.selected.push(pckg)
      if (this.isActive(pckg)) {
        this.selected = null
        return
      }
      this.selected = pckg
    },
    isActive (pckg) {
      if (this.multiple) return this.selected.indexOf(pckg) !== -1
      return this.selected === pckg
    },
    confirm () {
      if (!this.ready) return
      return this.onSelect(this.selected, this) // TODO: this is passed to close the store from another component, maybe there is a cleaner way to do it
    },
    confirmCustom () {
      return this.onCustomSelect(this) // TODO: this is passed to close the store from another component, maybe there is a cleaner way to do it
    }
  }
}
</script>
