import { API } from '@/shared/plugins/Api/API'

class Changelogs extends API {
  constructor () {
    super('FPAPI', true, true)
  }

  async all () {
    const changelogs = await this.request({
      method: 'GET',
      url: 'changelogs'
    })
    return changelogs
  }

  async getSkipLimit (skip = null, limit = null) {
    return await this.request({
      method: 'GET',
      url: `changelogs?skip=${skip}&limit=${limit}`
    })
  }
}

export default Changelogs
