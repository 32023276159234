<template lang='pug'>
.fp-tree
  fp-loader(v-if="loading")
  .fp-tree-main-header(v-if="haveHeader")
    .fp-tree-main-header-left-content
      slot(name="header-left")
      span.selected-actions(v-if='selected.length') {{ $t('treeview.selected.count', {count: selected.length}, selected.length) }}
    .fp-tree-main-header-right-content
      .fp-tree-root-actions
        .fp-tree-root-action(
          :key='index',
          v-for='action, index in availableGlobalOrderedActions'
        )
          fpui-button(
            :disabled='action.disabled && action.disabled(null,selected)'
            icon-left
            noshadow
            toolbar
            auto-width
            v-tooltip="action.tooltip",
            :icon='action.icon'
            @click="!action.click(null,selected);$event.stopPropagation()"
          ) {{ action.label }}
      slot(name="header")

  .table.fp-tree-content
    .tr.fp-tree-headers(v-if='value.length')
      .th.fp-tree-cell.fp-tree-header(
        v-for="(column, $index) in columnsWithWidth"
        :class="{ sortable: !!column.sortable, 'sort-active': tmp.sortOn===column ,'first':$index===0}"
        :style="{ width: column.width}"
        @click="sortBy(column)"
      )
        span.content {{ column.label | translate }}
        i.fp4(v-if="tmp.sortOn==column",:class="{'fp4-chevron-up':!tmp.sortReverse,'fp4-chevron-down':tmp.sortReverse}")
        span.resizable(
          v-if="$index!==columns.length-1",
          v-resizable="delta=>onColResize($index,delta)",
          @click="event=>event.stopPropagation()"
        )
          .visual
    .fp-tree-root(ref="root",:class="{'no-header':!haveHeader,'drop-target':hovered}")
      .tr.fp-tree-item.placeholder(v-if='!value.length')
        .td
          i.icon-placeholder(:class="icon")
          h3(v-if="placeholder && placeholder.title") {{ $t(placeholder.title) }}
          h3(v-else) {{ $t(`treeview.placeholder.${type}`,$t(`treeview.placeholder`,{type})) }}
          p(v-if="placeholder && placeholder.text") {{ $t(placeholder.text) }}
          p(v-else) {{ $t(`treeview.placeholder.${type}.description`,$t(`treeview.placeholder.description`,{type})) }}
          fpui-button(
            auto-width
            iconLeft
            icon='fp4 fp4-plus',
            @click="() => $emit('add')"
            color="blue-flash"
          )
            span(v-if="placeholder && placeholder.button") {{ $t(placeholder.button) }}
            span(v-else) {{ $t(`treeview.placeholder.${type}.button`,$t(`treeview.placeholder.button`,{type})) }}

      recycle-scroller.scroller(
        :items="rows"
        v-if="!paginate"
        :item-size="45"
        key-field="id"
        v-slot="{ item }"
        :item-classes="itemClasses"
        :context-menu-options="contextMenuOptions && Object.keys(contextMenuOptions).length"
      )
        .scroller-item
          fp-tree-item(
            :icon="icon"
            :draggable="draggable"
            :class="rowClass(item)"
            :key="rowKey(item)"
            :columns="columnsWithWidth"
            :actions="orderedActions"
            :sort-on="tmp.sortOn"
            :no-icon="noIcon"
            :value="item"
            :parser="parser"
            :auto-rename="toAutoRename(item)"
            :context-menu-options="contextMenuOptions"
            @focus="focus(item)"
            @select="select(item,$event)"
            @edit="$emit('edit', item.object)"
            @update="update"
            @open="callback=>open(item,callback)"
            @toggle="toggle(item.object)"
          )
      .pagination(v-else)
        .pagination-rows
          fp-tree-item(
            v-for="item in rows"
            :icon="icon"
            :draggable="draggable"
            :class="`${rowClass(item)} ${itemClasses(item).join(' ')}`"
            :key="rowKey(item)"
            :columns="columnsWithWidth"
            :actions="orderedActions"
            :no-icon="noIcon"
            :sort-on="tmp.sortOn"
            :value="item"
            :parser="parser"
            :auto-rename="toAutoRename(item)"
            :context-menu-options="contextMenuOptions"
            @focus="focus(item)"
            @select="select(item,$event)"
            @edit="$emit('edit', item.object)"
            @update="update"
            @open="callback=>open(item,callback)"
            @toggle="toggle(item.object)"
          )
          .pagination-footer(v-if="this.paginationPagesNumber>1")
            .pagination-footer-content
              .button.first(@click="paginationActions.first()",v-if="pagination.current>1")
                i.fp4.fp4-angle-left
                i.fp4.fp4-angle-left
              .button.prev(@click="paginationActions.prev()",v-if="pagination.current>0")
                i.fp4.fp4-angle-left
              .button.page(
                v-for="number in paginationShortcuts"
                @click="paginationActions.goTo(number)"
                :class="{ active: pagination.current === number }"
              ) {{number+1}}
              .button.next(@click="paginationActions.next()",v-if="pagination.current<paginationPagesNumber-1")
                i.fp4.fp4-angle-right
              .button.last(@click="paginationActions.last()",v-if="pagination.current<paginationPagesNumber-2")
                i.fp4.fp4-angle-right
                i.fp4.fp4-angle-right
            .pagination-footer-info
              //- .page {{pagination.current}}/{{paginationPagesNumber}}
              .items {{paginateStart+1}}-{{paginateEnd}}/{{value.length}}


</template>

<script>
import _debounce from 'lodash/debounce'
import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'
import _set from 'lodash/set'
import _uniq from 'lodash/uniq'
import interact from 'interactjs'
import { join } from 'path'
import moment from 'moment'
import { getSortValue, toTree, toFlat, toFlatLight } from '@/shared/components/tree-view/Parser'
import ConfirmModal from '@/shared/components/modals/Confirm'
import './FpTree.less'
import FpTreeItem from './FpTreeItem'
import FpTreeFooter from './FpTreeFooter'
import RecycleScroller from './RecycleScroller.vue'

export default {
  components: { FpTreeItem, FpTreeFooter, RecycleScroller },
  props: {
    // Parser is use to parse the original list of items, they will need an _id field (unique), a name (use for copy system and autorenaming) and a path (optional) if you use the folder system
    defaultSort: {
      type: Number,
      default: null,
      required: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    // Auto paginate the treeview instead of virtual scroll
    // That feature is not compatible with treeview that own folders
    paginate: {
      type: Boolean,
      default: false
    },
    // Disable icon display on line item
    noIcon: {
      type: Boolean,
      default: false
    },
    parser: {
      default: () => {
        return {
          id: '_id',
          path: 'tags.path',
          name: 'display_name'
        }
      },
      type: Object
    },
    placeholder: {
      type: Object,
      default: () => {}
    },
    // The default type use in some translation
    type: {
      type: String,
      default: 'file'
    },
    // The default icon for non folder object
    icon: {
      type: String,
      default: 'fa fa-file'
    },
    confirm: {
      type: Object,
      default () {
        return {
          copy: true
        }
      }
    },
    // value should contain the list of objects (as a flat array)
    value: {
      type: Array,
      default: () => []
    },
    // actions should contains an extend for defaultAction parameter
    actions: {
      type: Object,
      default: () => { }
    },
    // Column should contain the list of column to display
    columns: {
      type: Array,
      default: () => [
        {
          label: this.$t('treeview.name'), // Header of the column
          grow: 4, // Size of the column
          target: 'display_name', // Target can be a field name (with lodash getter way) / a function (row)=>row.value / a component (will receive the props value with the row inside it)
          sortable: false // If set to true, column will be sortable, it can be a function with the sortable value to use (if different from display)
        }
      ]
    },
    beforeRemove: {
      type: Function,
      default: null
    },
    contextMenuOptions: {
      type: Object,
      default: () => { }
    },
    rowClasses: {
      type: Function,
      default: null
    },
    noHeader: { type: Boolean, default: false },
    noRemoveConfirm: { type: Boolean, default: false },
    noMultiSelect: { type: Boolean, default: false }
  },
  data () {
    return {
      loading: false, // Set to true from long running action, will be replace by a task list in next version
      remainSize: 0, // Height available for treeview render
      windowWidth: 0,
      width: 100,
      columnResized: null,
      pagination: {
        current: 0
      },
      hovered: false,
      tmp: {
        sortOn: this.columns.find(c => !!c.sortable), // Default index of column, if set to -1 there is no sort at all
        sortReverse: false,
        focus: null,
        folders: [], // Will be use to store the local folderId, that folder is empty, it should be removed from here when something is drop inside
        opens: [], // Will be use to store id of the opens folder
        selected: [], // Will be use to store id of selected items
        previousSelected: null, // Will store the previously selected item (to use shift + click at next time)
        autoRename: null // Will be autoRename on open
      }
    }
  },
  computed: {
    paginationShortcuts () {
      const shortkuts = []
      const start = this.pagination.current - 2
      for (let i = 0; i < 5; i++) {
        if (start + i >= 0 && start + i <= this.paginationPagesNumber - 1) {
          shortkuts.push(start + i)
        }
      }
      return shortkuts
    },
    paginationPagesNumber () {
      return Math.ceil(this.value.length / this.paginationMaxRows)
    },
    paginationActions () {
      return {
        goTo: (number) => {
          this.pagination.current = number
        },
        first: () => {
          this.paginationActions.goTo(0)
        },
        prev: () => {
          this.paginationActions.goTo(this.pagination.current - 1)
        },
        next: () => {
          this.paginationActions.goTo(this.pagination.current + 1)
        },
        last: () => {
          this.paginationActions.goTo(this.paginationPagesNumber - 1)
        }
      }
    },
    paginationFooter () {
      return true
    },
    paginationMaxRows () {
      const footerSize = this.paginationFooter ? 50 : 0
      return Math.floor((this.remainSize - footerSize) / 45)
    },
    // Will return the list of items selected (not only ids)
    selected () {
      const sel = this.rows.filter(r => this.tmp.selected.indexOf(_get(r, `object.${this.parser.id}`)) !== -1)
      return sel
    },
    // Default action will be use to add standard button, it can be override (the conf you give will override that one by merge)
    defaultActions () {
      return {
        // Add an element inside the folder or globally
        add: {
          icon: 'fp4 fp4-plus',
          tooltip: this.$t(`treeview.add.${this.type}.tooltip`),
          order: 5,
          click: (parent) => {
            this.$emit('add', parent.object)
          },
          // Only if folder or globally
          if (item) {
            if (!item) return false
            if (item.object.type !== 'folder') return false
            return true
          }
        },
        // Create new folder
        folder: {
          icon: 'fp4 fp4-folder-plus',
          tooltip: this.$t('treeview.folder.tooltip'),
          order: 10,
          click: this.folder,
          // Only inside folder or globally
          if (item) {
            if (!item) return true
            if (item.object.type !== 'folder') return false
            return true
          }
        },
        // Launch edit an object (often a redirect)
        edit: {
          icon: 'fp4 fp4-pencil',
          tooltip: this.$t('treeview.edit.tooltip'),
          order: 15,
          click: (item) => {
            this.$emit('edit', item.object)
          },
          // Not working on folder
          if (item) {
            if (!item) return false
            if (item.object.type !== 'folder') return true
            return false
          }
        },
        // Copy element(s)
        copy: {
          icon: 'fp4 fp4-copy',
          tooltip: this.$t('treeview.copy.tooltip'),
          order: 20,
          click: this.copy,
          // Disable globally if nothing selected
          disabled (item, selected) {
            if (item) return false
            if (selected && selected.length) return false
            return true
          },
          if (item, selected) {
            if (item) return true
            if (selected && selected.length) return true
            return false
          }
        },
        // Remove element(s)
        remove: {
          icon: 'fp4 fp4-trash-can',
          tooltip: this.$t('treeview.remove.tooltip'),
          order: 25,
          click: this.remove,
          // Disable globally if nothing selected
          disabled (item, selected) {
            if (item) return false
            if (selected && selected.length) return false
            return true
          },
          if (item, selected) {
            if (item) return true
            if (selected && selected.length) return true
            return false
          }
        }
      }
    },
    haveHeader () {
      if (this.noHeader) return false
      return true
    },
    // Will order action with the order field
    availableGlobalOrderedActions () {
      return this.orderedActions.filter(action => {
        return !action.if || action.if(null, this.selected)
      })
    },
    orderedActions () {
      const merged = _merge({}, this.defaultActions, this.actions)
      const actions = Object.keys(merged).map(key => {
        merged[key].id = key
        return merged[key]
      }).sort((a, b) => {
        if (a.order < b.order) return -1
        if (b.order < a.order) return 1
        return 0
      })
      return actions
    },
    filteredColumns () {
      return this.columns.filter(column => !column.if || column.if(this.windowWidth))
    },
    // Will compute the column width with the grow system
    columnsWithWidth () {
      if (this.columnResized) return this.columnResized
      let ratio = 0
      for (const column of this.filteredColumns) {
        ratio += column.grow || 1
      }
      return this.filteredColumns.map((c, index) => {
        const width = this.width / ratio * (c.grow || 1)
        c.width = `${width}px`
        return c
      })
    },
    paginateStart () {
      const pageMaxRows = this.paginationMaxRows > 0 ? this.paginationMaxRows : 1
      return this.pagination.current * pageMaxRows
    },
    paginateEnd () {
      const pageMaxRows = this.paginationMaxRows > 0 ? this.paginationMaxRows : 1
      const end = this.paginateStart + pageMaxRows
      if (end > this.value.length) return this.value.length
      return end
    },
    // Enable sort on items before every compute, cause if we do it after, perf has already gone away
    paginateValues () {
      if (!this.paginate) return this.value
      // Doing sort here, cause we need to do it before splicing values
      const sorted = [...this.value].sort((a, b) => {
        const aValue = getSortValue(a, this.tmp.sortOn, this.tmp.sortReverse)
        const bValue = getSortValue(b, this.tmp.sortOn, this.tmp.sortReverse)
        if (aValue < bValue) return this.tmp.sortReverse ? 1 : -1
        if (aValue > bValue) return this.tmp.sortReverse ? -1 : 1
        return 0
      })
      return sorted.slice(this.paginateStart, this.paginateEnd)
    },
    // Will give a treeview item list, with folder, children ect, it's use to work with parent and sibling system in algo
    items () {
      if (this.paginate) return this.paginateValues
      const items = toTree(this.paginateValues, this.parser, this.tmp)
      return items
    },
    // Will give a row item list, with folder, but with the "level" tricks inside it, it's use to render the tree as a table
    rows () {
      if (this.paginate) return toFlatLight(this.items)
      const items = toFlat(this.items, this.parser, this.tmp).map(row => {
        row.id = this.rowKey(row)
        return row
      })
      return _uniqBy(items, i => _get(i.object, this.parser.id))
    }
  },
  watch: {
    value () {
      this.pagination.current = 0
    }
  },
  mounted () {
    // On mount add a resize event with debounce
    if (this.defaultSort) {
      this.tmp.sortOn = this.columns[this.defaultSort]
    }
    this.onResize()
    this.onResize = _debounce(this.onResize.bind(this), 300)
    window.addEventListener('resize', this.onResize)
    // Add event for click outside
    window.addEventListener('click', this.onClickOutside)
    process.nextTick(() => {
      this.width = this.$el.offsetWidth
    })

    const element = interact(this.$el)
    // On drop event
    element.dropzone({
      ondrop: async (event) => {
        this.hovered = false
        if (!event.relatedTarget.items) return
        event.relatedTarget.items.forEach(item => {
          this.update({ target: this.parser.path, value: '', row: item })
        })
      },
      ondragenter: async (event) => {
        if (!event.relatedTarget.items) return
        this.hovered = true
      },
      ondragleave: async (event) => {
        this.hovered = false
      }
    })
  },
  destroyed () {
    // On destroyed, remove them
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    onColResize (index, delta) {
      const currentState = _cloneDeep(this.columnsWithWidth || this.columnResized)
      const column = currentState[index]
      const currentWidth = parseInt(column.width.split('px')[0])
      if (isNaN(currentWidth)) return
      let newWidth = parseFloat(currentWidth) + parseFloat(delta)
      if (newWidth > 0.8 * this.width) newWidth = this.width * 0.8
      if (newWidth < 20) newWidth = 20
      column.width = `${newWidth}px`
      this.columnResized = currentState
    },
    sortBy (column) {
      if (!column.sortable) return
      if (this.tmp.sortOn === column) {
        this.tmp.sortReverse = !this.tmp.sortReverse
        return
      }
      this.tmp.sortOn = column
      this.tmp.sortReverse = false
    },
    isParent (target) {
      if (!this.$refs.virtuallist) return false
      if (target === this.$refs.virtuallist) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    itemClasses (row) {
      const classes = []
      if (this.isSelected(row)) classes.push('selected')
      if (row === this.tmp.focus) classes.push('focus')
      return classes
    },
    focus (row) {
      this.tmp.focus = row
    },
    toAutoRename (row) {
      return _get(row.object, this.parser.id) === this.tmp.autoRename
    },
    rowClass (row) {
      if (!this.rowClasses) return ''
      return this.rowClasses(row.object).join(' ')
    },
    rowKey (row) {
      return (row.object.type ? row.object.type : 'file') + _get(row.object, this.parser.id)
    },
    // On click outside unselect elems
    onClickOutside ($event) {
      if (!$event || this.isParent($event.target)) return
      this.unSelect()
    },
    // Use to set size onWindowResize event
    onResize () {
      this.remainSize = this.$refs?.root?.clientHeight
      this.pagination.current = 0
      this.windowWidth = window.innerWidth
      this.width = this.$el.offsetWidth
    },
    // Call when we click on remove from item or selected, it can give folder or direct item, or list of item (in selected)
    async remove (item, selected) {
      const itemToRemove = item ? [item.object] : selected.map(s => s.object)
      let flatItems = []
      this.loading = true
      // Use to prevent too long load of recursive items
      const startTime = moment()

      for (const it of itemToRemove) {
        this.close(it)
        const idx = this.tmp.folders.indexOf(_get(it, this.parser.id))
        if (idx !== -1) {
          this.tmp.folders.splice(idx, 1)
          continue
        }

        // We will flat list to have a list of object (no folder)
        const itemsRecursive = await this.recursiveGet([it], true, startTime)
        flatItems = flatItems.concat(itemsRecursive)
      }
      this.loading = false

      if (!flatItems.length) return

      let confirmResult = null
      let icon = ''
      let title = this.$t(`treeview.${this.type}.remove.modal.title`)
      let content = this.$t(`treeview.${this.type}.remove.modal.content`, [`${flatItems.length > 10 ? '<strong>' + this.$t('treeview.remove.modal.items', [flatItems.length > 100 ? '100+' : `${flatItems.length}${moment().diff(startTime, 'seconds') >= 3 ? '+' : ''}`], flatItems.length) + '</strong>' : `"${flatItems.map(i => _get(i, this.parser.name)).join('", "')}"`}`])
      let contentHtml = true

      if (this.beforeRemove) {
        const modalConfig = await this.beforeRemove(flatItems, this.type)
        icon = modalConfig.icon || icon
        title = modalConfig.title || title
        content = modalConfig.content || content
        contentHtml = modalConfig.contentHtml || contentHtml
      }

      if (!this.noRemoveConfirm) {
        confirmResult = await ConfirmModal(this, {
          icon,
          title,
          content,
          contentHtml,
          yes: {
            text: this.$t('fpui-modal-confirm.remove'),
            color: 'red'
          }
        })
        if (!confirmResult) return
      }

      // We unselect everything
      this.unSelect()
      // Start doing the remove
      this.loading = true

      if (this.$listeners['bulk-remove']) {
        await new Promise(resolve => {
          this.$emit('bulk-remove', flatItems, item ? [item?.object] : selected.map(s => s.object), () => resolve())
        })
      } else {
        await Promise.all(flatItems.map(async item => {
          return new Promise(resolve => this.$emit('remove', item, () => resolve()))
        }))
      }
      this.loading = false
    },
    // Call when we click on copy from item or selected, it can give folder or direct item, or list of item (in selected)
    async copy (item, selected) {
      // Get all selected item inside an array
      const itemsToCopy = item ? [item.object] : selected.map(s => s.object)
      const itemName = itemsToCopy.length === 1 ? _get(itemsToCopy[0], this.parser.name) : null
      if (this.confirm.copy || typeof (this.confirm.copy) === 'undefined') {
        const confirmResult = await ConfirmModal(this, {
          title: this.$t('treeview.copy.confirm.title', { name: itemName }, (selected?.length + 1) || 1),
          yes: {
            text: this.$t('treeview.copy.confirm.duplicate'),
            color: 'blue-flash'
          }
        })
        if (!confirmResult) return
      }
      let flatItems = []
      this.loading = true
      for (let itemToCopy of itemsToCopy) {
        // For each item it will work separatly
        // Update name of item on a copy
        const currentName = _get(itemToCopy, this.parser.name)
        const newName = this.generateCopyName(currentName, this.getSiblings(itemToCopy))
        await this.recursiveGet([itemToCopy])
        itemToCopy = _cloneDeep(itemToCopy)
        // Flart item to updates
        // Get a flat list of item to copy
        const itemsRecursive = await this.recursiveGet([itemToCopy])
        _set(itemToCopy, this.parser.name, newName)
        _set(itemToCopy, this.parser.id, join(_get(itemToCopy, this.parser.path) || '', _get(itemToCopy, this.parser.name)))
        flatItems = flatItems.concat(itemsRecursive).map(i => {
          // Clone them
          const copy = _cloneDeep(i)
          // Replace the path currentName occurence by newName
          const newPath = (_get(copy, this.parser.path) || '').replace(currentName, newName)
          // Set that new path
          _set(copy, this.parser.path, newPath)
          _set(copy, this.parser.id, join(copy.path || '', copy.name))
          return copy
        })
      }
      this.unSelect()
      // Launch the bulk create call
      await Promise.all(flatItems.map(async item => {
        return new Promise(resolve => this.$emit('create', item, () => resolve()))
      }))
      this.loading = false
    },
    // Will return if a row is selected or not
    isSelected (item) {
      return this.tmp.selected.indexOf(_get(item, `object.${this.parser.id}`)) !== -1
    },
    // Unselected everything (use by bulk call ect after end)
    unSelect () {
      this.tmp.selected = []
    },
    // Will select an item (for bulk actions)
    select (item, $event) {
      $event.stopPropagation()
      // If shift press we will select everything between current selection and previous one
      if ($event.shiftKey && !this.noMultiSelect) {
        // We need to find element between prev and now item and sort them
        let startIndex = this.rows.findIndex(r => _get(r, `object.${this.parser.id}`) === this.tmp.previousSelected)
        let stopIndex = this.rows.findIndex(r => _get(r, `object.${this.parser.id}`) === _get(item, `object.${this.parser.id}`))
        if (startIndex > stopIndex) {
          const tmp = stopIndex
          stopIndex = startIndex
          startIndex = tmp
        }
        // We will keep uniq list of previous selection and new ones
        this.tmp.selected = _uniq(this.tmp.selected.concat(this.rows.filter((r, i) => i >= startIndex && i <= stopIndex).map(r => _get(r, `object.${this.parser.id}`))))
        // Set previousclick to current for next click
        this.tmp.previousSelected = _get(item, `object.${this.parser.id}`)
        return
      }

      // Set the previous, for next click (in dcase of shift)
      this.tmp.previousSelected = _get(item, `object.${this.parser.id}`)
      // Check if previously selected
      const idx = this.tmp.selected.indexOf(_get(item, `object.${this.parser.id}`))
      // If already select, we unselect it
      if (idx !== -1) {
        this.$emit('unselect', item)
        return this.tmp.selected.splice(idx, 1)
      }
      // If not prev selected
      // If ctlrkey or metakey we add it to current list
      if ((!$event.ctrlKey && !$event.metaKey) || this.noMultiSelect) {
        this.$emit('select', item)
        this.tmp.selected = [_get(item, `object.${this.parser.id}`)]
      } else {
        // We unselect all and select current one
        this.tmp.selected.push(_get(item, `object.${this.parser.id}`))
        this.$emit('select', this.tmp.selected)
        return this.tmp.selected
      }
    },
    // We will close a folder, without looking for current state
    close (item) {
      const id = _get(item.object, this.parser.id)
      const idx = this.tmp.opens.indexOf(id)
      if (idx !== -1) this.tmp.opens.splice(idx, 1)
    },
    // We will open folder, without looking for current state
    open (item, callback = () => { }) {
      if (!item) return callback()
      const id = _get(item.object, this.parser.id)
      const idx = this.tmp.opens.indexOf(id)
      if (idx === -1) {
        this.tmp.opens.push(id)
        this.$emit('open', { item: item.object, callback })
      } else {
        callback()
      }
    },
    // Toggle item between open close state
    toggle (item) {
      this.unSelect()
      if (item.type !== 'folder') return
      const id = _get(item, this.parser.id)
      const idx = this.tmp.opens.indexOf(id)
      if (idx === -1) {
        this.$emit('open', { item, callback: () => {} })
        return this.tmp.opens.push(id)
      }
      this.tmp.opens.splice(idx, 1)
    },

    // Will take an array of items (folder and object) and return an array of objects
    // limitCount is used when remove, to avoid too long loading if await call (like in buckets)
    // starTime is also used in remove, to avoid too long loading time
    async recursiveGet (items = [], limitCount = false, startTime = null) {
      let flatItems = []
      for (const item of items) {
        if (!limitCount || (limitCount && flatItems.length < 100 && (moment().diff(startTime, 'seconds') < 3))) {
          if (item.type !== 'folder') {
            flatItems.push(item)
            continue
          }

          // We force open, with a callback of the folder before check children
          if (!item.children || !item.children.length) {
            await new Promise(resolve => {
              if (!this.$listeners.open) return resolve()
              this.$emit('open', { item, callback: resolve })
            })
          }

          const fromChildren = await this.recursiveGet(item.children, limitCount, startTime)
          flatItems = flatItems.concat(fromChildren)
        } else {
          break
        }
      }
      return flatItems
    },
    // Create a new folder (in tmp vars, will be removed if something is really create inside it)
    folder (item) {
      if (item && this.tmp.opens.indexOf(_get(item, `object.${this.parser.id}`)) === -1) {
        this.toggle(item.object)
      }
      const name = this.generateFolderName(item)
      const path = _get(item, `object.${this.parser.id}`) || ''
      const id = join(path, name)
      this.tmp.folders.push(id)
      this.tmp.autoRename = id
    },
    // return siblings from item (alias BRO)
    getSiblings (item) {
      return this.rows.filter(row => {
        return _get(row.object, this.parser.path) === _get(item, this.parser.path)
      }).map(row => row.object)
    },
    // Generate a new name from previous one
    generateCopyName (baseName, siblings) {
      const names = siblings.map(sibling => _get(sibling, this.parser.name))
      const tmp = baseName.split('.')
      const idx = tmp.length > 1 ? tmp.length - 2 : 0
      tmp[idx] = tmp[idx] + ' (Copy)'
      let name = tmp.join('.')
      let index = 1
      while (names.indexOf(name) !== -1) {
        index++
        name = `${baseName} (Copy ${index})`
      }
      return name
    },
    // Will create a new folder name
    generateFolderName (parent) {
      const siblings = parent ? parent.object.children : this.items
      const names = siblings.map(c => _get(c, this.parser.name))
      const baseName = this.$t('treeview.new.folder')
      let name = baseName
      let index = 1
      // We handle colision, 2 folder cant have the same name
      while (names.indexOf(name) !== -1) {
        index++
        name = `${baseName} (${index})`
      }
      return name
    },
    // Will update a folder or an item
    // @target is a field name, it can be like that display_name or tags.path
    // @value will be the value to set for this field
    // @row is an item of the treeview, folder or object
    async update ({ target, value, row }) {
      // If this is not a folder, we can stop here and update the element
      if (row.type !== 'folder') {
        const change = {}
        const keys = target.split('.')
        if (keys.length > 1) {
          // Handle tags.path, it's removing tags.tags
          const base = keys.shift()
          const sub = keys.join('.')
          change[base] = _cloneDeep(row[base])
          _set(change[base], sub, value)
        } else {
          _set(change, target, value)
        }
        this.$emit('update', { item: row, value: change })
        return
      }
      // Force autoopen folder, the next one (clone) won't have the original name for open
      await this.recursiveGet([row])

      // Folder case will be harder, we have to update name, id, path and give an update to all child
      // Other field of folder should never be update, cause its useless, it will me removed at refresh (they don't have any real storage except tags.path inside files)
      // We clone the folder
      const clone = _cloneDeep(row)
      // Set the value
      _set(clone, target, value)
      // Get the prev id
      const prevRowId = _get(clone, this.parser.id)
      // Get the next id
      const newRowId = join(_get(clone, this.parser.path), _get(clone, this.parser.name))

      // Update opens and local folder (with cleaning filter)
      this.tmp.folders = this.tmp.folders.filter(f => f).map(folderId => {
        return folderId.replace(prevRowId, newRowId)
      })
      this.tmp.opens = this.tmp.opens.filter(f => f).map(folderId => {
        return folderId.replace(prevRowId, newRowId)
      })

      // Set the new id
      _set(clone, this.parser.id, newRowId)
      // Set the new path
      // Get all recursive item (flat mode)
      const itemsRecursive = await this.recursiveGet([clone])
      itemsRecursive.forEach(item => {
        // Compute the new path
        const oldPath = _get(item, this.parser.path)
        const newPath = oldPath.replace(prevRowId, newRowId)
        // If same one, no update launch
        if (newPath === oldPath) return
        // Compute change object
        const change = {}
        _set(change, this.parser.path, newPath)
        // Launch update
        this.$emit('update', { item, value: change })
      })
    }
  }
}
</script>
