<template lang="pug">
.header-container
  .left
    a(:href="linkHomeDashboards")
      fpui-button(
        v-if="displayBackButton"
        color="grey"
        reverse
        icon-left
        noshadow
        unbordered
        autoWidth
        icon='fp4 fp4-arrow-left'
      ) {{ $t('home.back') }}
  .middle
    img(:src="require('@/dashboard/assets/img/ForePaaS.png')")
  .right
    fpui-button(
      auto-width
      reverse
      noshadow
      icon-left
      icon='fp4 fp4-power-off'
      color='red'
      @click="logout()"
    ) {{ $t('home.logout') }}
</template>

<script>
import Config from '@/shared/Config'
import configDashboard from '@/dashboard/config'

export default {
  props: {
    displayBackButton: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null
    }
  },
  computed: {
    linkHomeDashboards () {
      return `dashboard/#/${this.config?.DATAPLANT_ID}/home`
    }
  },
  async mounted () {
    this.config = this.$route.name === 'Dashboard' ? configDashboard : await Config()
  },
  methods: {
    logout () {
      this.$store.dispatch('LOGOUT')
    }
  }
}
</script>

<style lang="less">
.header-container {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  position: relative;
  background: white;
  box-shadow: inset 0px -1px 0px rgba(151, 167, 183, 0.2);

  .left {
    position: absolute;
    left: 40px;
  }
  .middle {
    img {
      height: 30px;
    }
  }
  .right {
    position: absolute;
    right: 40px;
  }
}
</style>
