<template lang="pug">
.query-preview
  fp-loader(type="chart" v-if="internalLoading || loadingExecution")
  .header-container
    .left-buttons-container
      a.button-settings(
        v-if="!fromExplorer"
        v-tooltip="$t('query.preview.toggle_chart_settings')"
        :class="{ active: isOpenSettings }"
        @click="switchSettings()"
      )
        i.fp4.fp4-gears
      .preview-switch-mode(
        v-if="!fromExplorer"
      )
        a.mode-button.mode-table(
          v-tooltip="$t('query.preview.table')"
          :class="{ active: previewMode === 'table' }"
          @click="switchPreviewMode('table')"
        )
          i.fp4.fp4-table
        a.mode-button.mode-line(
          v-if="visualMode"
          v-tooltip="$t('query.preview.line')"
          :class="{ active: previewMode === 'line' }"
          @click="switchPreviewMode('line')"
        )
          i.fp4.fp4-chart-line
        a.mode-button.mode-area(
          v-if="visualMode"
          v-tooltip="$t('query.preview.area')"
          :class="{ active: previewMode === 'area' }"
          @click="switchPreviewMode('area')"
        )
          i.fp4.fp4-chart-area
        a.mode-button.mode-bar(
          v-if="visualMode"
          v-tooltip="$t('query.preview.bar')"
          :class="{ active: previewMode === 'bar' }"
          @click="switchPreviewMode('bar')"
        )
          i.fp4.fp4-chart-bar
        a.mode-button.mode-scatter(
          v-if="visualMode"
          v-tooltip="$t('query.preview.scatter')"
          :class="{ active: previewMode === 'scatter' }"
          @click="switchPreviewMode('scatter')"
        )
          i.fp4.fp4-chart-scatter
        a.mode-button.mode-pie(
          v-if="visualMode"
          v-tooltip="$t('query.preview.pie')"
          :class="{ active: previewMode === 'pie' }"
          @click="switchPreviewMode('pie')"
        )
          i.fp4.fp4-chart-pie
        a.mode-button.mode-big-number(
          v-if="visualMode"
          v-tooltip="$t('query.preview.big_number')"
          :class="{ active: previewMode === 'big-number' }"
          @click="switchPreviewMode('big-number')"
        )
          i.fp4.fp4-chart-number
      .fpui-tabs.explorer(
        v-if="fromExplorer"
        ref="tabs"
      )
        ul.fpui-tabs-heads
          li.fpui-tabs-head(
            :title="$t('query.preview.tab_results')"
            :class="{ active: tabActive === 'results' }"
            @click="setActive('results')"
          )
            span(v-html="$t('query.preview.tab_results')")
          li.fpui-tabs-head(
            :title="$t('query.preview.tab_history')"
            :class="{ active: tabActive === 'history' }"
            @click="setActive('history')"
          )
            span(v-html="$t('query.preview.tab_history')")
    .right-buttons-container
      .download-loading(v-if="downloadLoading")
        fp-loading
        span {{ this.$t('query.preview.exporting_loader') }}
      fpui-input-select.limit(
        v-model="limit"
        v-tooltip="$t('query.preview.limit_tooltip')"
        :options="limitOptions"
        :clearable="false"
        is-filter
      )
      fpui-button.run(
        v-tooltip="$t('query.preview.run')"
        color="green",
        icon='fp4 fp4-play'
        auto-width
        icon-left
        :disabled="internalLoading || !acl || item.saving"
        @click="run()",
      ) {{ $t('button.run') }}
      .actions-container(v-if="!fromExplorer")
        .action-button.download
          fpui-input-select-categories(
            :options="downloadOptions"
            :clearable="false"
            :searchable="false"
            :icon="'fp4-down-to-line'"
            :categories="downloadCategories"
            :value-tooltip="$t('query.preview.download_button')"
            @input="(v) => downloadPreview(v)"
          )

        a.action-button.share(
          v-tooltip.top-end="{ content: $t('query.preview.coming_soon'), delay: 0 }"
          @click="share()"
          :disabled='true || !this.result'
          :class='{ "disabled": true || !this.result }'
        )
          i.fp4.fp4-share
        //- a.action-button.code(@click="code()")
        //-   i.fp4.fp4-code
        //- a.action-button.qrcode(@click="qrcode()")
        //-   i.fp4.fp4-plus-grid-2
  .preview-data-container(v-if="!internalLoading && !loadingExecution")
    .explorer-history(
      v-if="fromExplorer && tabActive === 'history'"
    )
      history-table(
        type="past"
        :query-id="queryId"
        :from-explorer="true"
      )
    .results(
      v-if="(fromExplorer && tabActive === 'results') || !fromExplorer"
    )
      .settings-container(
        v-show="isOpenSettings && !error && !fromExplorer"
      )
        chart-settings(
          :type="previewMode"
          :item="item"
          :result="result"
          :read-only="readOnly"
        )
      .result-container(
        v-if="result && !error"
        :style="{ width: isOpenSettings ? 'calc(100% - 328px)' : '100%' }"
      )
        component(
          :is="chartDisplayed"
          :value="resultToDisplay"
          :sql="item && item.type === 'sql'"
          :key="previewMode"
        )
      .result-error(
        v-if="error"
      )
        .status.center
          i.fp4.fp4-circle-exclamation
        .text {{ $t('query.preview.result.error') }}
        .panel-error
          .summary
            .circle
            .summary-text {{ errorSummary }}
          .error-message {{ error }}
      .no-result(v-if="!result && !error")
        img(
          v-if="previewMode === 'table'"
          src='@/qb/assets/img/placeholder_preview.png'
        )
        img(
          v-else
          src='@/qb/assets/img/placeholder_preview_chart@2x.png'
        )
        .message {{ $t('query.preview.result.empty') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import pascalcase from 'pascalcase'

import HistoryTable from '@/shared/components/query-builder/HistoryTable'
// import mockResults from './mock/mock_rides_per_stationName.js'
import ChartSettings from './Charts/ChartSettings/ChartSettings'
import Charts from './Charts'
import EchartsFormatter from './Charts/EchartsFormatter'
// import ShareModal from './ShareModal'

export default {
  components: {
    ...Charts,
    ChartSettings,
    HistoryTable
  },
  props: {
    item: { type: Object, default: () => { } },
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    fromExplorer: { type: Boolean, default: false },
    // Loading execution is used to display loader when user run from sql editor (Cmd + Enter) and the query is saving
    loadingExecution: { type: Boolean, default: false }
  },
  data () {
    return {
      canQueryRun: true,
      canQueryHistory: true,
      isOpenSettings: false,
      previewMode: 'table',
      limit: 5000,
      semiStructured: false,
      internalLoading: false,
      downloadLoading: false,
      result: null,
      error: null,
      errorSummary: null,
      tabActive: 'results',
      downloadCategories: [
        {
          label: this.$t('query.preview.download.results'),
          id: 'results'
        },
        {
          label: this.$t('query.preview.download.configuration'),
          id: 'configuration'
        }
      ]
    }
  },
  computed: {
    limitOptions () {
      return [
        {
          label: this.$t('query.preview.limit.all'),
          value: 'all'
        }, {
          label: this.$t('query.preview.limit', [10]),
          value: 10
        }, {
          label: this.$t('query.preview.limit', [100]),
          value: 100
        }, {
          label: this.$t('query.preview.limit', [1000]),
          value: 1000
        }, {
          label: this.$t('query.preview.limit', [5000]),
          value: 5000
        }
      ]
    },
    downloadOptions () {
      const options = [
        {
          label: this.$t('query.preview.download.csv'),
          value: 'csv',
          disabled: !this.result,
          tooltip: !this.result ? this.$t('query.preview.download.copy_to_clipboard.tooltip_disabled') : '',
          category: 'results'
        }, {
        //   label: this.$t('query.preview.download.online_csv'),
        //   value: 'online-csv',
        //   disabled: true,
        //   category: 'results'
        // }, {
        //   label: this.$t('query.preview.download.pdf'),
        //   value: 'pdf',
        //   disabled: true,
        //   category: 'results'
        // }, {
        //   label: this.$t('query.preview.download.png'),
        //   value: 'png',
        //   disabled: true,
        //   category: 'results'
        // }, {
        //   label: this.$t('query.preview.download.html'),
        //   value: 'html',
        //   disabled: true,
        //   category: 'results'
        // }, {
          label: this.$t('query.preview.download.copy_to_clipboard'),
          value: 'copy',
          disabled: !this.result,
          tooltip: !this.result ? this.$t('query.preview.download.copy_to_clipboard.tooltip_disabled') : '',
          category: 'results'
        }
      ]

      if (this.item?.type !== 'sql') {
        options.push({
          label: this.$t('query.preview.download.json'),
          value: 'json',
          category: 'configuration'
        })
      }

      return options
    },
    resultToDisplay () {
      if (['line', 'pie', 'bar', 'scatter', 'area'].includes(this.previewMode)) {
        const fullOptions = {
          ..._cloneDeep(this.result),
          options: this.item.chart_settings[this.previewMode]
        }

        // If 30+ data points, do not show label in pie chart
        if (this.result?.results?.length > 30 && this.previewMode === 'pie') {
          fullOptions.options.series.label.show = false
        }

        return new EchartsFormatter(fullOptions)
      }
      return {
        ...this.result,
        options: this.item.chart_settings[this.previewMode] || {}
      }
    },
    acl () {
      return this.canQueryRun
    },
    visualMode () {
      return this.item?.type === 'forepaas'
    },
    queryId () {
      return this.item?._id
    },
    evolDepth () {
      if (!this.result?.query_params?.evol || !this.result.query_params.evol.scale) return 0
      return this.result?.query_params?.evol.depth || 1
    },
    chartDisplayed () {
      const chart = `chart-${this.previewMode}`
      if (Object.keys(Charts).includes(pascalcase(chart))) return chart
      return 'chart-echarts'
    },
    trinoActive () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE
    }
  },
  async mounted () {
    if (this.item?.chart_active) this.previewMode = this.item.chart_active
    else this.item.update('chart_active', 'table')
    this.canQueryRun = await this.$api.IAM.can('am', 'query', 'run')
    this.canQueryHistory = await this.$api.IAM.can('am', 'query', 'history')
    this.runFromCache()

    if (this.$route.query?.editTile || this.$route.query?.queryFromDashboard) this.run()
  },
  methods: {
    switchSettings () {
      this.isOpenSettings = !this.isOpenSettings
    },
    switchPreviewMode (mode) {
      this.$analytics.track('Edit chart configuration', {
        shared_id: this.item.shared_id,
        chart_type: mode
      })
      this.previewMode = mode
      this.item.update('chart_active', mode, !this.readOnly)
    },
    async runFromCache () {
      if (!this.fromExplorer) return
      try {
        this.internalLoading = true
        this.$emit('loading', true)
        this.result = await this.$api.QB.getResult(this.item, this.limit, {
          legacy: false,
          cache: true,
          source: 'Explorer'
        })
        if (this.result) {
          this.$emit('latest-used-table', this.result?.table_name)
          this.error = null
          this.errorSummary = null
        }
      } catch (error) {
        this.error = error.message
        this.errorSummary = error.error_code
      }
      this.$emit('loading', false)
      this.internalLoading = false
    },
    async run () {
      this.$analytics.track('Run query', {
        shared_id: this.item.shared_id,
        nb_data: Object.keys(this.item.configuration?.data?.fields || {}).length,
        nb_scale: (this.item.configuration?.scale?.fields || []).length,
        filter: !!Object.keys(this.item.configuration?.filter || {}).length,
        order: !!Object.keys(this.item.configuration?.order || {}).length,
        query_mode: this.item?.type
      })
      try {
        this.internalLoading = true
        this.$emit('loading', true)
        this.result = await this.$api.QB.getResult(this.item, this.limit, {
          legacy: !this.trinoActive,
          source: this.fromExplorer ? 'Explorer' : 'AM'
        })

        // If AM or Trino doesn't work -> uncomment this and the import
        // this.result = mockResults

        this.$emit('latest-used-table', this.result?.table_name)

        this.internalLoading = false
        this.$emit('loading', false)
        this.error = null
        this.errorSummary = null

        // To remove when WS active
        // Because there is no web socket, we refresh history here
        if (this.canQueryHistory) await this.$store.dispatch('LOAD_QB_QUERIES_HISTORY', true)
      } catch (error) {
        this.error = error.message
        this.errorSummary = error.error_code
        this.$emit('loading', false)
        this.internalLoading = false

        // To remove when WS active
        // Because there is no web socket, we refresh history here
        if (this.canQueryHistory) await this.$store.dispatch('LOAD_QB_QUERIES_HISTORY', true)
      }
    },
    share () {
      return null
      // if (!this.result) return
      // this.$modal.show(ShareModal, {
      //   request: { id: 'todo' }
      // }, {
      //   height: 'auto',
      //   width: 560
      // })
    },
    code () {
      console.log('code')
    },
    qrcode () {
      console.log('qrcode')
    },
    formatResultToExport (format) { // Also used in Tile.vue
      let csvContent = ''
      const headerValues = []
      const limit = format === 'csv' ? 10_000_000 : 1_000_000
      let maxRows = null
      let rowsDownloaded = null

      // Get columns
      const columnsInfo = []
      if (this.visualMode) {
        if (this.result?.query_params?.scale?.fields) {
          this.result.query_params.scale.fields.forEach(scale => {
            headerValues.push(scale)
            columnsInfo.push({
              label: scale,
              target: `scales.${scale}`
            })
          })
        }
        if (this.result?.query_params?.data?.fields) {
          Object.keys(this.result.query_params.data.fields).forEach(data => {
            this.result.query_params.data.fields[data].forEach(cm => {
              for (let evol = 0; evol <= this.evolDepth; evol++) {
                headerValues.push(cm === 'select' ? data : cm + ' ' + data)
                columnsInfo.push({
                  label: cm === 'select' ? data : cm + ' ' + data,
                  target: `data.${data}.${cm}[${evol}].value`
                })
              }
            })
          })
        }
      } else {
        (this.result?.results?.columns || []).forEach(column => {
          headerValues.push(column)
          columnsInfo.push({
            label: column,
            target: column
          })
        })
      }
      csvContent += `${headerValues.join('\t')}\n`

      // Get rows
      let rows = this.result?.results
      maxRows = rows.length
      let estimateRowsToLimit = 0

      // Used for sql queries
      const { results, columns } = this.result?.results || {}
      if (!this.visualMode) {
        rows = new Array(results?.length || 0)
        maxRows = rows.length
      }

      for (let i = 0; i < rows.length; i++) {
        if (!this.visualMode) {
          // eslint-disable-next-line no-sequences
          rows[i] = results[i].reduce((acc, cur, i) => ((acc[columns[i]] = cur), acc), {})
        }

        const values = []
        columnsInfo.forEach(c => {
          let value = _get(rows[i], c.target)
          if (typeof value === 'string') value = value.replace(/(\r\n|\n|\r|\t)/gm, ' ')
          values.push(value)
        })

        const test = csvContent + `${values.join('\t')}\n`
        if (i === 0 || estimateRowsToLimit === limit) {
          const firstRowSize = new Blob([test]).size
          estimateRowsToLimit = Math.floor(limit / (firstRowSize || 1))
        }

        if (maxRows > estimateRowsToLimit && i % estimateRowsToLimit === 0 && new Blob([test]).size > limit) {
          maxRows = rows.length
          rowsDownloaded = i
          break
        }
        csvContent = test
      }

      const size = new Blob([csvContent]).size
      if (size && size > limit) {
        const csvContentArray = csvContent.split('\n')
        const ratio = size / limit
        const rowsToKeep = Math.floor(csvContentArray.length / ratio)

        csvContent = csvContentArray.slice(0, rowsToKeep).join('\n')
        // We do not count column name in line downloaded
        rowsDownloaded = rowsToKeep - 1
      }

      return {
        csvContent,
        maxRows,
        rowsDownloaded
      }
    },
    async downloadPreview (format) { // Also used in Tile.vue
      this.downloadLoading = true

      await new Promise(resolve => {
        setTimeout(() => { // Put timeout to display 'loading' in UI
          if (format === 'json') {
            const blob = new Blob([JSON.stringify(this.item.configuration, null, 2)], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = this.item.display_name
            a.click()
            a.remove()
          }

          if (['copy', 'csv'].includes(format)) {
            if (!this.result) return
            this.$analytics.track('Download the query result', {
              shared_id: this.item.shared_id,
              format,
              from: 'Query edition'
            })

            try {
              let message = ''
              if (format === 'copy') {
                const { csvContent, maxRows, rowsDownloaded } = this.formatResultToExport(format)
                this.$copyText(csvContent)
                message = rowsDownloaded === null ? this.$t('query.preview.download.copy_to_clipboard.all') : this.$t('query.preview.download.copy_to_clipboard.limit', [rowsDownloaded, maxRows])
              }

              if (format === 'csv') {
                const { csvContent, maxRows, rowsDownloaded } = this.formatResultToExport(format)
                const blob = new Blob([csvContent], { type: 'text/csv' })
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = this.item.display_name
                a.click()
                a.remove()

                message = rowsDownloaded === null ? this.$t('query.preview.download.csv.all') : this.$t('query.preview.download.csv.limit', [rowsDownloaded, maxRows])
              }

              this.$fpuiMessageBlock.success(message)
            } catch (err) {
              this.$fpuiMessageBlock.error(err)
              console.error(err)
            }
          }
          resolve(true)
        }, 100)
      })

      this.downloadLoading = false
    },
    setActive (tab) {
      this.tabActive = tab
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.query-preview {
  background: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px 12px 25px;
    border-bottom: 1px solid #D6DEE5;
    .left-buttons-container {
      display: flex;
      align-items: center;
      .button-settings {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        height: 25px;
        width: 37px;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        color: #97A7B7;
        cursor: pointer;
        &.active, &:hover:not(.disabled) {
          color: white;
          background-color: @grey;
          box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
        }
        &.disabled {
          cursor: not-allowed;
          box-shadow: none;
          border: 1px solid rgba(151,167,183,0.21);
          color: #E4E7EC;
        }
      }
      .preview-switch-mode {
        display: flex;
        align-items: center;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        margin-left: 10px;
        height: 25px;
        align-content: center;
        a {
          font-size: 20px;
          display: inline-block;
          color: #97A7B7;
          height: 23px;
          width: 37px;
          padding: 0 6px 0 4px;
          cursor: pointer;
          border-right: 0.5px solid rgba(151,167,183,0.21);
          border-left: 0.5px solid rgba(151,167,183,0.21);
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: none;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: none;
          }
          &:only-child {
            border-radius: 4px;
            border: none;
          }
          &.active, &:hover {
            color: white;
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
            width: 37px !important;
          }
          i {
            font-size: 20px;
          }
        }
      }
      .fpui-tabs.explorer {
        margin-bottom: -32px;
        .fpui-tabs-heads {
          padding-left: 12px;
        }
      }
    }
    .right-buttons-container {
      display: flex;
      align-items: center;
      .download-loading {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .fp-loading {
          margin-right: 3px;
          width: 20px !important;
          height: 20px !important;
        }
        span {
          color: #97a7b7;
        }
      }
      .limit {
        margin-right: 10px;
        .fpui-input-select {
          width: 195px;
        }
      }
      .run {
        .fpui-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          .btn-content {
            font-size: 13px;
          }
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        margin-left: 10px;
        height: 34px;
        a {
          font-size: 20px;
          display: inline-block;
          color: #97A7B7;
          height: 32px;
          width: 34px;
          padding: 4px 6px;
          cursor: pointer;
          border-right: 0.5px solid rgba(151,167,183,0.21);
          border-left: 0.5px solid rgba(151,167,183,0.21);
          &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: none;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: none;
          }
          &.disabled {
            cursor: not-allowed;
            box-shadow: none;
            color: #E4E7EC;
          }
          &:hover:not(.disabled) {
            color: white;
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
          }
        }
        > .download {
          .fpui-input-select-container, .fpui-input-select-categories-container {
            position: relative;
            border-radius: 4px 0 0 4px;
            &.disabled {
              > .fpui-input-label {
                i {
                  color: #E4E7EC;
                }
              }
            }
            &:hover:not(.disabled) {
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
              > .fpui-input-label {
                color: @white;
                i {
                  color: @white;
                }
              }
            }
            > .fpui-input-label {
              position: absolute;
              left: 7px;
              top: 4px;
              i {
                font-size: 20px;
                color: #97a7b7;
              }
            }
            > .content {
              > .default {
                > .fpui-input-select {
                  border: none;
                  background-color: initial;
                  display: initial;
                  padding: 8px 17px;
                }
                .select-label {
                  display: none;
                }
              }
              > .dropdown {
                left: unset;
                right: 0;
                top: 36px;
              }
              li[disabled] {
                color: @grey;
                user-select: none;
                cursor: initial;
                &:hover {
                  background-color: initial;
                }
              }
              i.fp4-angle-down {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .preview-data-container {
    display: flex;
    overflow-y: auto;
    width: 100%;
    flex-grow: 1;
    .results {
      display: flex;
      width: 100%;
      flex-grow: 1;
      .settings-container {
        width: 328px;
        height: 100%;
        border-right: 1px solid rgba(151,167,183,0.21);
      }
      .result-container {
        height: 100%;
        width: 100%;
      }

      .result-error {
        margin: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .status.center {
          margin-bottom: 3px;
          .fp4-circle-exclamation {
            color: @red;
            font-size: 46px;
          }
        }
        .text {
          color: #000;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 25px;
        }
        .panel-error {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;
          background: #F6F9FC;
          border-radius: 10px;
          .summary {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            .circle {
              margin-top: 3px;
              margin-right: 6px;
              border-radius: 100%;
              width: 8px;
              height: 8px;
              background-color: @red;
            }
            .summary-text {
              font-size: 14px;
              color: #000;
            }
          }
          .error-message {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 20px;
            background: #3E4550;
            border: 1px solid rgba(151, 167, 183, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Courier;
            font-size: 12px;
            line-height: 14px;
            color: #FFF;
            width: 100%;
            word-break: break-word;
          }
        }
      }

      .no-result {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: calc(~"100% - 80px");
          max-height: 180px;
          margin-bottom: 30px;
        }
        .message {
          font-size: 14px;
          line-height: 18px;
          color: @grey-chart;
        }
      }
    }

    .explorer-history {
      width: 100%;

      .no-execution {
        width: 50%;
        margin: auto;
      }

      .queries-history-content {
        .fpui-table {
          height: 100%!important;
          .fpui-table_body {
            height: calc(~"100% - 80px")!important;
            overflow: overlay;
            overflow: auto;
          }
          .fpui-table_row {
            height: 90px;
            overflow: hidden;
            .fpui-table_row-actions {
              padding-right: 35px;
              background: transparent;
              box-shadow: none;
              -webkit-box-shadow: none;
            }
          }
        }
      }
    }
  }
}
</style>
