<template lang="pug">
.vue-grid-item(
  ref="item"
  :class="classObj"
  :style="style"
)
  slot
  //- span.left(
  //-   v-if="resizableAndNotStatic && !edit && displayArrow && editing"
  //-   ref="handle-left"
  //-   :class="resizableHandleClass"
  //-   @click="test(true)"
  //- )
  span.right(
    v-if="resizableAndNotStatic && !edit && displayArrow && editing"
    ref="handle-right"
    :class="resizableHandleClass"
    @click="test(false)"
  )
  span.bottom(
    v-if="verticalResize && editing"
    ref="handle-bottom"
    :class="resizableHandleClass"
  )
    i.fp4.fp4-double-arrow
</template>

<script>
import {
  setTopLeft,
  setTopRight,
  setTransformRtl,
  setTransform
} from './helpers/utils'
import { getControlPosition, createCoreData } from './helpers/draggableUtils'
import { getColsFromBreakpoint } from './helpers/responsiveUtils'
import { getDocumentDir } from './helpers/DOM'

import '@interactjs/auto-start'
import '@interactjs/auto-scroll'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'

export default {
  name: 'GridItem',
  inject: ['eventBus', 'layout'],
  props: {
    isDraggable: { type: Boolean, required: false, default: null },
    isResizable: { type: Boolean, required: false, default: null },
    verticalResize: { type: Boolean, required: false, default: null },
    edit: { type: Boolean, required: false, default: null },
    isBounded: { type: Boolean, required: false, default: null },
    static: { type: Boolean, required: false, default: false },
    minH: { type: Number, required: false, default: 1 },
    minW: { type: Number, required: false, default: 1 },
    maxH: { type: Number, required: false, default: Infinity },
    maxW: { type: Number, required: false, default: Infinity },
    x: { type: Number, required: true },
    y: { type: Number, required: true },
    w: { type: Number, required: true },
    h: { type: Number, required: true },
    i: { type: [String, Number], required: true },
    dragIgnoreFrom: { type: String, required: false, default: 'a, button' },
    dragAllowFrom: { type: String, required: false, default: null },
    resizeIgnoreFrom: { type: String, required: false, default: 'a, button' },
    preserveAspectRatio: { type: Boolean, required: false, default: false },
    dragOption: { type: Object, required: false, default: () => ({}) },
    resizeOption: { type: Object, required: false, default: () => ({}) },
    styleOverride: { type: Object, required: false, default: () => ({}) },
    editing: { type: Boolean, default: false }
  },
  data: function () {
    return {
      cols: 1,
      containerWidth: 100,
      maxRows: Infinity,
      draggable: null,
      resizable: null,
      transformScale: 1,
      useCssTransforms: true,
      useStyleCursor: true,

      isDragging: false,
      dragging: null,
      isResizing: false,
      resizing: null,
      lastX: NaN,
      lastY: NaN,
      lastW: NaN,
      lastH: NaN,
      style: {},
      rtl: false,
      rtlTest: false,

      dragEventSet: false,
      resizeEventSet: false,

      previousW: null,
      previousH: null,
      previousX: null,
      previousY: null,
      innerX: this.x,
      innerY: this.y,
      innerW: this.w,
      innerH: this.h,
      minHToUse: this.minH
    }
  },
  computed: {
    classObj () {
      return {
        'vue-resizable': this.resizableAndNotStatic,
        static: this.static,
        resizing: this.isResizing,
        'vue-draggable-dragging': this.isDragging,
        cssTransforms: this.useCssTransforms,
        // 'render-rtl': this.renderRtl,
        'resize-left': this.rtlTest,
        'disable-userselect': this.isDragging,
        'no-touch': this.isAndroid && this.draggableOrResizableAndNotStatic
      }
    },
    resizableAndNotStatic () {
      return this.resizable && !this.static
    },
    draggableOrResizableAndNotStatic () {
      return (this.draggable || this.resizable) && !this.static
    },
    isAndroid () {
      return navigator.userAgent.toLowerCase().indexOf('android') !== -1
    },
    // renderRtl () {
    //   return (this.layout.isMirrored) ? !this.rtl : this.rtl
    // },
    resizableHandleClass () {
      if (this.renderRtl) {
        return 'vue-resizable-handle vue-rtl-resizable-handle'
      } else {
        return 'vue-resizable-handle'
      }
    },
    displayArrow () {
      return this.layout.layout.getRowTiles(this.y).length < this.layout.layout.colNum / this.layout.layout.minWidth
    },
    margin () {
      if (this.layout.margin) {
        if (this.editing && this.layout.margin[1] < 30) return [this.layout.margin[0], 30]
        return this.layout.margin
      }
      return [10, 10]
    }
  },
  watch: {
    isDraggable: function () {
      this.draggable = this.isDraggable
    },
    static: function () {
      this.tryMakeDraggable()
      this.tryMakeResizable()
    },
    draggable: function () {
      this.tryMakeDraggable()
    },
    isResizable: function () {
      this.resizable = this.isResizable
    },
    isBounded: function () {
      this.bounded = this.isBounded
    },
    resizable: function () {
      this.tryMakeResizable()
    },
    cols: function () {
      this.tryMakeResizable()
      this.createStyle()
      this.emitContainerResized()
    },
    containerWidth: function () {
      this.tryMakeResizable()
      this.createStyle()
      this.emitContainerResized()
    },
    x: function (newVal) {
      this.innerX = newVal
      this.createStyle()
    },
    y: function (newVal) {
      this.innerY = newVal
      this.createStyle()
    },
    h: function (newVal) {
      this.innerH = newVal
      this.createStyle()
      // this.emitContainerResized();
    },
    w: function (newVal) {
      this.innerW = newVal
      this.createStyle()
      // this.emitContainerResized();
    },
    renderRtl: function () {
      this.tryMakeResizable()
      this.createStyle()
    },
    minH: function () {
      this.tryMakeResizable()
    },
    maxH: function () {
      this.tryMakeResizable()
    },
    minW: function () {
      this.tryMakeResizable()
    },
    maxW: function () {
      this.tryMakeResizable()
    },
    '$parent.margin': function (margin) {
      if (!margin || (margin[0] === this.margin[0] && margin[1] === this.margin[1])) {
        return
      }
      this.margin = margin.map(m => Number(m))
      this.createStyle()
      this.emitContainerResized()
    }
  },
  created () {
    const self = this

    // Accessible refernces of functions for removing in beforeDestroy
    self.updateWidthHandler = function (width) {
      self.updateWidth(width)
    }

    self.compactHandler = function (layout) {
      self.compact(layout)
    }

    self.setDraggableHandler = function (isDraggable) {
      if (self.isDraggable === null) {
        self.draggable = isDraggable
      }
    }

    self.setResizableHandler = function (isResizable) {
      if (self.isResizable === null) {
        self.resizable = isResizable
      }
    }

    self.setBoundedHandler = function (isBounded) {
      if (self.isBounded === null) {
        self.bounded = isBounded
      }
    }

    self.setTransformScaleHandler = function (transformScale) {
      self.transformScale = transformScale
    }

    self.setMaxRowsHandler = function (maxRows) {
      self.maxRows = maxRows
    }

    // self.directionchangeHandler = () => {
    //   this.rtl = getDocumentDir() === 'rtl'
    //   this.compact()
    // }

    self.setColNum = (colNum) => {
      self.cols = parseInt(colNum)
    }

    this.eventBus.$on('updateWidth', self.updateWidthHandler)
    this.eventBus.$on('compact', self.compactHandler)
    this.eventBus.$on('setDraggable', self.setDraggableHandler)
    this.eventBus.$on('setResizable', self.setResizableHandler)
    this.eventBus.$on('setBounded', self.setBoundedHandler)
    this.eventBus.$on('setTransformScale', self.setTransformScaleHandler)
    this.eventBus.$on('setMaxRows', self.setMaxRowsHandler)
    this.eventBus.$on('directionchange', self.directionchangeHandler)
    this.eventBus.$on('setColNum', self.setColNum)

    this.rtl = getDocumentDir() === 'rtl'
  },
  beforeDestroy: function () {
    const self = this
    // Remove listeners
    this.eventBus.$off('updateWidth', self.updateWidthHandler)
    this.eventBus.$off('compact', self.compactHandler)
    this.eventBus.$off('setDraggable', self.setDraggableHandler)
    this.eventBus.$off('setResizable', self.setResizableHandler)
    this.eventBus.$off('setBounded', self.setBoundedHandler)
    this.eventBus.$off('setTransformScale', self.setTransformScaleHandler)
    this.eventBus.$off('setMaxRows', self.setMaxRowsHandler)
    this.eventBus.$off('directionchange', self.directionchangeHandler)
    this.eventBus.$off('setColNum', self.setColNum)
    if (this.interactObj) {
      this.interactObj.unset() // destroy interact intance
    }
  },
  mounted: function () {
    if (this.layout.responsive && this.layout.lastBreakpoint) {
      this.cols = getColsFromBreakpoint(this.layout.lastBreakpoint, this.layout.cols)
    } else {
      this.cols = this.layout.colNum
    }
    this.containerWidth = this.layout.width !== null ? this.layout.width : 100
    this.maxRows = this.layout.maxRows

    if (this.isDraggable === null) {
      this.draggable = this.layout.isDraggable
    } else {
      this.draggable = this.isDraggable
    }
    if (this.isResizable === null) {
      this.resizable = this.layout.isResizable
    } else {
      this.resizable = this.isResizable
    }
    if (this.isBounded === null) {
      this.bounded = this.layout.isBounded
    } else {
      this.bounded = this.isBounded
    }
    this.transformScale = this.layout.transformScale
    this.useCssTransforms = this.layout.useCssTransforms
    this.useStyleCursor = this.layout.useStyleCursor
    this.createStyle()

    if (this.verticalResize) this.minHToUse = this.minH + 20
  },
  methods: {
    test (val) {
      this.rtlTest = val
    },
    createStyle: function () {
      if (this.x + this.w > this.cols) {
        this.innerX = 0
        this.innerW = (this.w > this.cols) ? this.cols : this.w
      } else {
        this.innerX = this.x
        this.innerW = this.w
      }
      const pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)


      if (this.isDragging) {
        pos.top = this.dragging.top
        // Add rtl support
        if (this.renderRtl) {
          pos.right = this.dragging.left
        } else {
          pos.left = this.dragging.left
        }
      }
      if (this.isResizing) {
        pos.width = this.resizing.width
        pos.height = this.resizing.height
      }

      let style
      // CSS Transforms support (default)
      if (this.useCssTransforms) {
        // Add rtl support
        if (this.renderRtl) {
          style = setTransformRtl(pos.top, pos.right, pos.width, pos.height)
        } else {
          style = setTransform(pos.top, pos.left, pos.width, pos.height)
        }
      } else { // top,left (slow)
        // Add rtl support
        if (this.renderRtl) {
          style = setTopRight(pos.top, pos.right, pos.width, pos.height)
        } else {
          style = setTopLeft(pos.top, pos.left, pos.width, pos.height)
        }
      }

      this.style = { ...style, ...this.styleOverride }
    },
    emitContainerResized () {
      // this.style has width and height with trailing 'px'. The
      // resized event is without them
      const styleProps = {}
      for (const prop of ['width', 'height']) {
        const val = this.style[prop]
        const matches = val.match(/^(\d+)px$/)
        if (!matches) { return }
        styleProps[prop] = matches[1]
      }
      this.$emit('container-resized', this.i, this.h, this.w, styleProps.height, styleProps.width)
    },
    handleResize: function (event) {
      if (this.static) return
      const position = getControlPosition(event)
      // Get the current drag point from the event. This is used as the offset.
      if (position == null) return // not possible but satisfies flow
      const { x, y } = position

      const newSize = { width: 0, height: 0 }
      let pos
      switch (event.type) {
        case 'resizestart': {
          this.tryMakeResizable()
          this.previousW = this.innerW
          this.previousH = this.innerH
          pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)
          newSize.width = pos.width
          newSize.height = pos.height
          this.resizing = newSize
          this.isResizing = true
          break
        }
        case 'resizemove': {
          const coreEvent = createCoreData(this.lastW, this.lastH, x, y)
          if (this.rtlTest) {
            newSize.width = this.resizing.width - coreEvent.deltaX / this.transformScale
          } else {
            newSize.width = this.resizing.width + coreEvent.deltaX / this.transformScale
          }
          newSize.height = this.resizing.height + coreEvent.deltaY / this.transformScale

          this.resizing = newSize
          break
        }
        case 'resizeend': {
          pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)
          newSize.width = pos.width
          newSize.height = pos.height
          this.resizing = null
          this.isResizing = false
          break
        }
      }

      // Get new WH
      pos = this.calcWH(newSize.height, newSize.width)
      if (pos.w < this.minW) {
        pos.w = this.minW
      }

      // TODO get number of item in row and set max if item > 1
      // const itemsInRow = getRowTiles(this.layout.layout, this.y)
      const maxWToUse = this.maxW
      if (pos.w > maxWToUse) {
        pos.w = maxWToUse
      }
      if (pos.h < this.minHToUse) {
        pos.h = this.minHToUse
      }

      const maxHToUse = this.edit ? Infinity : this.h
      if (pos.h > maxHToUse) {
        pos.h = maxHToUse
      }

      if (pos.h < 1) {
        pos.h = 1
      }
      if (pos.w < 1) {
        pos.w = 1
      }

      this.lastW = x
      this.lastH = y

      if (this.innerW !== pos.w || this.innerH !== pos.h) {
        this.$emit('resize', this.i, pos.h, pos.w, newSize.height, newSize.width)
      }
      if (event.type === 'resizeend' && (this.previousW !== this.innerW || this.previousH !== this.innerH)) {
        this.$emit('resized', this.i, pos.h, pos.w, newSize.height, newSize.width)
      }
      this.eventBus.$emit('resizeEvent', event.type, this.i, this.innerX, this.innerY, pos.h, pos.w)
    },
    handleDrag (event) {
      if (this.static) return
      if (this.isResizing) return

      const position = getControlPosition(event)

      // Get the current drag point from the event. This is used as the offset.
      if (position === null) return // not possible but satisfies flow
      const { x, y } = position

      // let shouldUpdate = false;
      const newPosition = { top: 0, left: 0 }
      switch (event.type) {
        case 'dragstart': {
          this.previousX = this.innerX
          this.previousY = this.innerY

          const parentRect = event.target.offsetParent.getBoundingClientRect()
          const clientRect = event.target.getBoundingClientRect()

          const cLeft = clientRect.left / this.transformScale
          const pLeft = parentRect.left / this.transformScale
          const cRight = clientRect.right / this.transformScale
          const pRight = parentRect.right / this.transformScale
          const cTop = clientRect.top / this.transformScale
          const pTop = parentRect.top / this.transformScale

          if (this.renderRtl) {
            newPosition.left = (cRight - pRight) * -1
          } else {
            newPosition.left = cLeft - pLeft
          }
          newPosition.top = cTop - pTop
          this.dragging = newPosition
          this.isDragging = true
          break
        }
        case 'dragend': {
          if (!this.isDragging) return
          const parentRect = event.target.offsetParent.getBoundingClientRect()
          const clientRect = event.target.getBoundingClientRect()

          const cLeft = clientRect.left / this.transformScale
          const pLeft = parentRect.left / this.transformScale
          const cRight = clientRect.right / this.transformScale
          const pRight = parentRect.right / this.transformScale
          const cTop = clientRect.top / this.transformScale
          const pTop = parentRect.top / this.transformScale

          // Add rtl support
          if (this.renderRtl) {
            newPosition.left = (cRight - pRight) * -1
          } else {
            newPosition.left = cLeft - pLeft
          }
          newPosition.top = cTop - pTop
          this.dragging = null
          this.isDragging = false
          this.innerH = this.h
          break
        }
        case 'dragmove': {
          const coreEvent = createCoreData(this.lastX, this.lastY, x, y)
          // Add rtl support
          if (this.renderRtl) {
            newPosition.left = this.dragging.left - coreEvent.deltaX / this.transformScale
          } else {
            newPosition.left = this.dragging.left + coreEvent.deltaX / this.transformScale
          }
          newPosition.top = this.dragging.top + coreEvent.deltaY / this.transformScale
          if (this.bounded) {
            const bottomBoundary = event.target.offsetParent.clientHeight - this.calcGridItemWHPx(1, this.innerH, this.margin[1])
            newPosition.top = this.clamp(newPosition.top, 0, bottomBoundary)
            const colWidth = this.calcColWidth()
            const rightBoundary = this.containerWidth - this.calcGridItemWHPx(this.w, colWidth, this.margin[0])
            newPosition.left = this.clamp(newPosition.left, 0, rightBoundary)
          }
          this.dragging = newPosition
          break
        }
      }

      // Get new XY
      let pos
      if (this.renderRtl) {
        pos = this.calcXY(newPosition.top, newPosition.left)
      } else {
        pos = this.calcXY(newPosition.top, newPosition.left, event)
      }

      this.lastX = x
      this.lastY = y

      if (this.innerX !== pos.x || this.innerY !== pos.y) {
        this.$emit('move', this.i, pos.x, pos.y)
      }
      if (event.type === 'dragend' && (this.previousX !== this.innerX || this.previousY !== this.innerY)) {
        this.$emit('moved', this.i, pos.x, pos.y)
      }
      this.eventBus.$emit('dragEvent', event.type, this.i, pos.x, pos.y, this.innerH, this.innerW)
    },
    calcPosition: function (x, y, w, h) {
      const colWidth = this.calcColWidth()
      let out
      if (this.renderRtl) {
        out = {
          right: Math.round(colWidth * x + (x + 1) * this.margin[0]),
          top: Math.round(this.layout.layout.getItemTop(y)),
          width: w === Infinity ? w : Math.round(colWidth * w + Math.max(0, w - 1) * this.margin[0]),
          height: h === Infinity ? h : Math.round(this.h + Math.max(0, h - 1) * this.margin[1])
        }
      } else {
        out = {
          left: Math.round(colWidth * x + (x + 1) * this.margin[0]),
          top: Math.round(this.layout.layout.getItemTop(y)),
          // Note we do it here rather than later because Math.round(Infinity) causes deopt
          width: w === Infinity ? w : Math.round(colWidth * w + Math.max(0, w - 1) * this.margin[0]),
          height: Math.round(h)
        }
      }

      return out
    },
    /**
     * Translate x and y coordinates from pixels to grid units.
     * @param  {Number} top  Top position (relative to parent) in pixels.
     * @param  {Number} left Left position (relative to parent) in pixels.
     * @return {Object} x and y in grid units.
     */
    calcXY (top, left, event) {
      const colWidth = this.calcColWidth()
      const parentRect = event.target.offsetParent.getBoundingClientRect()
      const pTop = parentRect.top / this.transformScale
      const topY = this.layout.layout.getItemTop(this.previousY)

      if (Math.abs(top - topY) >= 60 && event.type !== 'dragend') {
        if (this.innerH !== this.minHToUse) this.dragging = { ...this.dragging, top: event.clientY - pTop }
        this.innerH = this.minHToUse
      }

      // TODO calculer ici aussi pour between line
      let x = Math.round((left - this.margin[0]) / (colWidth + this.margin[0]))
      let y = this.layout.layout.getItemY(Math.round(top + (this.innerH / 2)))

      // Capping
      x = Math.max(Math.min(x, this.cols - this.innerW), 0)
      y = Math.max(Math.min(y, this.maxRows - this.innerH), 0)

      // This is to keep tile under mouse when tile is resizing width
      if (this.y !== y) {
        const pLeft = parentRect.left / this.transformScale
        this.dragging = { ...this.dragging, left: event.clientX - (pLeft + pLeft / 2) }
      }

      return { x, y }
    },
    // Helper for generating column width
    calcColWidth () {
      const colWidth = (this.containerWidth - (this.margin[0] * (this.cols + 1))) / this.cols
      return colWidth
    },
    // This can either be called:
    // calcGridItemWHPx(w, colWidth, margin[0])
    // or
    // calcGridItemWHPx(h, rowHeight, margin[1])
    calcGridItemWHPx (gridUnits, colOrRowSize, marginPx) {
      // 0 * Infinity === NaN, which causes problems with resize contraints
      if (!Number.isFinite(gridUnits)) return gridUnits
      return Math.round(
        colOrRowSize * gridUnits + Math.max(0, gridUnits - 1) * marginPx
      )
    },

    // Similar to _.clamp
    clamp (num, lowerBound, upperBound) {
      return Math.max(Math.min(num, upperBound), lowerBound)
    },

    /**
     * Given a height and width in pixel values, calculate grid units.
     * @param  {Number} height Height in pixels.
     * @param  {Number} width  Width in pixels.
     * @return {Object} w, h as grid units.
     */
    calcWH (height, width) {
      const colWidth = this.calcColWidth()

      // width = colWidth * w - (margin * (w - 1))
      // ...
      // w = (width + margin) / (colWidth + margin)
      let w = Math.round((width + this.margin[0]) / (colWidth + this.margin[0]))
      const h = height

      // Capping
      w = Math.max(Math.min(w, this.cols - this.innerX), 0)

      return { w, h }
    },
    updateWidth: function (width, colNum) {
      this.containerWidth = width
      if (colNum !== undefined && colNum !== null) {
        this.cols = colNum
      }
    },
    compact: function () {
      this.createStyle()
    },
    tryMakeDraggable: function () {
      if (!this.editing) return

      const self = this
      if (this.interactObj === null || this.interactObj === undefined) {
        this.interactObj = interact(this.$refs.item)
        if (!this.useStyleCursor) {
          this.interactObj.styleCursor(false)
        }
      }
      if (this.draggable && !this.static) {
        const opts = {
          ignoreFrom: this.dragIgnoreFrom,
          allowFrom: this.dragAllowFrom,
          autoScroll: {
            container: document.getElementById('layout-container'),
            speed: 450,
            margin: 150
          },
          ...this.dragOption
        }
        this.interactObj.draggable(opts)

        if (!this.dragEventSet) {
          this.dragEventSet = true
          this.interactObj.on('dragstart dragmove dragend', function (event) {
            self.handleDrag(event)
          })
        }
      } else {
        this.interactObj.draggable({
          enabled: false
        })
      }
    },
    tryMakeResizable: function () {
      if (!this.editing) return

      const self = this
      if (this.interactObj === null || this.interactObj === undefined) {
        this.interactObj = interact(this.$refs.item)
        if (!this.useStyleCursor) {
          this.interactObj.styleCursor(false)
        }
      }
      if (this.resizable && !this.static) {
        const maxHToUse = this.edit ? Infinity : this.h
        // const itemsInRow = getRowTiles(this.layout.layout, this.y)
        const maxWToUse = this.maxW
        const maximum = this.calcPosition(0, 0, maxWToUse, maxHToUse)
        const minimum = this.calcPosition(0, 0, this.minW, this.minHToUse)

        const opts = {
          // allowFrom: "." + this.resizableHandleClass.trim().replace(" ", "."),
          edges: {
            // left: '.' + this.resizableHandleClass.trim().replace(' ', '.'),
            left: false,
            right: '.' + this.resizableHandleClass.trim().replace(' ', '.'),
            bottom: '.' + this.resizableHandleClass.trim().replace(' ', '.'),
            top: false
          },
          ignoreFrom: this.resizeIgnoreFrom,
          restrictSize: {
            min: {
              height: this.verticalResize ? minimum.height * this.transformScale : this.h,
              width: minimum.width * this.transformScale
            },
            max: {
              height: this.verticalResize ? maximum.height * this.transformScale : this.h,
              width: maximum.width * this.transformScale
            }
          },
          autoScroll: {
            container: document.getElementById('layout-container')
          },
          ...this.resizeOption
        }

        if (this.preserveAspectRatio) {
          opts.modifiers = [
            interact.modifiers.aspectRatio({
              ratio: 'preserve'
            })
          ]
        }

        this.interactObj.resizable(opts)
        if (!this.resizeEventSet) {
          this.resizeEventSet = true
          this.interactObj
            .on('resizestart resizemove resizeend', function (event) {
              self.handleResize(event)
            })
        }
      } else {
        this.interactObj.resizable({
          enabled: false
        })
      }
    }
  }
}
</script>

<style lang="less">
.vue-grid-item {
  transition: all 200ms ease;
  transition-property: left, top, right;
  touch-action: none;
}

.vue-grid-item.no-touch {
  -ms-touch-action: none;
  touch-action: none;
}

.vue-grid-item.cssTransforms {
  transition-property: transform;
  left: 0;
  right: auto;
}

.vue-grid-item.cssTransforms.render-rtl {
  left: auto;
  right: 0;
}

.vue-grid-item.resizing {
  opacity: 0.6;
  z-index: 3;
}

.vue-grid-item.vue-draggable-dragging {
  transition:none;
  z-index: 3;
}

.vue-grid-item.vue-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.vue-grid-item > .vue-resizable-handle {
  position: absolute;
  width: 5px;
  height: 100%;
  box-sizing: border-box;
  padding: 0 3px 3px 0;
  cursor: ew-resize;

  &.left {
    left: 0;
    bottom: 0;

    &:after {
      content: "";
      background: #CBD3DB;
      border-radius: 4px;
      width: 2px;
      height: 21px;
      position: absolute;
      bottom: calc(~"50% - 10.5px");
      left: 3px;
      display: none;
    }
  }
  &.right {
    right: 0;
    bottom: 0;

    &:after {
      content: "";
      background: #CBD3DB;
      border-radius: 4px;
      width: 2px;
      height: 21px;
      position: absolute;
      bottom: calc(~"50% - 10.5px");
      right: 3px;
      display: none;
    }
  }
  &.bottom {
    bottom: -4px;
    right: calc(~"50% - 22px");
    height: 14px;
    width: 45px;
    background: none;
    cursor: ns-resize;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-radius: 1px;
    padding: 0;

    i {
      display: none;
    }

    &:hover {
      background: #97A7B7;
      i {
        display: block;
      }
    }
  }
}

.vue-grid-item:hover {
  .vue-resizable-handle {
    &.left, &.right {
      &:after {
        display: block;
      }
    }
  }
}

.vue-grid-item > .vue-rtl-resizable-handle {
  bottom: 0;
  left: 0;
  background-position: bottom left;
  padding-left: 3px;
  background-repeat: no-repeat;
  background-origin: content-box;
  cursor: sw-resize;
  right: auto;
}

.vue-grid-item.disable-userselect {
  user-select: none;
}
</style>
